import { request } from '../request'
import { ISmsCodeGetResponse, ISmsPayload, ISmsResponse } from './dto/sms'

export function handleSms(data: ISmsPayload): Promise<ISmsResponse> {
  return request({
    method: 'POST',
    url: '/sms/v1/check',
    data: {
      phone: data.phone,
      code: data.code,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function getSmsCode(data: Pick<ISmsPayload, 'phone'>): Promise<ISmsCodeGetResponse> {
  return request({
    method: 'POST',
    url: '/sms/v1/ask',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
