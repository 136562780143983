import { Outlet } from 'react-router'
import Footer from '../components/Footer/Footer'
import { createPortal } from 'react-dom'
import Header from '../components/Header/Header'
import Scroller from '../components/Scroller/Scroller'
import BestCompaniesOffers from '../modules/Companies/components/BestOffers/BestOffers'
import styles from '../modules/Companies/Companies.module.scss'
import OftenLookingFor from '../modules/MainPage/components/OftenLookingFor/OftenLookingFor'

export default function CompanyLayout() {
  return (
    <>
      {createPortal(<Header />, document.getElementsByTagName('body')[0], 'header')}
      <main className={styles.companies}>
        <Scroller />
        <Outlet />
        <BestCompaniesOffers className={styles.bestOffers} />
        <OftenLookingFor className={styles.oftenLookingFor} />
      </main>
      {createPortal(<Footer />, document.getElementsByTagName('body')[0], 'footer')}
    </>
  )
}
