import styles from './BestLoans.module.scss'
import Scroller from '../../../components/Scroller/Scroller'
import BestLoansSkeleton from './BestLoans.skeleton'
import Title from '../../../components/Title/Title'
import useBackground from '../../../hooks/useBackground'
import useBestLoans from './useBestLoans'
import { useEffect, useMemo } from 'react'
import Offer from '../../../components/Offer/Offer'
import useBanners from '../../ShowCase/hooks/useBanners'
import { BannersCategories } from '../../../api/ui/banners'
import checkDesktop from '../../../helpers/checkDesktop'
import Banner from '../../ShowCase/Loans/components/Banner/Banner'
import { HeadHelmet } from '../../../components/HeadHelment/HeadHelmet'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'

export default function BestOfferLoans() {
  useBackground('#D6D9CF')
  const { template, loans, loadLoans, isLoading } = useBestLoans()
  const { banners } = useBanners({
    category: BannersCategories.LOAN,
  })
  const { getPostBackUrl } = useGetOfferUrlWithUtm()

  useEffect(() => {
    ;(async function () {
      await loadLoans()
    })()
  }, [])

  const content = useMemo(() => {
    const result = []
    for (let i = 0; i < loans.length; i++) {
      if (!checkDesktop() && banners.length && i % 4 === 0) {
        const trueBannerIndex = i / 4
        const banner = banners[trueBannerIndex % banners.length]
        result.push(<Banner className={styles.banner} {...banner} key={`banner ${banner.id}`} />)
      }
      const { tag, ...item } = loans[i]
      result.push(
        <Offer template={template} tag={tag} item={item} key={item.id} onClick={getPostBackUrl} />,
      )
      if (checkDesktop() && banners.length && (i + 1) % 6 === 0) {
        const trueBannerIndex = (i + 1) / 6 - 1
        const banner = banners[trueBannerIndex % banners.length]
        result.push(<Banner className={styles.banner} {...banner} key={`banner ${banner.id}`} />)
      }
    }
    return result
  }, [banners, loans])

  return (
    <>
      <HeadHelmet
        title='Взять займ на карту онлайн | Срочно взять микрозайм на банковскую карту'
        description='Сервис Снизим.ру предлагает взять займ на карту онлайн мгновенно на выгодных условиях.
        У нас можно сравнить условия микрозаймов и оставить заявку онлайн.'
      />
      <Scroller />
      <div className={styles.bestLoans}>
        <Title className={styles.title} level={1}>
          Лучшие займы
        </Title>
        <ul className={styles.providers}>{!isLoading ? content : <BestLoansSkeleton />}</ul>
      </div>
    </>
  )
}
