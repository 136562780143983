import { Outlet } from 'react-router'
import Footer from '../components/Footer/Footer'
import { createPortal } from 'react-dom'
import Header from '../components/Header/Header'

export default function MainLayout() {
  return (
    <>
      {createPortal(<Header />, document.getElementsByTagName('body')[0], 'header')}
      <Outlet />
      {createPortal(<Footer />, document.getElementsByTagName('body')[0], 'footer')}
    </>
  )
}
