import styles from './OfferMobile.module.scss'
import Tag from '../../Tag/Tag'
import { Link } from 'react-router-dom'
import { ProviderProps } from '../Offer'
import joinClassNames from '../../../helpers/joinClassNames'
import { PropertyBlockProps } from '../../PropertyBlock/PropertyBlock'
import LinkButton from '../../LinkButton/LinkButton'

function PropertyMobileBlock({
  title,
  text,
  children,
  width = '100%',
  size = 'default',
  colorBlock = '',
}: PropertyBlockProps) {
  return (
    <div className={styles.propertyBlock} style={{ width: width }}>
      <span className={styles.title}>{title}:</span>
      <span
        style={{ color: colorBlock ? colorBlock : '' }}
        className={joinClassNames(styles.text, styles[size])}
      >
        {text || children}
      </span>
    </div>
  )
}

export default function OfferMobile({
  item: { icon, title, url, field1, field2, field3, advertiser, field3Color, field1Replaced, id },
  tag: { text, ...restTagProps },
  template: { buttonText, field1Name, field2Name, field3Name, size, backgroundColorCard, width },
  onClick,
}: ProviderProps) {
  function getField1() {
    return <PropertyMobileBlock title={field1Name} text={`${field1}`} size={'small'} />
  }

  function getField2() {
    if (field1Replaced) {
      return (
        <PropertyMobileBlock title={field2Name} size={'small'}>
          <span className={styles.field1Replaced}>{`${field1Replaced}`}</span>{' '}
          <span className={styles.field1New}>{`${field2}`}</span>
        </PropertyMobileBlock>
      )
    } else {
      return <PropertyMobileBlock title={field2Name} text={`${field2}`} size={'small'} />
    }
  }

  return (
    <div className={styles.provider} style={{ backgroundColor: backgroundColorCard, width }}>
      <Link
        to={url}
        className={styles.overlay}
        target='_blank'
        rel='nofollow'
        onClick={(e) => onClick({ event: e, url, offerId: id })}
      />
      <div className={styles.titleContainer}>
        <img className={styles.logo} src={icon} alt={`${title} logo`} />
        <span className={`${styles.title} ${title.length >= 13 ? styles.smallText : ''}`}>
          {title}
        </span>
      </div>
      <div className={styles.tagContainer}>
        <Tag className={styles.tag} {...restTagProps}>
          {text}
        </Tag>
      </div>
      <div className={styles.fluidContainer}>
        <div className={`${styles.firstLineProperties} ${size === 's' ? styles.sizeS : ''}`}>
          {getField1()}
          {getField2()}
          <PropertyMobileBlock
            title={field3Name}
            text={field3}
            size={'small'}
            colorBlock={field3Color}
          />
        </div>
      </div>
      <LinkButton
        className={styles.button}
        to={url}
        target='_blank'
        uiType='secondary'
        title={url}
        rel='nofollow'
        onClick={(e) => onClick({ event: e, url, offerId: id })}
      >
        {buttonText}
      </LinkButton>
      {advertiser && (
        <div className={styles.advertiserContainer}>
          <div className={styles.advertiser}>{advertiser}</div>
        </div>
      )}
    </div>
  )
}
