import { ProviderItem, ProviderTag, ProviderTemplate } from '../../../components/Offer/Offer'
import { Loan, LoansSubcategory } from '../../../api/ui/dto/loans'
import { useMemo, useState } from 'react'
import { getRubles } from '../../../helpers/getRubles'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'
import getTermUnit from '../../../helpers/getTermUnit'

export default function useLoans(subcategory: LoansSubcategory) {
  const [loansProviderItems, setLoansProviderItems] = useState<
    (ProviderItem & { tag: ProviderTag })[]
  >([])
  const { getOfferUrlUtm } = useGetOfferUrlWithUtm()

  function getField3Color(level: string): string {
    switch (level) {
      case 'Высокое':
        return '#35B879'
      case 'Среднее': {
        return '#FF8900'
      }
      case 'Низкое':
        return '#933433'
      default: {
        return '#000'
      }
    }
  }
  const loadLoans = async (offers) => {
    const getField = (item: Loan) => {
      if (subcategory.toUpperCase() !== LoansSubcategory.SNIZIM_PERCENT) {
        return item.rateFrom ? String(item.rateFrom) + '%' : '0%'
      }
      return item.reducedRateFrom ? String(item.reducedRateFrom) + '%' : '0%'
    }

    const getField1Replaced = (item: Loan) => {
      if (subcategory.toUpperCase() === LoansSubcategory.SNIZIM_PERCENT && item.rateFrom !== null) {
        return String(item.rateFrom) + '%'
      }

      return null
    }

    const providerItems = offers.map((item) => {
      return {
        id: String(item.id),
        tag: {
          text: item.tagText,
          color: '#000000',
          backgroundColor: '#DFEB81',
        },
        title: item.name,
        field1Replaced: getField1Replaced(item),
        field1: getRubles(Number(item.maxAmount), true, true),
        field2:
          subcategory.toUpperCase() === LoansSubcategory.SNIZIM_PERCENT
            ? getField(item)
            : `${item.termFrom}-${item.termTo} ${getTermUnit(item.termUnit, item.termTo)}`,
        field3: item.approvalLevel,
        url: getOfferUrlUtm(item.targetUrl, subcategory),
        icon: item.logoUrl,
        advertiser: item.advertiser,
        field3Color: getField3Color(item.approvalLevel),
      }
    })

    setLoansProviderItems(providerItems)
  }
  const template: ProviderTemplate = useMemo(
    () => ({
      buttonText: 'Перейти',
      field1Name: 'сумма до',
      field2Name: subcategory.toUpperCase() === LoansSubcategory.SNIZIM_PERCENT ? 'ставка' : 'срок',
      field3Name: 'Одобрение',
      size: 's',
      isFlexTitleContainer: true,
      backgroundColorCard: '#EDF0E7',
    }),
    [subcategory],
  )

  return {
    loansProviderItems,
    template,
    loadLoans,
  }
}
