import { useEffect, useState } from 'react'
import type { TabItem } from 'components/Tabs/Tabs'
import { getLoans } from '../../../../api/ui/loans'
import { Loan, LoansSubcategory } from '../../../../api/ui/dto/loans'
import { getCredit } from '../../../../api/ui/credit'
import { CreditSubcategory, UiCreditOfferDto } from '../../../../api/ui/dto/uiCreditOfferDto'
import { getBankruptcy } from '../../../../api/ui/bankruptcy'
import { BankruptcySubcategory, UiBankruptcyOfferDto } from '../../../../api/ui/dto/bankruptcy'
import getTermUnit from '../../../../helpers/getTermUnit'
import { ProviderProps } from '../../../../components/Offer/Offer'
import { getRubles } from '../../../../helpers/getRubles'
import getShortTermUnit from '../../../../helpers/getShortTermUnit'
import { useGetOfferUrlWithUtm } from '../../../../hooks/useGetOfferUrlWithUtm'
import checkDesktop from '../../../../helpers/checkDesktop'

enum ETabsIndex {
  LONG_TERM = 0,
  CONSUMER_LOAN = 1,
  CREDIT_CARD = 2,
  BANKRUPTCY_ONLINE = 3,
}

enum ETabsNames {
  ALL = 'Все',
  LONG_TERM = 'Займы',
  CONSUMER_LOAN = 'Кредиты',
  CREDIT_CARD = 'Карты',
  BANKRUPTCY_ONLINE = 'Банкротство',
}

export default function useBestOffers() {
  const [selectedTab, setSelectedTab] = useState(0)
  const [offsets, setOffsets] = useState([])
  const { getOfferUrlUtm } = useGetOfferUrlWithUtm()

  const tabItems: TabItem[] = [
    {
      text: ETabsNames.LONG_TERM,
      onClick: () => setSelectedTab(ETabsIndex.LONG_TERM),
    },
    {
      text: ETabsNames.CONSUMER_LOAN,
      onClick: () => setSelectedTab(ETabsIndex.CONSUMER_LOAN),
    },
    {
      text: ETabsNames.CREDIT_CARD,
      onClick: () => setSelectedTab(ETabsIndex.CREDIT_CARD),
    },
    {
      text: ETabsNames.BANKRUPTCY_ONLINE,
      onClick: () => setSelectedTab(ETabsIndex.BANKRUPTCY_ONLINE),
    },
  ]

  function getLoansItem(item: Loan): ProviderProps {
    const getField = (item: Loan) => {
      if (item.subcategory !== LoansSubcategory.SNIZIM_PERCENT) {
        return item.rateFrom ? String(item.rateFrom) + '%' : '0%'
      }
      return item.reducedRateFrom ? String(item.reducedRateFrom) + '%' : '0%'
    }

    const getField1Replaced = (item: Loan) => {
      if (item.subcategory === LoansSubcategory.SNIZIM_PERCENT && item.rateFrom !== null) {
        return String(item.rateFrom) + '%'
      }

      return null
    }

    function getField3Color(level: string): string {
      switch (level) {
        case 'Высокое':
          return '#35B879'
        case 'Среднее': {
          return '#FF8900'
        }
        case 'Низкое':
          return '#933433'
        default: {
          return '#000'
        }
      }
    }

    return {
      item: {
        id: String(item.id),
        title: item.name,
        field1Replaced: getField1Replaced(item),
        field1: getRubles(Number(item.maxAmount), true, true),
        field2:
          item.subcategory === LoansSubcategory.SNIZIM_PERCENT
            ? getField(item)
            : `${item.termFrom}-${item.termTo} ${getTermUnit(item.termUnit, item.termTo)}`,
        field3: item.approvalLevel,
        url: getOfferUrlUtm(item.targetUrl),
        icon: item.logoUrl,
        advertiser: item.advertiser,
        field3Color: getField3Color(item.approvalLevel),
      },
      tag: {
        text: item.tagText,
        color: '#000000',
        backgroundColor: '#DFEB81',
      },
      template: {
        buttonText: 'Перейти',
        field1Name: 'сумма до',
        field2Name: item.subcategory === LoansSubcategory.SNIZIM_PERCENT ? 'ставка' : 'срок',
        field3Name: 'одобрение',
        size: 's',
        backgroundColorCard: '#F0F0F0',
        width: 155,
        isFlexTitleContainer: true,
      },
    }
  }

  function getCreditItem(item: UiCreditOfferDto): ProviderProps {
    function getFieldName(item: UiCreditOfferDto) {
      if (item.subcategory === CreditSubcategory.MORTGAGE) {
        return 'перв. взнос'
      }

      if (item.subcategory === CreditSubcategory.CREDIT_CARD) {
        return 'без %'
      }

      return 'срок'
    }

    function getField1(maintenanceFrom, maintenanceTo, maintenanceUnit) {
      let result = ''

      if (maintenanceFrom !== null && maintenanceFrom >= 0) {
        result += `от ${getRubles(maintenanceFrom, false)} `
      }

      if (maintenanceTo !== null && maintenanceTo >= 0) {
        result += `до ${getRubles(maintenanceTo, false)}`
      }

      if (maintenanceUnit) {
        result += `руб./${getShortTermUnit(maintenanceUnit)}`
      }

      return !result.length ? null : result
    }

    function getField2(periodFrom, periodTo, periodUnit) {
      let result = ''

      if (periodFrom !== null) {
        result += `от ${periodFrom}`
      }

      if (periodTo !== null) {
        result += `до ${periodTo}`
      }

      if (periodUnit !== null) {
        result += ' ' + getTermUnit(periodUnit)
      }

      return !result.length ? null : result
    }

    function getMortgageField(downPaymentFrom: number, downPaymentTo: number) {
      let result = ''

      if (downPaymentFrom !== null) {
        result += `от ${downPaymentFrom}`
      }

      if (downPaymentTo !== null) {
        result += `до ${downPaymentTo}`
      }

      return !result.length ? null : result + '%'
    }
    function getFieldsValue(item: UiCreditOfferDto) {
      if (item.subcategory === CreditSubcategory.CREDIT_CARD) {
        return {
          field1: getField1(item.maintenanceFrom, item.maintenanceTo, item.maintenanceUnit),
          field2: getField2(item.periodFrom, item.periodTo, item.periodUnit),
        }
      }

      if (item.subcategory === CreditSubcategory.MORTGAGE) {
        if (!checkDesktop()) {
          return {
            field1: getMortgageField(item?.downPaymentFrom, item?.downPaymentTo),
            field2: item.rateFrom !== null ? 'от' + ' ' + String(item.rateFrom) + '%' : null,
          }
        }

        return {
          field1: item.rateFrom !== null ? 'от' + ' ' + String(item.rateFrom) + '%' : null,
          field2: getMortgageField(item?.downPaymentFrom, item?.downPaymentTo),
        }
      }
      return {
        field1: item.rateFrom !== null ? 'от' + ' ' + String(item.rateFrom) + '%' : null,
        field2: `${item.termFrom}-${item.termTo} ${getTermUnit(item.termUnit, item.termTo)}`,
      }
    }

    return {
      item: {
        id: String(item.id),
        title: item.name,
        field3: `до ${getRubles(item.sum)}`,
        url: getOfferUrlUtm(item.targetUrl),
        icon: item.logoUrl,
        advertiser: item.advertiser,
        ...getFieldsValue(item),
      },
      tag: {
        text: item.tagText,
      },
      template: {
        buttonText: 'Перейти',
        field1Name: getFieldName(item),
        field2Name: item.subcategory === CreditSubcategory.CREDIT_CARD ? 'обсуживание' : 'ставка',
        field3Name: 'стоимость',
        size: 's',
        backgroundColorCard: '#F0F0F0',
        width: 155,
      },
    }
  }

  function getBankruptcyItem(item: UiBankruptcyOfferDto): ProviderProps {
    return {
      item: {
        id: String(item.id),

        title: item.name,
        field1: `от ${getRubles(item.priceFrom)}`,
        field2: `до ${getRubles(item.profitTo)}`,
        field3: String(item.termString),
        url: getOfferUrlUtm(item.targetUrl),
        icon: item.logoUrl,
      },
      tag: {
        text: item.tagText,
        color: '#000000',
        backgroundColor: '#DFEB81',
      },
      template: {
        buttonText: 'Перейти',
        field1Name: 'стоимость',
        field2Name: 'выгода',
        field3Name: 'оформление банкротства',
        backgroundColorCard: '#F0F0F0',
        width: 215,
        isFlexTitleContainer: true,
      },
    }
  }

  async function getOffersContent(tab: ETabsIndex) {
    switch (tab) {
      case ETabsIndex.LONG_TERM: {
        const result = await getLoans({ subcategory: LoansSubcategory.LONG_TERM })

        return result.map((item) => getLoansItem(item))
      }
      case ETabsIndex.CONSUMER_LOAN: {
        const result = await getCredit({ subcategory: CreditSubcategory.CONSUMER_LOAN })

        return result.map((item) => getCreditItem(item))
      }
      case ETabsIndex.CREDIT_CARD: {
        const result = await getCredit({ subcategory: CreditSubcategory.CREDIT_CARD })

        return result.map((item) => getCreditItem(item))
      }
      case ETabsIndex.BANKRUPTCY_ONLINE: {
        const result = await getBankruptcy({ subcategory: BankruptcySubcategory.ONLINE })
        return result.map((item) => getBankruptcyItem(item))
      }
      default: {
        const exhaustiveCheck: never = tab
        throw new Error(exhaustiveCheck)
      }
    }
  }

  useEffect(() => {
    async function getOffers() {
      const offers = await getOffersContent(selectedTab)

      if (!offers) {
        setOffsets([])
      } else {
        setOffsets(offers.slice(0, 6))
      }
    }

    getOffers()
  }, [selectedTab])

  return {
    tabItems,
    selectedTab,
    offsets,
  }
}
