import { useCompanyUserAccount } from './useCompanyUserAccount'
import CompanyUserAccountSkeleton from './CompanyUserAccount.skeleton'
import styles from '../CompanyUserAccount/CompanyUserAccount.module.scss'

export function CompanyUserAccount() {
  const { isLoading, companyAccount } = useCompanyUserAccount()

  if (isLoading) {
    return <CompanyUserAccountSkeleton />
  }
  return <div className={styles.account}>{companyAccount.content}</div>
}
