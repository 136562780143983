import { useEffect } from 'react'
import { setBodyBackground } from 'helpers/setBodyBackground'

export default function useBackground(value: string) {
  useEffect(() => {
    setBodyBackground(value)

    return () => {
      setBodyBackground('unset')
    }
  }, [])
}
