import { request } from '../request'
import { BankruptcySubcategory, UiBankruptcyOfferDto } from './dto/bankruptcy'

export interface GetBankruptcyParams {
  subcategory: BankruptcySubcategory
}

export function getBankruptcy(params: GetBankruptcyParams): Promise<UiBankruptcyOfferDto[]> {
  return request({
    url: '/ui/v1/offers/bankruptcy',
    params,
  })
}

export function getAllBankruptcy(): Promise<UiBankruptcyOfferDto[]> {
  return request({
    url: '/ui/v1/offers/bankruptcy',
  })
}
