import { ICompanyInfo, ICompanyItem, ICompanyOfferDTO } from '../../../../api/ui/dto/company'
import getTermUnit from '../../../../helpers/getTermUnit'
import { getRubles } from '../../../../helpers/getRubles'

export function transformDto(company: ICompanyItem): ICompanyOfferDTO {
  if (!company || !company.offer) {
    throw new Error('Company is null')
  }

  const { offer } = company

  return {
    id: offer.id ?? 0,
    name: offer.name ?? '',
    rateFix: offer.rateFix ?? false,
    rateFrom: offer.rateFrom ?? 0,
    rateTo: offer.rateTo ?? 0,
    rateUnit: offer.rateUnit ?? null,
    reducedRateFix: offer.reducedRateFix ?? false,
    reducedRateFrom: offer.reducedRateFrom ?? 0,
    reducedRateTo: offer.reducedRateTo ?? 0,
    reducedRateUnit: offer.reducedRateUnit ?? null,
    termFix: offer.termFix ?? false,
    termFrom: offer.termFrom ?? 0,
    termTo: offer.termTo ?? 0,
    termUnit: offer.termUnit ?? null,
    maxAmount: Number(offer.maxAmount) ?? 0,
    advertiser: offer.advertiser ?? '',
    logoUrl: offer.logoUrl ?? '',
    targetUrl: offer.targetUrl ?? '',
    tagText: offer.tagText ?? '',
  }
}

export function getCompanyInfoData(data: ICompanyOfferDTO): ICompanyInfo {
  const { termFrom, termTo, termUnit, maxAmount, ...rest } = data
  return {
    term: `${termFrom}-${termTo} ${getTermUnit(termUnit, termTo)}`,
    totalSum: getRubles(Number(maxAmount)),
    ...rest,
  }
}
