export default function pluralize(qty: number, cases: [string, string, string]): string {
  let idx
  if (qty % 10 === 1 && qty % 100 !== 11) {
    idx = 0
  } else if (qty % 10 >= 2 && qty % 10 <= 4 && (qty % 100 < 10 || qty % 100 >= 20)) {
    idx = 1
  } else {
    idx = 2
  }

  return cases[idx] || ''
}
