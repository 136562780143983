import styles from './BestCredits.module.scss'
import Offer from '../../../components/Offer/Offer'
import useBackground from '../../../hooks/useBackground'
import { useParams } from 'react-router'
import { useEffect, useMemo } from 'react'
import Scroller from '../../../components/Scroller/Scroller'
import CreditsSkeleton from './BetsCredits.skeleton'
import Title from '../../../components/Title/Title'
import useBestCredits from './useBestCredits'
import useBanners from '../../ShowCase/hooks/useBanners'
import { BannersCategories } from '../../../api/ui/banners'
import checkDesktop from '../../../helpers/checkDesktop'
import Banner from '../../ShowCase/Loans/components/Banner/Banner'
import { HeadHelmet } from '../../../components/HeadHelment/HeadHelmet'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'

export default function BestCredits() {
  useBackground('#E0D6CF')
  const { credits, loadCredits, isLoading } = useBestCredits()
  const { banners } = useBanners({
    category: BannersCategories.CREDITS,
  })
  const { getPostBackUrl } = useGetOfferUrlWithUtm()

  const { category } = useParams()

  useEffect(() => {
    void loadCredits()
  }, [category])

  const content = useMemo(() => {
    const result = []
    for (let i = 0; i < credits.length; i++) {
      if (!checkDesktop() && banners.length && i % 4 === 0) {
        const trueBannerIndex = i / 4
        const banner = banners[trueBannerIndex % banners.length]
        result.push(<Banner className={styles.banner} {...banner} key={`banner ${banner.id}`} />)
      }
      const { tag, template, ...item } = credits[i]
      result.push(
        <Offer item={item} tag={tag} template={template} key={item.id} onClick={getPostBackUrl} />,
      )

      if (checkDesktop() && banners.length && (i + 1) % 6 === 0) {
        const trueBannerIndex = (i + 1) / 6 - 1
        const banner = banners[trueBannerIndex % banners.length]
        result.push(<Banner className={styles.banner} {...banner} key={`banner ${banner.id}`} />)
      }
    }
    return result
  }, [banners, credits])

  return (
    <>
      <HeadHelmet
        title='Оформить онлайн заявку на кредит во все банки | Взять кредит онлайн с низким процентом'
        description='Подберите кредит на страницах портала Снизим.ру.
        Оформите кредит на выгодных условиях с минимальной процентной ставкой онлайн.
        Получите решение за 15 минут.'
      />
      <Scroller />
      <div className={styles.bestCredits}>
        <Title className={styles.title} level={1}>
          Лучшие кредиты
        </Title>
        <ul className={styles.providers}>{!isLoading ? content : <CreditsSkeleton />}</ul>
      </div>
    </>
  )
}
