import styles from './NotFoundPage.module.scss'
import LinkButton from '../../components/LinkButton/LinkButton'
import React from 'react'

export default function NotFoundPage() {
  return (
    <section className={styles.notFoundPage}>
      <section className={styles.container}>
        <div className={styles.content}>
          <div className={styles.mainInfo}>
            <h1 className={styles.title}>404</h1>
            <span className={styles.mainText}>Похоже, такой страницы не существует</span>
          </div>
          <div className={styles.textInfo}>
            <span className={styles.text}>
              Убедитесь в правильности ввода адреса и повторите попытку, или воспользуйтесь меню для
              поиска нужного сервиса.
            </span>
            <LinkButton className={styles.link} to='/' uiType='green'>
              <span>Вернуться на главную</span>
            </LinkButton>
          </div>
        </div>
      </section>
    </section>
  )
}
