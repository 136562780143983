import { useEffect, useState } from 'react'
import { UIBannerDto } from '../../../api/ui/dto/banners'
import { BannerParamsType, getBanners } from '../../../api/ui/banners'

export default function useBanners({ category, subcategory }: BannerParamsType) {
  const [banners, setBanners] = useState<UIBannerDto[]>([])
  const [isLoading, setLoading] = useState(false)

  async function fetchBanners() {
    setLoading(true)
    try {
      const result = await getBanners({ category, subcategory })
      setBanners(result)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void fetchBanners()
  }, [category, subcategory])

  return {
    isLoading,
    banners,
    fetchBanners,
  }
}
