import { request } from '../request'

export interface PostbackParams {
  offerId: string
}

export default function getPostBack({ offerId }: PostbackParams) {
  return request({
    url: '/postback/new',
    params: {
      offer_id: offerId,
    },
  })
}
