import { useEffect, useState } from 'react'
import { HeadHelmetProps } from '../../../components/HeadHelment/HeadHelmet'

export const useBrokersMeta = () => {
  const [meta, setMeta] = useState<HeadHelmetProps>({
    title: '',
    description: '',
  })
  function getHead(): HeadHelmetProps {
    return {
      title: 'Другие займы',
      description: '',
    }
  }

  useEffect(() => {
    setMeta(getHead())
  }, [])

  return {
    meta,
  }
}
