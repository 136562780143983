import { ReactNode } from 'react'
import styles from './Step.module.scss'
import Stepper, { StepperProps } from '../Stepper/Stepper'
export default function Step({
  children,
  ...stepperProps
}: { children: ReactNode } & StepperProps) {
  return (
    <div className={styles.step}>
      <Stepper {...stepperProps} />
      {children}
    </div>
  )
}
