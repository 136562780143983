import Step from '../../components/Step/Step'
import { CommonStepProps } from '../types'
import Container from '../../components/Container/Container'
import Input from '../../../../components/Input/Input'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './SubInfoStep.module.scss'

export default function SubInfoStep({ onSuccess, currentStep }: CommonStepProps) {
  const validationSchema = Yup.object().shape({
    region: Yup.string().required('Заполните все поля'),
    city: Yup.string().required('Заполните все поля'),
    house: Yup.string().required('Заполните все поля'),
    apartment: Yup.string().required('Заполните все поля'),
    street: Yup.string().required('Заполните все поля'),
  })

  const { values, errors, isValid, handleChange, handleSubmit } = useFormik<{
    region: string
    city: string
    house: string
    apartment: string
    street: string
  }>({
    onSubmit() {
      onSuccess(values)
    },
    initialValues: {
      region: '',
      city: '',
      street: '',
      house: '',
      apartment: '',
    },
    validationSchema,
  })

  return (
    <Step
      currentStep={currentStep}
      onSubmit={handleSubmit}
      ready={!isValid}
      error={errors.region || errors.city || errors.house || errors.street || errors.apartment}
    >
      <Container>
        <form className={styles.subInfoStep} onSubmit={handleSubmit}>
          <Input
            label='Регион проживания'
            name='region'
            placeholder='Начните вводить название'
            value={values.region}
            onChange={handleChange}
          />
          <div className={styles.row}>
            <div className={styles.space}>
              <Input
                label='Населенный пункт'
                name='city'
                placeholder=''
                value={values.city}
                onChange={handleChange}
              />
            </div>
            <Input label='Улица' name='street' value={values.street} onChange={handleChange} />
          </div>
          <div className={styles.row}>
            <div className={styles.space}>
              <Input
                label='Дом'
                name='house'
                placeholder=''
                value={values.house}
                onChange={handleChange}
              />
            </div>
            <Input
              label='Квартира'
              name='apartment'
              value={values.apartment}
              onChange={handleChange}
            />
          </div>
        </form>
      </Container>
    </Step>
  )
}
