import { TermUnit } from './common'

export enum LoansSubcategory {
  SNIZIM_PERCENT = 'SNIZIM_PERCENT',
  SNIZIM_PAYMENT = 'SNIZIM_PAYMENT',
  LONG_TERM = 'LONG_TERM',
  SHORT_TERM = 'SHORT_TERM',
  BIG_AMOUNT = 'BIG_AMOUNT',
  ZERO_PERCENTS = 'ZERO_PERCENTS',
  PTS_LOAN = 'PTS_LOAN',
  MASTER_LOAN = 'MASTER_LOAN',
}

export interface Loan {
  id: number
  name: string
  rateFix: true
  rateFrom: number
  rateTo: number
  rateUnit: 'DAY'
  reducedRateFix: true
  reducedRateFrom: number
  reducedRateTo: number
  reducedRateUnit: 'DAY'
  termFix: true
  termFrom: number
  termTo: number
  termUnit: TermUnit
  maxAmount: string
  advertiser: string
  logoUrl: string
  targetUrl: string
  tagText: string
  sum: string
  subcategory: LoansSubcategory
  approvalLevel: string
}

export enum LoanCategoryRu {
  snizim_percent = 'Снизим процент по займам',
  snizim_payment = 'Снизим платеж по займам',
  long_term = 'Займы на долгий срок',
  short_term = 'Краткосрочные займы',
  big_amount = 'Займы на крупную сумму',
  zero_percents = 'Займы под ноль процентов',
  pts_loan = 'Займы под ПТС',
  master_loan = 'Займ-мастер',
}
