import { CompanyTextField } from '../CompanyTextField/CompanyTextField'
import { useCompanyDetails } from './useCompanyDetails'
import CompanyDetailsSkeleton from './CompanyDetails.skeleton'
import styles from '../CompanyDetails/CompanyDetails.module.scss'
import LinkButton from '../../../../../../components/LinkButton/LinkButton'

export function CompanyDetails() {
  const { companyDetails, isLoading } = useCompanyDetails()

  if (isLoading) {
    return <CompanyDetailsSkeleton />
  }
  return (
    <div className={styles.details}>
      <h5 className={styles.title}>Реквизиты микрофинансовой организации</h5>
      <div className={styles.content}>
        <div className={styles.column}>
          <CompanyTextField title={'Юридическое лицо'} text={companyDetails?.advertiser} />
          <CompanyTextField title={'Юридический адрес'} text={companyDetails?.address} />
          <CompanyTextField
            title={'ИНН / КПП'}
            text={companyDetails?.numericCode}
            itemProp='taxID'
          />
          <CompanyTextField title={'ОКПО'} text={companyDetails?.okpoTitle} />
          <CompanyTextField title={'E-mail'} itemProp='email' text={companyDetails?.email} />
        </div>
        <div className={styles.column}>
          <CompanyTextField title={'Лицензия'} text={'№ ' + companyDetails?.licence} />
          <CompanyTextField
            title={'Головной офис'}
            itemProp='address.streetAddress'
            text={companyDetails?.mainAddress}
          />
          <CompanyTextField title={'ОГРН'} text={companyDetails?.ogrnTitle} />
          <CompanyTextField title={'Телефон'} itemProp='telephone' text={companyDetails?.phone} />
          <CompanyTextField title={'Официальный сайт'} text={companyDetails?.mainUrl} />
        </div>
      </div>
      <footer className={`${styles.content} ${styles.buttonContainer}`}>
        <div className={styles.fluidContainerButtons} />
        <LinkButton
          className={styles.button}
          to={companyDetails?.mainUrl}
          target='_blank'
          uiType='secondary'
          title={'companyInfo?.targetUrl'}
          rel='nofollow'
        >
          Перейти на сайт
        </LinkButton>
      </footer>
    </div>
  )
}
