import Scroller from '../../../components/Scroller/Scroller'
import BackLink from '../../../components/BackLink/BackLink'
import { ReactComponent as Logo } from 'assets/icons/logo-white.svg'
import { ReactComponent as Docs } from 'assets/icons/about/doc.svg'
import { ReactComponent as Contract } from 'assets/icons/about/contract.svg'
import { ReactComponent as App } from 'assets/icons/about/app.svg'

import styles from './AboutCompany.module.scss'
import checkDesktop from '../../../helpers/checkDesktop'
import AboutCard from './components/AboutCard/AboutCard'
import { HeadHelmet } from '../../../components/HeadHelment/HeadHelmet'
import AboutCompanyTextField from './components/AboutCompanyTextField/AboutCompanyTextField'
export default function AboutCompany() {
  return (
    <>
      <HeadHelmet title='О компании' />
      <Scroller isSmooth />
      <main className={styles.aboutCompany}>
        <BackLink text='На главную страницу' url='/' />
        <section className={styles.aboutBanner}>
          <Logo className={styles.logo} />
          <div className={styles.title}>Ваш путь к выгодным финансам</div>
          <div className={styles.text}>
            {checkDesktop() ? (
              <>
                Мы - сервис Снизим.ру - маркетплейс наиболее выгодных предложений <br />
                на рынке финансов. За счет прямых интеграций с партнерами <br />и рекламодателями мы
                можем предложить наилучшие ставки на рынке.
              </>
            ) : (
              <>
                Мы - сервис Снизим.ру - маркетплейс наиболее выгодных предложений на рынке финансов.
                За счет прямых интеграций с партнерами и рекламодателями мы можем предложить
                наилучшие ставки на рынке.
              </>
            )}
          </div>
        </section>
        <section className={styles.cardSection}>
          <AboutCard
            title={'Как это' + '\n' + 'работает?'}
            icon={<Docs />}
            color='#DED6D1'
            text='Мы снижаем стоимость рекламных услуг для своих партнеров, за счет чего можем предложить условия даже лучше, чем на официальном сайте данного партнера - банка или любой другой финансовой организации.'
          />
          <AboutCard
            title={'В чем' + '\n' + 'удобство?'}
            icon={<App />}
            color='#B39D8F'
            text='Снизим.ру - это принцип одного окна для всего финансового рынка России и СНГ: не придется просматривать сотни однотипных сайтов – вся актуальная информация есть у нас. Сравнивайте продукты на одной странице, оставляйте заявки онлайн.'
          />
          <AboutCard
            title={'Что мы?' + '\n' + 'снижаем?'}
            icon={<Contract />}
            color='#DFEB81'
            text='Мы снижаем как процентную ставку, так и общую сумму ежемесячного платежа. Снижаем сумму полного фиксированного платежа или возможной комиссии.'
          />
        </section>
        <section className={styles.history}>
          <div className={styles.title}>История и перспективы</div>
          <div className={styles.text}>
            Запущенный в 2023 году сервис уже является зрелым новостным порталом, собирая наиболее
            актуальные темы для рубрик Финансы, Кредиты, Страхование и Инвестиции.
          </div>
          <div className={styles.text}>
            Сегодня Снизим.ру отображает в одном окне предложения сотен финансовых организаций и
            дает возможность получать индивидуальные подборки продуктов с лучшими условиями на
            основе регулярно обновляющегося персонального кредитного рейтинга.
          </div>
          <div className={styles.text}>
            В 2024 году для наших зарегистрированных пользователей станет доступно рефинансирование
            одного финансового продукта другим полностью онлайн - через свой личный кабинет. Вступай
            в ряды тех, кто получает самые выгодные предложения!
          </div>
        </section>
        <section className={styles.contacts}>
          <div className={styles.title}>Реквизиты</div>
          <div className={styles.container}>
            <div className={styles.row}>
              <AboutCompanyTextField
                title='Юридическое лицо'
                text='Общество с ограниченной ответственностью “СНИЗИМ.РУ”'
              />
              <AboutCompanyTextField
                title='Генеральный директор'
                text='Цветкова Алла Вячеславовна'
              />
              <AboutCompanyTextField
                title='Юридический адрес'
                text={
                  '125363, г. Москва, вн. тер. г. Муниципальный округ Южное Тушино, ул. Сходненская, 25-26'
                }
              />
            </div>
            <div className={styles.row}>
              <AboutCompanyTextField title='ОГРН' text='1247700212528' />
              <AboutCompanyTextField title='ИНН / КПП' text='7733440950 / 773301001' />
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
