import { request } from '../request'
import {
  ICompanyAbout,
  ICompanyCallCenterDTO,
  ICompanyDetails,
  ICompanyItem,
  ICompanyList,
  ICompanyUserAccountDTO,
} from './dto/company'

export interface IGetCompanyListParams {
  page: number
  size: number
}

export function getCompanyList({ page, size }: IGetCompanyListParams): Promise<ICompanyList> {
  return request({
    url: '/snizim-offer/v2/companies',
    params: {
      page,
      size,
    },
  })
}

export function getCompanyInfo(companySlug: string): Promise<ICompanyItem> {
  return request({
    url: `/snizim-offer/v2/companies/${companySlug}`,
  })
}

export function getCompanyAbout(companySlug: string): Promise<ICompanyAbout> {
  return request({
    url: `/snizim-offer/v2/companies/${companySlug}/info`,
  })
}

export function getCompanyUserAccount(companySlug: string): Promise<ICompanyUserAccountDTO> {
  return request({
    url: `/snizim-offer/v2/companies/${companySlug}/user-account`,
  })
}

export function getCompanyCallCenter(companySlug: string): Promise<ICompanyCallCenterDTO> {
  return request({
    url: `/snizim-offer/v2/companies/${companySlug}/call-center`,
  })
}

export function getCompanyDetails(companySlug: string): Promise<ICompanyDetails> {
  return request({
    url: `/snizim-offer/v2/companies/${companySlug}/details`,
  })
}
