import styles from './Loans.module.scss'
import useBackground from '../../../hooks/useBackground'
import { useParams } from 'react-router'
import SearchFormTitle from '../components/SearchFormTitle/SearchFormTitle'
import Scroller from '../../../components/Scroller/Scroller'
import { BannersCategories } from '../../../api/ui/banners'
import SeoInfoBlock from '../../SeoPage/components/SeoInfoBlock/SeoInfoBlock'
import { useShowcase } from '../hooks/useShowcase'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LoanCategoryRu, LoansSubcategory } from '../../../api/ui/dto/loans'
import useGetTextBanner from './hooks/useGetTextBanner'
import { HeadHelmet } from '../../../components/HeadHelment/HeadHelmet'
import { useLoanMeta } from './useLoanMeta'
import useLoans from './useLoans'
import Offer from '../../../components/Offer/Offer'
import Banner from './components/Banner/Banner'
import useBanners from '../hooks/useBanners'
import LoansSkeleton from './Loans.skeleton'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'

export default function Loans() {
  useBackground('#D6D9CF')
  const params = useParams()
  const subcategory = params?.subcategory
  const [pageNum, setPageNum] = useState(0)
  const { template, loansProviderItems, loadLoans } = useLoans(subcategory as LoansSubcategory)
  const {
    fetchShowcase,
    offerList,
    isLoading,
    hasNextPage,
    seoBlock,
    setOfferList,
    setHasNextPage,
  } = useShowcase(BannersCategories.LOAN)
  const { getPostBackUrl } = useGetOfferUrlWithUtm()

  const { banners } = useBanners({
    category: BannersCategories.LOAN,
    subcategory: subcategory.replace('-', '_').toUpperCase() as LoansSubcategory,
  })

  const [loansTitle, setLoansTitle] = useState<string>('')
  const { meta } = useLoanMeta(subcategory.replace('-', '_').toUpperCase() as LoansSubcategory)
  const { text } = useGetTextBanner({
    subcategory: subcategory.replace('-', '_').toUpperCase() as LoansSubcategory,
  })
  const intObserver = useRef<IntersectionObserver | null>(null)
  const lastPostRef = useCallback(
    (offer: HTMLDivElement) => {
      if (isLoading) {
        return
      }

      if (intObserver.current) {
        intObserver.current.disconnect()
      }

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          setPageNum((prev) => prev + 1)
        }
      })

      if (offer) {
        intObserver.current.observe(offer)
      }
    },
    [isLoading, hasNextPage],
  )

  useEffect(() => {
    ;(async function () {
      await fetchShowcase({ pageNum, key: subcategory })
      if (seoBlock) {
        setLoansTitle(seoBlock)
      } else if (subcategory) {
        setLoansTitle(
          LoanCategoryRu[
            subcategory.replace('-', '_').toLowerCase() as keyof typeof LoanCategoryRu
          ],
        )
      }
    })()
  }, [subcategory, pageNum])

  useEffect(() => {
    setPageNum(0)
    setOfferList([])
    setHasNextPage(false)
  }, [subcategory])

  useEffect(() => {
    void loadLoans(offerList)
  }, [offerList])

  const content = useMemo(() => {
    const result = []
    for (let i = 0; i < loansProviderItems.length; i++) {
      const { tag, ...item } = loansProviderItems[i]

      if (loansProviderItems.length === i + 1) {
        result.push(
          <Offer
            ref={lastPostRef}
            item={item}
            tag={tag}
            template={template}
            key={`offer ${item.id}`}
            onClick={getPostBackUrl}
            isRedesignMobile
          />,
        )
      } else {
        result.push(
          <Offer
            item={item}
            tag={tag}
            template={template}
            key={`offer ${item.id}`}
            onClick={getPostBackUrl}
            isRedesignMobile
          />,
        )
      }

      if (banners.length && (i + 1) % 6 === 0) {
        const trueBannerIndex = (i + 1) / 6 - 1
        const banner = banners[trueBannerIndex % banners.length]
        result.push(<Banner className={styles.banner} {...banner} key={`banner ${banner.id}`} />)
      }
    }
    return result
  }, [banners, loansProviderItems, subcategory])

  return (
    <>
      <HeadHelmet description={meta.description} title={loansTitle || meta.title} />
      <Scroller />
      <div className={styles.loans}>
        <SearchFormTitle
          className={styles.searchFormTitle}
          title={loansTitle}
          subtitle='Ставка от 0% за 5 минут!'
          text={text}
        />
        <ul className={styles.providers}>
          {isLoading && pageNum === 0 ? <LoansSkeleton /> : content}
        </ul>
        <SeoInfoBlock code={subcategory.replace('_', '-').toLowerCase() as LoansSubcategory} />
      </div>
    </>
  )
}
