import { useParams } from 'react-router'
import { getCompanyAbout } from '../../../../../../api/ui/company'
import { useEffect, useState } from 'react'
import { ICompanyAbout } from '../../../../../../api/ui/dto/company'

function transformedDTO(data: ICompanyAbout): ICompanyAbout {
  if (!data) {
    throw new Error('Company About is null')
  }

  return {
    id: data.id ?? 0,
    advertiser: data.advertiser ?? '',
    about: data.about ?? '',
    foundingDate: data.foundingDate ?? '',
    network: data.network ?? '',
    files: data.files ?? [],
  }
}

export function useCompanyAbout() {
  const { companySlug } = useParams()
  const [isLoading, setLoading] = useState(false)
  const [companyAbout, setCompanyAbout] = useState<ICompanyAbout>(null)

  async function fetchCompanyAbout() {
    setLoading(true)

    try {
      const result = await getCompanyAbout(companySlug)
      setCompanyAbout(transformedDTO(result))
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void fetchCompanyAbout()

    return () => {
      setCompanyAbout(null)
    }
  }, [])

  return {
    isLoading: isLoading || !companyAbout,
    companyAbout,
  }
}
