import styles from './PropertyBlock.module.scss'
import React, { ReactNode } from 'react'
import joinClassNames from '../../helpers/joinClassNames'

export interface PropertyBlockProps {
  title: string
  text?: string
  width?: string
  size?: 'default' | 'small'
  children?: ReactNode
  colorBlock?: string
}

export default function PropertyBlock({
  title,
  text,
  children,
  width = '100%',
  size = 'default',
  colorBlock = '',
}: PropertyBlockProps) {
  return (
    <div className={styles.propertyBlock} style={{ width: width }}>
      <span className={styles.title}>{title}</span>
      <span style={{ color: colorBlock }} className={joinClassNames(styles.text, styles[size])}>
        {text || children}
      </span>
    </div>
  )
}
