import { ProviderItem, ProviderTag, ProviderTemplate } from '../../../components/Offer/Offer'
import { useMemo, useState } from 'react'
import { getRubles } from '../../../helpers/getRubles'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'
import getTermUnit from '../../../helpers/getTermUnit'
import { BannersCategories } from '../../../api/ui/banners'

export default function useBrokers() {
  const [brokersProviderItems, setBrokersProviderItems] = useState<
    (ProviderItem & { tag: ProviderTag })[]
  >([])
  const { getOfferUrlUtm } = useGetOfferUrlWithUtm()

  function getField3Color(level: string): string {
    switch (level) {
      case 'Высокое':
        return '#35B879'
      case 'Среднее': {
        return '#FF8900'
      }
      case 'Низкое':
        return '#933433'
      default: {
        return '#000'
      }
    }
  }
  async function loadBrokers({ offerList }) {
    const getField1Replaced = () => {
      return null
    }

    const providerItems = offerList?.map((item) => {
      return {
        id: String(item.id),
        tag: {
          text: item.tagText,
          color: '#000000',
          backgroundColor: '#DFEB81',
        },
        title: item.name,
        field1Replaced: getField1Replaced(),
        field1: getRubles(Number(item.maxAmount), true, true),
        field2: `${item.termFrom}-${item.termTo} ${getTermUnit(item.termUnit, item.termTo)}`,
        field3: item.approvalLevel,
        url: getOfferUrlUtm(item.targetUrl, BannersCategories.BROKERS),
        icon: item.logoUrl,
        advertiser: item.advertiser,
        field3Color: getField3Color(item.approvalLevel),
      }
    })

    setBrokersProviderItems(providerItems)
  }

  const template: ProviderTemplate = useMemo(
    () => ({
      buttonText: 'Перейти',
      field1Name: 'сумма до',
      field2Name: 'срок',
      field3Name: 'одобрение',
      size: 's',
      isFlexTitleContainer: true,
      backgroundColorCard: '#EDF0E7',
    }),
    [],
  )

  return {
    brokersProviderItems,
    template,
    loadBrokers,
  }
}
