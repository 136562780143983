import styles from './SignUpFooter.module.scss'
import { ReactComponent as Telegram } from 'assets/icons/telegram.svg'
import { ReactComponent as Whatsapp } from 'assets/icons/whatsapp.svg'

export default function SignUpFooter() {
  return (
    <footer className={styles.signUpFooter}>
      <span>© {new Date().getFullYear()}, ООО «СНИЗИМ.РУ»</span>
      <div className={styles.socialsContainer}>
        <a href='https://web.telegram.org/' target='_blank' rel='noreferrer'>
          <Telegram />
        </a>
        <a href='https://web.whatsapp.com/' target='_blank' rel='noreferrer'>
          <Whatsapp />
        </a>
      </div>
    </footer>
  )
}
