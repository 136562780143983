import styles from './RoundButton.module.scss'
import { ReactNode } from 'react'
import joinClassNames from '../../../../helpers/joinClassNames'

export default function RoundButton({
  children,
  description,
  disabled,
  type = 'default',
  onClick,
}: {
  children: ReactNode
  description?: string
  type?: 'default' | 'success'
  disabled?: boolean
  onClick?: () => void
}) {
  return (
    <button
      className={joinClassNames(styles.roundButton, type && styles[type])}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={styles.circle}>{children}</div>
      {description && <div className={styles.description}>{description}</div>}
    </button>
  )
}
