import styles from '../Company/Company.module.scss'
import CompanyInfo from './components/CompanyInfo/CompanyInfo'
import CompanyTabs from './components/CompanyTabs/CompanyTabs'
import BackLink from '../../components/BackLink/BackLink'

export default function Company() {
  return (
    <section className={styles.company} itemType='https://schema.org/Organization'>
      <section>
        <BackLink text='Рейтинг микрофинансовых организаций' url='/mfo/' />
        <section className={styles.section}>
          <CompanyInfo />
        </section>
        <section className={styles.section}>
          <CompanyTabs />
        </section>
      </section>
    </section>
  )
}
