import { ReactNode } from 'react'
import styles from './SeoToggle.module.scss'
export default function SeoToggle({
  children,
  onClick,
}: {
  children: ReactNode
  onClick?: () => void
}) {
  return (
    <button className={styles.seoToggle} onClick={onClick}>
      {children}
    </button>
  )
}
