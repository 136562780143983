import axios from 'axios'
import { News } from './dto/news'

export async function getNews(): Promise<News[]> {
  const result = await axios.request({
    url: 'https://nemihetoo.beget.app/wp-content/themes/snizim/erms45MdnsKNL321/Kdnekwm314Nsms_sfc.php',
    params: {
      pass: 'dsfjknKNJnsaNsk2',
    },
  })

  return result.data
}
