import styles from '../CompanyTextField/CompanyTextField.module.scss'

interface IProps {
  title: string
  text: string
  itemProp?: string
}

export function CompanyTextField({ title, text, itemProp }: IProps) {
  return (
    <section className={styles.textField}>
      <span className={styles.title}>{title}</span>
      <span className={styles.text} itemProp={itemProp}>
        {text}
      </span>
    </section>
  )
}
