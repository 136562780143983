import checkDesktop from '../../../../../../helpers/checkDesktop'
import ArticleDesktop from './desktop/ArticleDesktop'
import ArticleMobile from './mobile/ArticleMobile'

export interface ArticleProps {
  photoUrl: string
  date: string
  category: string
  title: string
  to?: string
  onClick?: () => void
}

export default function Article({ to, onClick, photoUrl, date, category, title }: ArticleProps) {
  return checkDesktop() ? (
    <ArticleDesktop
      photoUrl={photoUrl}
      date={date}
      category={category}
      title={title}
      to={to}
      onClick={onClick}
    />
  ) : (
    <ArticleMobile
      photoUrl={photoUrl}
      date={date}
      category={category}
      title={title}
      to={to}
      onClick={onClick}
    />
  )
}
