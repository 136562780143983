import styles from './Tabs.module.scss'
import { NavLink } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { useEffect, useRef, useState } from 'react'

export interface TabItem {
  text: string
  to?: string
  onClick?: () => void
}

export default function Tabs({
  items,
  selectedTab = 0, // layoutPaddingValue = 20,
}: {
  items: TabItem[]
  selectedTab?: number
  layoutPaddingValue?: number
}) {
  const navRef = useRef<HTMLDivElement>(null)
  const [idArray, setIdArray] = useState<string[]>([])

  useEffect(() => {
    const acc: string[] = []
    items.forEach(() => {
      acc.push(nanoid())
    })
    setIdArray(acc)
  }, [])

  useEffect(() => {
    if (navRef.current) {
      const tagsElements: (HTMLElement | null)[] = []

      idArray.forEach((id) => {
        tagsElements.push(document.getElementById(id))
      })

      const middleOfWindow = window.innerWidth / 2
      const selectedTabOffset = tagsElements[selectedTab]?.offsetLeft || 0
      const selectedTabWidth = tagsElements[selectedTab]?.offsetWidth || 0

      if (!selectedTabOffset || !selectedTabWidth) {
        navRef.current.scrollTo({
          left: 0,
          behavior: 'smooth',
        })
      }

      const selectedTabMiddlePointOffset = selectedTabOffset + Math.ceil(selectedTabWidth / 2)

      if (selectedTabMiddlePointOffset < middleOfWindow) {
        navRef.current.scrollTo({
          left: 0,
          behavior: 'smooth',
        })
      }

      if (selectedTabMiddlePointOffset >= middleOfWindow) {
        let leftScrollOffset = 0
        for (let i = 0; i < selectedTab; i++) {
          leftScrollOffset += tagsElements[i]?.offsetWidth || 0
        }

        leftScrollOffset -= selectedTabWidth / 2

        navRef.current.scrollTo({
          left: leftScrollOffset,
          behavior: 'smooth',
        })
      }
    }
  }, [selectedTab])

  return (
    <nav className={styles.tabs} ref={navRef}>
      {items.map((item, index) => {
        if (item.to) {
          return (
            <NavLink
              className={`${styles.tab} ${index === selectedTab ? styles.selected : ''}`}
              to={item.to}
              key={`${item.to}${item.text}`}
              id={idArray[index]}
              onClick={item.onClick}
            >
              {item.text}
            </NavLink>
          )
        } else {
          return (
            <button
              className={`${styles.tab} ${index === selectedTab ? styles.selected : ''}`}
              key={`${item.to}${item.text}`}
              onClick={item.onClick}
              id={idArray[index]}
            >
              {item.text}
            </button>
          )
        }
      })}
    </nav>
  )
}
