import styles from './Companies.module.scss'
import Title from '../../components/Title/Title'
import checkDesktop from '../../helpers/checkDesktop'
import { CompanyTable } from './components/CompanyTable/CompanyTable'
import { HeadHelmet } from '../../components/HeadHelment/HeadHelmet'

export default function Companies() {
  return (
    <>
      <HeadHelmet title='Рейтинг микрофинансовых организаций' />
      <Title className={styles.title} level={2}>
        Рейтинг микрофинансовых организаций
      </Title>
      <div className={styles.content}>
        <>
          {checkDesktop() ? (
            <section className={styles.infoBlock}>
              <div className={styles.infoBlockTitle}>Как мы составляем наш рейтинг</div>
              <div className={styles.infoBlockText}>
                Для вашего удобства мы рассчитываем гибридный рейтинг МФО, основанный на оценках
                пользователей, общем количестве отзывов, динамике изменения оценок во времени и
                качестве обратной связи от организаций по заявкам пользователей.
              </div>
            </section>
          ) : null}
        </>
      </div>
      <div className={styles.content}>
        <CompanyTable />
      </div>
    </>
  )
}
