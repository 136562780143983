import styles from './SignUp.module.scss'
import PhoneStep from './steps/0-PhoneStep/PhoneStep'
import { useCallback, useMemo, useState } from 'react'
import FullNameStep from './steps/2-FullNameStep/FullNameStep'
import checkDesktop from '../../helpers/checkDesktop'
import SignUpFooter from './components/SignUpFooter/SignUpFooter'
import { SignUpHeader } from './components/SignUpHeader/SignUpHeader'
import { getSmsCode } from '../../api/sms/sms'
import formatPhoneNumber from '../../helpers/formatPhone'
import SubInfoStep from './steps/1-SubInfoStep/SubInfoStep'
import { useNavigate } from 'react-router-dom'
import Preloader from '../../components/Preloader/Preloader'
import Modal from 'react-modal'
import { MobileWrapper } from './components/MobileWrapper/MobileWrapper'
import { handleRegistration } from '../../api/registration/registration'

const customStyles = {
  overlay: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: 10,
    backgroundColor: 'rgba(28, 42, 64, 0.4)',
    display: 'flex',
    alignItems: 'start',
  },
  content: {
    position: 'relative',
    inset: 'inherit',
    border: 'none',
    background: 'none',
    overflow: 'inherit',
    borderRadius: 0,
    outline: 'none',
    padding: 0,
    height: 'fit-content',
    width: '100%',
  },
} as Modal.Styles

export default function SignUp() {
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [dataForm, setDataForm] = useState(null)
  const [showTopBlock, setShowTopBlock] = useState(false)
  const navigate = useNavigate()
  const getSmsCodeAction = useCallback(async (data: { phone: string }) => {
    setLoading(true)
    try {
      setDataForm(data)
      await getSmsCode({ phone: formatPhoneNumber(data.phone) })
      showTopBlockHandler()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
      setCurrentStep(1)
    }
  }, [])

  const loginUser = useCallback(
    async (values: {
      region: string
      city: string
      house: string
      apartment: string
      street: string
    }) => {
      try {
        setLoading(true)
        const data = {
          email: dataForm.mail,
          phone: formatPhoneNumber(dataForm.phone),
          passport: {
            firstName: dataForm.firstname,
            lastName: dataForm.lastname,
            patronymic: dataForm.patronymic ?? null,
            birthDate: new Date(dataForm.birthDate).toISOString().slice(0, 10),
            birthPlace: dataForm.birthPlace,
            serialNumber: dataForm.serialNumber,
            documentNumber: dataForm.documentNumber,
            departmentCode: dataForm.departmentCode,
            issuedDate: new Date(dataForm.issuedDate).toISOString().slice(0, 10),
            ...values,
          },
        }
        const result = await handleRegistration(data)

        if (result) {
          navigate('/')
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [dataForm],
  )

  const currentStepJSX = useMemo(() => {
    switch (currentStep) {
      case 0:
        return (
          <FullNameStep
            currentStep={currentStep}
            onSuccess={getSmsCodeAction}
            initialValue={dataForm}
          />
        )
      case 1:
        return (
          <PhoneStep
            onSuccess={() => setCurrentStep(2)}
            phone={dataForm.phone}
            onBackward={() => setCurrentStep(0)}
          />
        )
      case 2:
        return <SubInfoStep currentStep={currentStep} onSuccess={loginUser} />
      default:
        return <div>error, state step is {currentStep}</div>
    }
  }, [currentStep])
  const handleCloseTopBlock = () => {
    setShowTopBlock(false)
    document.body.style.overflow = ''
  }

  const showTopBlockHandler = () => {
    setShowTopBlock(true)
    document.body.style.overflow = 'hidden'
  }

  return (
    <div className={styles.signUp}>
      <Modal
        isOpen={showTopBlock}
        onRequestClose={handleCloseTopBlock}
        contentLabel='Success Phone Modal'
        style={customStyles}
        ariaHideApp={false}
      >
        <MobileWrapper hide={handleCloseTopBlock}>
          {showTopBlock && (
            <div className={styles.modalSnackbar}>
              <div className={styles.modalSnackbarContainer}>
                <p className={styles.modalSnackbarHeader}>Успешно!</p>
                <div onClick={handleCloseTopBlock} className={styles.modalSnackbarButton} />
              </div>
              <div className={styles.modalSnackbarFooter}>
                Код подтверждения отправлен <br /> на номер <strong>{dataForm.phone}</strong>
              </div>
            </div>
          )}
        </MobileWrapper>
      </Modal>
      {isLoading ? <Preloader /> : null}
      {!checkDesktop() && <SignUpHeader />}
      {currentStepJSX}
      {checkDesktop() && <SignUpFooter />}
    </div>
  )
}
