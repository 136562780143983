import Title from 'components/Title/Title'
import styles from './LookForCard.module.scss'

interface LookForCardLink {
  text: string
  to: string
}

export interface LookForCardProps {
  title: string
  items: LookForCardLink[]
}
export default function LookForCard({ title, items }: LookForCardProps) {
  return (
    <div className={styles.lookForCard} id={title}>
      <Title className={styles.title} level={4}>
        {title}
      </Title>
      <div className={styles.linksContainer}>
        {items.map(({ to, text }, index) => (
          <a className={styles.link} href={to} key={index}>
            {text}
          </a>
        ))}
      </div>
    </div>
  )
}
