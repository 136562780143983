import styles from './ArticleMobile.module.scss'
import { ReactNode } from 'react'
import Title from '../../../../../../../components/Title/Title'
import { htmlToString } from '../../../../../../../helpers/htmlToString'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

export interface ArticleProps {
  photoUrl: string
  date: string
  category: string
  title: string
  to?: string
  onClick?: () => void
}

export default function ArticleMobile({
  to,
  onClick,
  photoUrl,
  date,
  category,
  title,
}: ArticleProps) {
  let container: ReactNode
  const content = (
    <div className={styles.container}>
      <LazyLoadImage
        className={styles.image}
        height={130}
        src={photoUrl}
        alt={`фото для ${title}`}
        width={100}
        effect='blur'
      />
      <div className={styles.textInfo}>
        <Title className={styles.title}>{htmlToString(title)}</Title>
        <div className={styles.metaInfo}>
          <span>{date}</span>
          <span className={styles.space}>{category}</span>
        </div>
      </div>
    </div>
  )

  if (to) {
    container = (
      <a className={styles.link} href={to}>
        {content}
      </a>
    )
  } else {
    container = (
      <button className={styles.button} onClick={onClick}>
        {content}
      </button>
    )
  }

  return <article className={styles.article}>{container}</article>
}
