import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { ICompanyInfo } from '../../../../api/ui/dto/company'
import { getCompanyInfo } from '../../../../api/ui/company'
import { getCompanyInfoData, transformDto } from './helper'
import { useNavigate } from 'react-router-dom'

export const useCompanyInfo = () => {
  const { companySlug } = useParams()
  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo>(null)
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function fetchCompanyInfo() {
    setLoading(true)

    try {
      const result = await getCompanyInfo(companySlug)
      const transformedData = transformDto(result)

      const info = getCompanyInfoData(transformedData)

      setCompanyInfo(info)
    } catch (e) {
      console.error(e)
      navigate('/404')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void fetchCompanyInfo()
  }, [])

  return {
    isLoading: isLoading || !companyInfo,
    companyInfo,
  }
}
