import { TermUnit } from './common'

export interface UiCreditOfferDto {
  id: number
  name: string
  rateFix: true
  rateFrom: number
  rateTo: number
  rateUnit: string
  periodFrom?: number
  periodTo?: number
  periodUnit?: TermUnit
  reducedRateFix: true
  reducedRateFrom: number
  reducedRateTo: number
  reducedRateUnit: string
  termFix: true
  termFrom: number
  termTo: number
  termUnit: TermUnit
  maxAmount: number
  advertiser: string
  logoUrl: string
  targetUrl: string
  sum: number
  tagText: string
  subcategory: CreditSubcategory
  maintenanceFrom?: number
  maintenanceTo?: number
  maintenanceUnit?: TermUnit
  downPaymentFix?: boolean
  downPaymentFrom?: number
  downPaymentTo?: number
  downPaymentUnit?: TermUnit
}

export enum CreditSubcategory {
  CONSUMER_LOAN = 'CONSUMER_LOAN',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT_MASTER = 'CREDIT_MASTER',
  MORTGAGE = 'MORTGAGE',
}
