import { useCallback, useState } from 'react'
import { sendAnalytics } from '../../api/analytics/analytics'
import cookie from 'js-cookie'

export function useAnalytics() {
  const [, setLoading] = useState(false)

  const sendClickAnalytics = useCallback(async () => {
    try {
      setLoading(true)

      await sendAnalytics({
        visitorUuid: cookie.get('visitorUuid') || null,
        clientId: 0,
        actionDate: new Date().toISOString(),
        utmSource: sessionStorage.getItem('utm_source') || null,
        utmCampaign: sessionStorage.getItem('utm_campaign') || null,
        pageIn: window.location.pathname || null,
        referrer: document.referrer || null,
      })
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])
  return {
    sendClickAnalytics,
  }
}
