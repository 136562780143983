import { request } from '../request'
import { UiCreditOfferDto, CreditSubcategory } from './dto/uiCreditOfferDto'

export interface GetCreditParams {
  subcategory: CreditSubcategory
}

export function getCredit({ subcategory }: GetCreditParams): Promise<UiCreditOfferDto[]> {
  return request({
    url: '/ui/v1/offers/credit',
    params: {
      subcategory,
    },
  })
}

export function getAllCredits(): Promise<UiCreditOfferDto[]> {
  return request({
    url: '/ui/v1/offers/credit',
  })
}
