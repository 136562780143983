import Title from 'components/Title/Title'
import styles from './BestOffers.module.scss'
import Offer from '../../../../components/Offer/Offer'
import useBestOffers from './useBestOffers'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import { useMemo } from 'react'
import './BestOffer.scss'
import { Link } from 'react-router-dom'
import { useGetOfferUrlWithUtm } from '../../../../hooks/useGetOfferUrlWithUtm'

export default function BestCompaniesOffers({ className }: { className?: string }) {
  const { offsets } = useBestOffers()
  const { getPostBackUrl } = useGetOfferUrlWithUtm()

  const slider = useMemo(() => {
    return (
      <Splide
        hasTrack={false}
        options={{
          autoWidth: true,
          perPage: 4,
          type: 'slide',
          gap: '10px',
          arrows: false,
          pagination: true,
          wheel: true,
          breakpoints: {
            940: {
              perPage: 1,
              drag: 'free',
            },
          },
        }}
        aria-labelledby='dynamic-slides-example-heading'
      >
        <SplideTrack>
          {offsets.map((offer) => (
            <SplideSlide key={offer.item.title + offer.item.id} role='complementary'>
              <div className={styles.offerContainer}>
                <Offer
                  item={offer.item}
                  tag={offer.tag}
                  template={offer.template}
                  key={`offer ${offer.item.id}`}
                  onClick={getPostBackUrl}
                />
              </div>
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    )
  }, [offsets])

  return (
    <div className={`${styles.bestOffers} ${className || ''}`} id='best-offer-anchor'>
      <Title level={5} className={styles.title}>
        Лучшие предложения займов
      </Title>
      <div className={styles.cards}>
        {offsets.length ? (
          slider
        ) : (
          <div className={styles.flexContainer}>
            <div className={styles.stub} />
            <div className={styles.stub} />
            <div className={styles.stub} />
            <div className={styles.stub} />
            <div className={styles.stub} />
          </div>
        )}
      </div>
      <div>
        <Link to='/showcase/loans' className={styles.link}>
          Все предложения займов
        </Link>
      </div>
    </div>
  )
}
