import styles from './Credits.module.scss'
import useBackground from '../../../hooks/useBackground'
import useCredits from './useCredits'
import SearchFormTitle from '../components/SearchFormTitle/SearchFormTitle'
import { useParams } from 'react-router'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import loadCategoryData, { categoryDto } from './loadCategoryData'
import { CreditSubcategory } from '../../../api/ui/dto/uiCreditOfferDto'
import Scroller from '../../../components/Scroller/Scroller'
import CreditsSkeleton from './Credits.skeleton'
import useBanners from '../hooks/useBanners'
import { BannersCategories } from '../../../api/ui/banners'
import Banner from '../Brokers/components/Banner/Banner'
import { HeadHelmet } from '../../../components/HeadHelment/HeadHelmet'
import { useCreditMeta } from './useCreditMeta'
import Offer from '../../../components/Offer/Offer'
import SeoInfoBlock from '../../SeoPage/components/SeoInfoBlock/SeoInfoBlock'
import { useShowcase } from '../hooks/useShowcase'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'

export default function Credits() {
  useBackground('#E0D6CF')
  const { category } = useParams()
  const subcategory = category?.replace('-', '_').toUpperCase()
  const subcategoryName = subcategory?.replace('_', '-').toLowerCase() as CreditSubcategory
  const [creditProviderItems, setCreditProviderItems] = useState<categoryDto>({} as categoryDto)
  const { credits, fetchCredits } = useCredits()
  const [pageNum, setPageNum] = useState(0)
  const {
    fetchShowcase,
    offerList,
    isLoading,
    hasNextPage,
    seoBlock,
    setOfferList,
    setHasNextPage,
  } = useShowcase(BannersCategories.CREDITS)
  const { getPostBackUrl } = useGetOfferUrlWithUtm()

  const { banners } = useBanners({
    category: BannersCategories.CREDITS,
    subcategory: subcategory as CreditSubcategory,
  })
  const { meta } = useCreditMeta(subcategory as CreditSubcategory)
  const intObserver = useRef<IntersectionObserver | null>(null)
  const lastPostRef = useCallback(
    (offer: HTMLDivElement) => {
      if (isLoading) {
        return
      }

      if (intObserver.current) {
        intObserver.current.disconnect()
      }

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          setPageNum((prev) => prev + 1)
        }
      })

      if (offer) {
        intObserver.current.observe(offer)
      }
    },
    [isLoading, hasNextPage],
  )
  useEffect(() => {
    ;(async function () {
      await fetchShowcase({ pageNum, key: subcategoryName })
      if (seoBlock) {
        const categoryData = loadCategoryData('custom')
        categoryData.title = seoBlock
        void setCreditProviderItems(categoryData)
      } else if (subcategory) {
        void setCreditProviderItems(loadCategoryData(subcategory.toLowerCase()))
      }
    })()
  }, [category, pageNum])

  const content = useMemo(() => {
    const result = []
    for (let i = 0; i < credits.length; i++) {
      const { tag, template, ...item } = credits[i]

      if (credits.length === i + 1) {
        result.push(
          <Offer
            ref={lastPostRef}
            item={item}
            tag={tag}
            template={template}
            key={i}
            onClick={getPostBackUrl}
            isRedesignMobile
          />,
        )
      } else {
        result.push(<Offer item={item} tag={tag} template={template} key={i} onClick={getPostBackUrl} isRedesignMobile />)
      }

      if (banners.length && (i + 1) % 6 === 0) {
        const trueBannerIndex = (i + 1) / 6 - 1
        const banner = banners[trueBannerIndex % banners.length]
        result.push(<Banner className={styles.banner} {...banner} key={`banner ${banner.id}`} />)
      }
    }
    return result
  }, [banners, credits, subcategoryName])

  useEffect(() => {
    setPageNum(0)
    setOfferList([])
    setHasNextPage(false)
  }, [subcategory])

  useEffect(() => {
    void fetchCredits({ offerList })
  }, [offerList])

  return (
    <>
      <HeadHelmet {...meta} />
      <Scroller />
      <div className={styles.credits}>
        <SearchFormTitle
          className={styles.searchFormTitle}
          title={creditProviderItems.title}
          subtitle={creditProviderItems.subtitle}
          text={creditProviderItems.text}
        />
        <ul className={styles.providers}>
          {isLoading && pageNum === 0 ? <CreditsSkeleton /> : content}
        </ul>
        <SeoInfoBlock code={subcategory.replace('_', '-').toLowerCase()} />
      </div>
    </>
  )
}
