import { ReactNode } from 'react'
import styles from './Subtitle.module.scss'

export default function Subtitle({
  className,
  children,
}: {
  className?: string
  children?: ReactNode
}) {
  return (
    <h3 className={`${styles.Subtitle} ${className}`}>
      <mark className={styles.Mark}>{children}</mark>
    </h3>
  )
}
