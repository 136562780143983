import { TermUnit } from '../api/ui/dto/common'

export default function getShortTermUnit(termUnit: TermUnit) {
  switch (termUnit) {
    case 'DAY':
      return 'дн.'
    case 'YEAR':
      return 'г.'
    case 'MONTH':
      return 'мес.'
    default:
      return termUnit
  }
}
