import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from 'assets/icons/logo-white.svg'
import { ReactComponent as Exit } from 'assets/icons/exit.svg'
import styles from './SignUpHeader.module.scss'

export function SignUpHeader() {
  return (
    <div>
      <header className={styles.headerReg}>
        <div className={styles.flexContainer}>
          <Link to={{ pathname: '/' }} aria-label='Ссылка на главную страницу'>
            <Logo className={styles.logo} />
          </Link>
          <Link to={{ pathname: '/sign_in' }} aria-label='Ссылка на главную страницу'>
            <Exit className={styles.exit} />
          </Link>
        </div>
        <div className={styles.container}>
          <h1 className={styles.title}>Новый аккаунт</h1>
          <div className={styles.subTitle}>Ваш личный кабинет с лучшими условиями</div>
        </div>
      </header>
    </div>
  )
}
