import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './MobileWrapper.module.scss'

interface IProps {
  children: ReactNode
  open?: boolean
  hide?: () => void
  isTop?: boolean
}
export const MobileWrapper = ({
  children,
  open = false,
  hide = () => {},
  isTop = false,
}: IProps) => {
  const scrollableContainer = useRef(null)

  const [startY, setStartY] = useState(null)
  const [displacement, setDisplacement] = useState(isTop ? -1000 : 1000)

  useEffect(() => {
    if (!open) {
      setDisplacement(isTop ? -1000 : 1000)
      setStartY(null)
    }
  }, [open])

  useEffect(() => {
    setDisplacement(0)
  }, [])

  const onTouchMove = (event) => {
    if (scrollableContainer.current.scrollTop === 0) {
      const { clientY } = event.touches[0]

      // eslint-disable-next-line no-unused-expressions
      !startY && setStartY(clientY)

      if (isTop) {
        const delta = clientY - (startY || clientY)
        setDisplacement(delta <= 0 ? delta : 0)
      } else {
        const delta = clientY - (startY || clientY)
        setDisplacement(delta >= 0 ? delta : 0)
      }
    }
  }

  const onTouchEnd = () => {
    if (displacement > 100 && !isTop) {
      hide()
    }
    if (displacement < 100 && isTop) {
      hide()
    }
    setStartY(null)
  }

  return (
    <div
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      style={{ transform: `translateY(${displacement}px)` }}
      ref={scrollableContainer}
      className={styles.mobileWrapper}
    >
      {children}
    </div>
  )
}
