import styles from './Companies.module.scss'
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg'

export const CompaniesSkeleton = () => {
  return (
    <>
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
    </>
  )
}

export const CompaniesMoreItemSkeleton = () => {
  return (
    <div className={styles.spinnerBlock}>
      <Spinner className={styles.spinner} />
    </div>
  )
}

export const CompanyItemImageSkeleton = () => {
  return <div className={styles.stubImage} />
}
