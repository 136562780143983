import Title from 'components/Title/Title'
import Tabs, { TabItem } from 'components/Tabs/Tabs'
import { useRef, useState } from 'react'
import styles from './OftenLookingFor.module.scss'
import LookForCard, { LookForCardProps } from './components/LookForCard/LookForCard'
import checkDesktop from '../../../../helpers/checkDesktop'

export default function OftenLookingFor({ className }: { className?: string }) {
  const cardsContainerRef = useRef<HTMLDivElement>(null)
  const [selectedTab, setSelectedTab] = useState(0)

  function onTabClick(tabIndex: number) {
    setSelectedTab(tabIndex)
    if (cardsContainerRef.current) {
      cardsContainerRef.current.scrollTo({
        /*
          252 - ширина карточки
          10 - паддинг
          5 - сдвиг назад для красоты
           */
        left: tabIndex * 252 + tabIndex * 10 - 5,
        behavior: 'smooth',
      })
    }
  }
  const tabs: TabItem[] = [
    {
      text: 'Кредиты',
      onClick: () => onTabClick(0),
    },
    {
      text: 'Кредитные карты',
      onClick: () => onTabClick(1),
    },
    {
      text: 'Ипотека',
      onClick: () => onTabClick(2),
    },
    {
      text: 'Дебетовые карты',
      onClick: () => onTabClick(3),
    },
    {
      text: 'Рефинансирование',
      onClick: () => onTabClick(4),
    },
    {
      text: 'Микрозаймы',
      onClick: () => onTabClick(5),
    },
  ]
  const cards: LookForCardProps[] = [
    {
      title: 'Кредиты',
      items: [
        {
          text: 'Кредиты с плохой кредитной историей',
          to: 'https://snizim.ru/kredity/kredity-s-plohoj-kreditnoj-istoriej/',
        },
        {
          text: 'Кредиты без справок',
          to: 'https://snizim.ru/kredity/kredity-bez-spravok/',
        },
        {
          text: 'Кредиты без поручителей',
          to: 'https://snizim.ru/kredity/kredity-bez-poruchitelej/',
        },
        {
          text: 'Кредиты без справок и поручителей',
          to: 'https://snizim.ru/kredity/kredity-bez-spravok-i-poruchitelej/',
        },
        {
          text: 'Кредиты с просрочками',
          to: 'https://snizim.ru/kredity/kredity-s-prosrochkami/',
        },
        {
          text: 'Автокредиты',
          to: 'https://snizim.ru/kredity/avtokredity/',
        },
      ],
    },
    {
      title: 'Кредитные карты',
      items: [
        {
          text: 'Кредитные карты без отказа',
          to: 'https://snizim.ru/kreditnye-karty/kreditnye-karty-bez-otkaza/',
        },
        {
          text: 'Кредитные карты с плохой историей',
          to: 'https://snizim.ru/kreditnye-karty/kreditnaya-karta-s-plohoj-kreditnoj-istoriej/',
        },
        {
          text: 'Кредитные карты с доставкой',
          to: 'https://snizim.ru/kreditnye-karty/kreditnye-karty-s-dostavkoj-kurerom/',
        },
        {
          text: 'Бсплатные кредитные карты',
          to: 'https://snizim.ru/kreditnye-karty/besplatnye-kreditnye-karty/',
        },
        {
          text: 'Кредитные карты с льготным периодом',
          to: 'https://snizim.ru/kreditnye-karty/kreditnaya-karta-s-lgotnym-periodom/',
        },
        {
          text: 'Кредитные карты с кешбэком',
          to: 'https://snizim.ru/kreditnye-karty/kreditnye-karty-s-keshbekom/',
        },
      ],
    },
    {
      title: 'Ипотека',
      items: [
        {
          text: 'Семейная ипотека',
          to: 'https://snizim.ru/ipoteka/semejnaya-ipoteka/',
        },
        {
          text: 'Сельская ипотека',
          to: 'https://snizim.ru/ipoteka/selskaya-ipoteka/',
        },
        {
          text: 'Ипотека без первоначального взноса',
          to: 'https://snizim.ru/ipoteka/ipoteka-bez-pervonachalnogo-vznosa/',
        },
        {
          text: 'Ипотека с господдержкой',
          to: 'https://snizim.ru/ipoteka/ipoteka-s-gospodderzhkoj/',
        },
        {
          text: 'Дальневосточная ипотека',
          to: 'https://snizim.ru/ipoteka/dalnevostochnaya-ipoteka/',
        },
        {
          text: 'Ипотека для IT специалистов',
          to: 'https://snizim.ru/ipoteka/ipoteka-dlya-it-speczialistov/',
        },
      ],
    },
    {
      title: 'Дебетовые карты',
      items: [
        {
          text: 'Моментальные дебетовые карты',
          to: 'https://snizim.ru/debetovye-karty/momentalnye-debetovye-karty/',
        },
        {
          text: 'Дебетовые карты с кэшбэком',
          to: 'https://snizim.ru/debetovye-karty/debetovye-karty-s-keshbekom/',
        },
        {
          text: 'Дебетовые карты с бесплатным обслуживанием',
          to: 'https://snizim.ru/debetovye-karty/debetovye-karty-s-besplatnym-obsluzhivaniem/',
        },
        {
          text: 'Виртуальные дебетовые карты',
          to: 'https://snizim.ru/debetovye-karty/virtualnye-debetovye-karty/',
        },
        {
          text: 'Дебетовые карты для путешествий',
          to: 'https://snizim.ru/debetovye-karty/debetovye-karty-dlya-puteshestvij/',
        },
        {
          text: 'Премиальные дебетовые карты ',
          to: 'https://snizim.ru/debetovye-karty/premialnye-debetovye-karty/',
        },
      ],
    },
    {
      title: 'Рефинансирование',
      items: [
        {
          text: 'Рефинансирование кредитных карт',
          to: 'https://snizim.ru/refinansirovanie/refinansirovanie-kreditnyh-kart/',
        },
        {
          text: 'Рефинансирование автокредитов',
          to: 'https://snizim.ru/refinansirovanie/refinansirovanie-avtokreditov/',
        },
        {
          text: 'Рефинансирование потребительских кредитов',
          to: 'https://snizim.ru/refinansirovanie/refinansirovanie-potrebitelskih-kreditov/',
        },
        {
          text: 'Рефинансирование с плохой кредитной историей',
          to: 'https://snizim.ru/refinansirovanie/refinansirovanie-s-plohoj-kreditnoj-istoriej/',
        },
        {
          text: 'Рефинансирование ипотеки',
          to: 'https://snizim.ru/refinansirovanie/refinansirovanie-ipoteki/',
        },
        {
          text: 'Рефинансирование микрозаймов онлайн',
          to: 'https://snizim.ru/refinansirovanie/refinansirovanie-mikrozajmov/',
        },
      ],
    },
    {
      title: 'Микрозаймы',
      items: [
        {
          text: 'Займы онлайн на карту',
          to: 'https://snizim.ru/mikrozajmy/zajmy-onlajn-na-kartu/',
        },
        {
          text: 'Мгновенные займы',
          to: 'https://snizim.ru/mikrozajmy/mgnovennye-zajmy/',
        },
        {
          text: 'Займы через Госуслуги',
          to: 'https://snizim.ru/mikrozajmy/zajmy-cherez-gosuslugi/',
        },
        {
          text: 'Займы на карту сбербанка',
          to: 'https://snizim.ru/mikrozajmy/zajmy-na-kartu-sberbanka/',
        },
        {
          text: 'Займы на 30 дней',
          to: 'https://snizim.ru/mikrozajmy/zajmy-na-30-dnej/',
        },
        {
          text: 'Долгосрочные займы на карту',
          to: 'https://snizim.ru/mikrozajmy/onlajn-zajmy-na-mesyacz/',
        },
      ],
    },
  ]

  return (
    <div className={`${className} ${styles.oftenLookingFor}`}>
      <Title className={styles.title} level={3}>
        Люди часто ищут
      </Title>
      {!checkDesktop() && <Tabs items={tabs} selectedTab={selectedTab} />}
      <div className={styles.cards} ref={cardsContainerRef}>
        {cards.map((item, index) => (
          <LookForCard {...item} key={index} />
        ))}
      </div>
    </div>
  )
}
