import { Outlet } from 'react-router'
import { createPortal } from 'react-dom'
import Header from '../components/Header/Header'
import checkDesktop from '../helpers/checkDesktop'
import styles from '../modules/NotFoundPage/NotFoundPage.module.scss'
import SignUpFooter from '../modules/SignUp/components/SignUpFooter/SignUpFooter'
import React from 'react'

export default function DarkLayout() {
  return (
    <>
      {createPortal(<Header mode='dark' />, document.getElementsByTagName('body')[0], 'header')}
      <Outlet />
      {createPortal(
        checkDesktop() && (
          <div className={styles.footerNotFound}>
            <SignUpFooter />
          </div>
        ),
        document.getElementsByTagName('body')[0],
        'footer',
      )}
    </>
  )
}
