import React, { useEffect, useRef, useState } from 'react'
import styles from './SuggestInput.module.scss'
import { Props } from 'react-input-mask'
import BdMaskedInput from '../BdMaskedInput/BdMaskedInput'
import useClickOutside from '../../../../hooks/useClickOutside'

export interface SuggestItem {
  text: string
  onClick(): void
}

export interface SuggestInputProps extends Props {
  label?: string
  open?: boolean
  suggests: SuggestItem[]
}
export default function SuggestInput({
  suggests,
  open = true,
  label,
  ...restProps
}: SuggestInputProps) {
  const ref = useRef(null)
  const [isSuggestBoxOpen, setIsSuggestBoxOpen] = useState<boolean>(false)
  useClickOutside(ref, () => setIsSuggestBoxOpen(false))

  useEffect(() => {
    if (suggests.length) {
      setIsSuggestBoxOpen(true)
    }
  }, [suggests, open])

  function onSuggestItemClick(onClick: () => void) {
    return function () {
      setIsSuggestBoxOpen(false)
      onClick()
    }
  }

  return (
    <div ref={ref} className={styles.suggestInput}>
      {label && (
        <label htmlFor={restProps.name} className={styles.label}>
          {label}
        </label>
      )}
      <BdMaskedInput className={styles.input} {...restProps} />
      <div className={`${styles.suggestBox} ${isSuggestBoxOpen ? styles.open : ''}`}>
        {suggests.map(({ text, onClick }) => (
          <div className={styles.suggestItem} onClick={onSuggestItemClick(onClick)} key={text}>
            {text}
          </div>
        ))}
      </div>
    </div>
  )
}
