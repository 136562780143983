import styles from '../CompanyInfoField/CompanyInfoField.module.scss'
import React, { ReactNode } from 'react'

interface IProps {
  icon: ReactNode
  title: string
  message: string
}

export default function CompanyInfoField({ icon, title, message }: IProps) {
  return (
    <section className={styles.field}>
      <span className={styles.icon}>{icon}</span>
      <div className={styles.textContainer}>
        <span className={styles.title}>{title}</span>
        <span className={styles.message}>{message}</span>
      </div>
    </section>
  )
}
