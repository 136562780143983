import axios from 'axios'

enum BUILD_MODE {
  DEV = 'dev',
  PROD = 'prod',
}

function getBaseUrl(mode: BUILD_MODE) {
  switch (mode) {
    case BUILD_MODE.DEV: {
      return 'https://ui-api.dev.snizim.ru'
    }
    case BUILD_MODE.PROD: {
      return 'https://ui-api.snizim.ru/'
    }
    default: {
      return 'https://ui-api.dev.snizim.ru'
    }
  }
}

const buildMode = process.env.BUILD_ENV as BUILD_MODE

const axiosInstance = axios.create({
  baseURL: getBaseUrl(buildMode),
  timeout: 5000,
})

axiosInstance.interceptors.response.use((response) => response.data)

const { request } = axiosInstance

export { request }
