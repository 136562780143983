import styles from './SignIn.module.scss'
import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { ReactComponent as ShowPassword } from 'assets/icons/showPassword.svg'
import Title from 'components/Title/Title'
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import LinkButton from '../../components/LinkButton/LinkButton'

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isPasswordShown, setIsPasswordShown] = useState(false)

  return (
    <div className={styles.signIn}>
      <header className={styles.header}>
        <Link to='/'>
          <Logo className={styles.logo} />
        </Link>
        <Link to='/'>
          <Close className={styles.close} />
        </Link>
      </header>
      <main>
        <Title className={styles.title} level={1}>
          Добро пожаловать в личный кабинет
        </Title>
        <form className={styles.form}>
          <Input
            value={email}
            name='email'
            label='Электронная почта'
            placeholder='ivan@gmail.com'
            onInput={(event) => setEmail(event.currentTarget.value)}
          />
          <Input
            value={password}
            className={styles.password}
            name='password'
            label='Пароль'
            suffix={ShowPassword}
            type={isPasswordShown ? 'text' : 'password'}
            onSuffixClick={() => setIsPasswordShown(!isPasswordShown)}
            onInput={(event) => setPassword(event.currentTarget.value)}
          />
          <Button
            className={styles.submitButton}
            type='submit'
            uiType={email && password ? 'green' : 'secondary'}
          >
            Войти
          </Button>
          <Link className={styles.link} to='/'>
            Что делать, если я забыл пароль?
          </Link>
        </form>
        <div className={styles.firstVisit}>Впервые здесь?</div>
        <LinkButton to='/sign_up' uiType='yellow'>
          Пройти регистрацию
        </LinkButton>
      </main>
    </div>
  )
}
