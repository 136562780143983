import { useEffect, useState } from 'react'
import { getCompanyList } from '../../api/ui/company'
import { ICompanyItem } from '../../api/ui/dto/company'

export const useCompanies = (pageNum = 0) => {
  const [companyList, setCompanyList] = useState<ICompanyItem[]>([])
  const [isLoading, setLoading] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)

  async function getCompanyTableList() {
    setLoading(true)

    try {
      const result = await getCompanyList({ page: pageNum, size: 10 })
      const list = result.content.map((item) => ({
        ...item,
        logoUrl: item.offer ? item.offer.logoUrl : '',
      }))
      setCompanyList((state) => [...state, ...list])
      setHasNextPage(!(result.last && result.totalPages === pageNum))
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void getCompanyTableList()
  }, [pageNum])

  return {
    isLoading,
    companyList,
    hasNextPage,
  }
}
