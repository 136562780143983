import { useParams } from 'react-router'
import { getCompanyDetails } from '../../../../../../api/ui/company'
import { useEffect, useState } from 'react'
import { ICompanyDetails } from '../../../../../../api/ui/dto/company'
import { getFormattedPhone } from '../../../../../../helpers/getFormattedPhone'

function transformedDTO(data: ICompanyDetails): ICompanyDetails {
  if (!data) {
    throw new Error('Company Details is null')
  }

  return {
    id: data?.id ?? 0,
    advertiser: data?.advertiser ?? '',
    licence: data?.licence ?? '',
    address: data?.address ?? '',
    mainAddress: data?.mainAddress ?? '',
    numericCode: data?.numericCode ?? '',
    ogrnTitle: data?.ogrnTitle ?? '',
    okpoTitle: data?.okpoTitle ?? '',
    phone: data?.phone ? getFormattedPhone(data.phone) : '',
    email: data?.email ?? '',
    targetUrl: data?.targetUrl ?? '',
    mainUrl: data?.mainUrl ?? '',
  }
}

export function useCompanyDetails() {
  const { companySlug } = useParams()
  const [isLoading, setLoading] = useState(false)
  const [companyDetails, setCompanyDetails] = useState<ICompanyDetails>(null)

  async function fetchCompanyAbout() {
    setLoading(true)

    try {
      const result = await getCompanyDetails(companySlug)
      setCompanyDetails(transformedDTO(result))
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void fetchCompanyAbout()

    return () => {
      setCompanyDetails(null)
    }
  }, [])

  return {
    isLoading: isLoading || !companyDetails,
    companyDetails,
  }
}
