import { ProviderItem, ProviderTag, ProviderTemplate } from '../../../components/Offer/Offer'
import { Loan, LoansSubcategory } from '../../../api/ui/dto/loans'
import { useState } from 'react'
import getTermUnit from '../../../helpers/getTermUnit'
import { getRubles } from '../../../helpers/getRubles'
import { getAllLoans } from '../../../api/ui/loans'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'

export default function useBestLoans() {
  const [loans, setLoansProviderItems] = useState<(ProviderItem & { tag: ProviderTag })[]>([])
  const [isLoading, setLoading] = useState(false)
  const { getOfferUrlUtm } = useGetOfferUrlWithUtm()

  async function loadLoans() {
    const getField1 = (item: Loan) => {
      if (item.subcategory !== LoansSubcategory.SNIZIM_PERCENT) {
        return item.rateFrom ? String(item.rateFrom) + '%' : '0%'
      }
      return item.reducedRateFrom ? String(item.reducedRateFrom) + '%' : '0%'
    }

    const getField1Replaced = (item: Loan) => {
      if (item.subcategory === LoansSubcategory.SNIZIM_PERCENT && item.rateFrom !== null) {
        return String(item.rateFrom) + '%'
      }

      return null
    }

    setLoading(true)
    try {
      const result = await getAllLoans()

      const providerItems = result.map((item) => {
        return {
          id: String(item.id),
          tag: {
            text: item.tagText,
            color: '#000000',
            backgroundColor: '#DFEB81',
          },
          title: item.name,
          field1Replaced: getField1Replaced(item),
          field1: getField1(item),
          field2: `${item.termFrom}-${item.termTo} ${getTermUnit(item.termUnit, item.termTo)}`,
          field3: getRubles(Number(item.maxAmount)),
          url: getOfferUrlUtm(item.targetUrl),
          icon: item.logoUrl,
          advertiser: item.advertiser,
        }
      })

      setLoansProviderItems(providerItems)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const template: ProviderTemplate = {
    buttonText: 'Перейти',
    field1Name: 'ставка',
    field2Name: 'срок',
    field3Name: 'сумма до',
    size: 's',
    isFlexTitleContainer: true,
    backgroundColorCard: '#EDF0E7',
  }

  return {
    loans,
    template,
    loadLoans,
    isLoading,
  }
}
