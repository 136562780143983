import styles from './Preloader.module.scss'
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg'

export default function Preloader() {
  return (
    <div className={styles.preloader}>
      <div className={styles.container}>
        <Spinner className={styles.spinner} />
      </div>
    </div>
  )
}
