import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import MainPage from '../modules/MainPage/MainPage'
import MainLayout from '../layouts/MainLayout'
import SignIn from '../modules/SignIn/SignIn'
import EmptyLayout from '../layouts/EmptyLayout'
import Bankruptcy from '../modules/ShowCase/Bankruptcy/Bankruptcy'
import Loans from '../modules/ShowCase/Loans/Loans'
import Credits from '../modules/ShowCase/Credits/Credits'
import SignUp from '../modules/SignUp/SignUp'
import BestOfferLoans from '../modules/BestOfferShowCase/BestLoans/BestLoans'
import BestCredits from '../modules/BestOfferShowCase/BestCredits/BestCredits'
import Companies from '../modules/Companies/Companies'
import CompanyLayout from '../layouts/CompanyLayout'
import Company from '../modules/Company/Company'
import PrivacyPolicy from '../modules/Promo/PrivacyPolicy/PrivacyPolicy'
import UserTerms from '../modules/Promo/Terms/UserTerms'
import AboutCompany from '../modules/About/AboutCompany/AboutCompany'
import Brokers from '../modules/ShowCase/Brokers/Brokers'
import NotFoundPage from '../modules/NotFoundPage/NotFoundPage'
import DarkLayout from '../layouts/DarkLayout'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<EmptyLayout />}>
        <Route path='/sign_in' element={<SignIn />} />
        <Route path='/sign_up' element={<SignUp />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path='/' element={<MainPage />} />
        <Route path='/showcase'>
          <Route path='bankruptcy' element={<Bankruptcy />} />
          <Route path='loans' element={<BestOfferLoans />} />
          <Route path='loans/:subcategory' element={<Loans />} />
          <Route path='credits/' element={<BestCredits />} />
          <Route path='credits/:category' element={<Credits />} />
          <Route path='brokers/' element={<Brokers />} />
        </Route>
        <Route path='/promo'>
          <Route path='privacy-policy/' element={<PrivacyPolicy />} />
          <Route path='polzovatelskoe-soglashenie/' element={<UserTerms />} />
        </Route>
        <Route path='/about'>
          <Route path='company' element={<AboutCompany />} />
        </Route>
      </Route>
      <Route element={<CompanyLayout />}>
        <Route path='/mfo/' element={<Companies />} />
        <Route path='/mfo/:companySlug/' element={<Company />} />
      </Route>
      <Route element={<DarkLayout />}>
        <Route path='*' element={<NotFoundPage />} />
        <Route path='/404' element={<NotFoundPage />} />
      </Route>
    </>,
  ),
)
