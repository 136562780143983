import { ReactNode, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import cookie from 'js-cookie'
import { AnalyticsContext } from './AnalyticsContext'
import { useAnalytics } from './useAnalytics'

interface IProps {
  children: ReactNode
}

export default function AnalyticsProvider({ children }: IProps) {
  const { sendClickAnalytics } = useAnalytics()

  useEffect(() => {
    if (!cookie.get('visitorUuid')) {
      cookie.set('visitorUuid', uuidv4())
    }
  }, [])

  useEffect(() => {
    ;(async function () {
      try {
        await sendClickAnalytics()
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
    <AnalyticsContext.Provider value={{ sendClickAnalytics }}>{children}</AnalyticsContext.Provider>
  )
}
