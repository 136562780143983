import Step from '../../components/Step/Step'
import Container from '../../components/Container/Container'
import styles from './FullName.module.scss'
import Input from '../../../../components/Input/Input'
import { CommonStepProps } from '../types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import SuggestInput, { SuggestItem } from '../../components/SuggestInput/SuggestInput'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { postFindPassportDepartment } from '../../../../api/registration/registration'
import BdMaskedInput from '../../components/BdMaskedInput/BdMaskedInput'
import { debounce } from '../../../../helpers/debounce'

dayjs.extend(customParseFormat)

export const formattersPhone = {
  raw: (value) => `+${String(value).replace(/\D/g, '')}`,
  format: (value) => {
    let cleanValue = String(value).replace(/^\+8/, '').replace(/^\+7/, '').replace(/\D/g, '')

    // In case of autofill from browser storage.
    if (cleanValue.length === 11) {
      cleanValue = cleanValue.slice(1)
    }

    const groups = cleanValue.match(/([0-9]{1,3})?([0-9]{1,3})?([0-9]{1,2})?([0-9]{1,2})?/)
    let result = ''

    if (groups[1]) {
      result += `+7 (${groups[1]}`
    }

    if (groups[2]) {
      result += `) ${groups[2]}`
    }

    if (groups[3]) {
      result += `-${groups[3]}`
    }

    if (groups[4]) {
      result += `-${groups[4]}`
    }

    return result
  },
}

export default function FullNameStep({
  onSuccess,
  currentStep,
  initialValue,
}: CommonStepProps & {
  initialValue: {
    lastname: string
    firstname: string
    patronymic?: string
    serialNumber: string
    documentNumber: string
    issuedDate: string
    departmentCode: string
    birthPlace: string
    birthDate: string
    mail: string
    phone: string
  }
}) {
  const validationSchema = Yup.object().shape({
    lastname: Yup.string()
      .matches(/[А-я-]+/g, 'Фамилия должна быть написана с использованием кириллицы')
      .required('Поле "Фамилия" обязательно для заполнения'),
    firstname: Yup.string()
      .matches(/[А-я-]+/g, 'Имя должно быть написано с использованием кириллицы')
      .required('Поле "Имя" обязательно для заполнения'),
    patronymic: Yup.string()
      .matches(/[А-я-]+/g, 'Отчество должно быть написано с использованием кириллицы')
      .optional(),
    serialNumber: Yup.string()
      .length(4, 'серия состоит из 4-х цифр')
      .matches(/^\d+$/, { message: 'серия состоит из 4-х цифр' })
      .required('Заполните серию паспорта'),
    documentNumber: Yup.string()
      .length(6, 'номер состоит из 6-ти цифр')
      .matches(/^\d+$/, { message: 'номер состоит из 6-ти цифр' })
      .required('Заполните номер паспорта'),
    issuedDate: Yup.string()
      .required('Заполните дату выдачи паспорта')
      .test('min-date', 'Вы должны быть младше 100 лет', (value) => {
        return Boolean(
          value.length &&
            !value.includes('_') &&
            dayjs(value, 'DD.MM.YYYY').isAfter(dayjs().add(-100, 'year')),
        )
      })
      .when('birthDate', ([birthDate], schema) => {
        if (dayjs(birthDate, 'DD.MM.YYYY').isValid()) {
          return schema.test('issuedAfter14', 'Дата выдачи паспорта указана с ошибкой', (value) => {
            const issuedDateDayJs = dayjs(value, 'DD.MM.YYYY')
            return issuedDateDayJs.isAfter(dayjs(birthDate, 'DD.MM.YYYY').add(14, 'year'))
          })
        }
      }),

    departmentCode: Yup.string()
      .required('Заполните код подразделения')
      .test('full-mask', 'Заполните код подразделения', (value) => {
        return !value.includes('_')
      }),
    birthPlace: Yup.string()
      .matches(/[А-я-]+/g, 'Место рождение должно быть написано с использованием кириллицы')
      .required('Место рождения обязательно к заполнению'),
    birthDate: Yup.string()
      .required('Дата рождения обязательна к заполнению')
      .test('max-date', 'Вы должны быть старше 18 лет', (value) => {
        return Boolean(
          value.length &&
            !value.includes('_') &&
            dayjs(value, 'DD.MM.YYYY').isBefore(dayjs().add(-18, 'year')),
        )
      })
      .test('min-date', 'Вы должны быть младше 100 лет', (value) => {
        return Boolean(
          value.length &&
            !value.includes('_') &&
            dayjs(value, 'DD.MM.YYYY').isAfter(dayjs().add(-100, 'year')),
        )
      }),
    mail: Yup.string()
      .matches(/^[A-z0-9._%+-]+@[A-z0-9._%+-]+\.((ru)|(com))$/, {
        message: 'Введите электронную почту в домене .ru или .com',
      })
      .required('Поле "Электронная почта" обязательно для заполнения'),
    phone: Yup.string()
      .matches(/^\+7 (\(\d{3}\)) (\d{3})-(\d{2})-(\d{2})$/, {
        message: 'Введите мобильный номер РФ',
      })
      .required('Поле "Телефон" обязательно для заполнения'),
  })

  const [issueBySuggests, setIssueBySuggests] = useState<SuggestItem[]>([])
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    getFieldMeta,
    setValues,
    setFieldValue,
    dirty,
    isValid,
    setFieldError,
  } = useFormik<{
    lastname: string
    firstname: string
    patronymic?: string
    serialNumber: string
    documentNumber: string
    issuedDate: string
    departmentCode: string
    birthPlace: string
    birthDate: string
    mail: string
    phone: string
  }>({
    initialValues: {
      lastname: initialValue?.lastname ?? '',
      firstname: initialValue?.firstname ?? '',
      serialNumber: initialValue?.serialNumber ?? '',
      documentNumber: initialValue?.documentNumber ?? '',
      issuedDate: initialValue?.issuedDate ?? '',
      departmentCode: initialValue?.departmentCode ?? '',
      birthPlace: initialValue?.birthPlace ?? '',
      birthDate: initialValue?.birthDate ?? '',
      mail: initialValue?.mail ?? '',
      phone: initialValue?.phone ?? '',
    },
    onSubmit() {
      onSuccess({ ...values })
    },
    validationSchema,
  })

  useEffect(() => {
    debounce(async function () {
      if (!values.departmentCode) return

      const { error: departmentCodeError } = getFieldMeta('departmentCode')

      if (!departmentCodeError && values.departmentCode) {
        return
      }

      try {
        const result = await postFindPassportDepartment({
          query: values.departmentCode,
        })

        const suggests: SuggestItem[] = result.data.suggestions.map((suggest) => {
          return {
            text: `${suggest.data.code} ${suggest.data.name}`,
            onClick() {
              setValues({
                ...values,
                departmentCode: suggest.data.code,
              })
              setFieldError('departmentCode', '')
            },
          }
        })
        setIssueBySuggests(suggests)
      } catch (e) {
        console.error(e)
      }
    }, 300)()
  }, [values.departmentCode])

  function onPhoneChange(e: ChangeEvent<HTMLInputElement>) {
    setFieldValue(
      'phone',
      `+7 (${formattersPhone.format(e.target.value).replace(/^\+7 \(/, '')}`,
      false,
    )
  }

  return (
    <Step
      onSubmit={handleSubmit}
      currentStep={currentStep}
      ready={!dirty || Boolean(Object.keys(errors).length) || !isValid}
      error={
        errors.lastname ||
        errors.firstname ||
        errors.serialNumber ||
        errors.documentNumber ||
        errors.issuedDate ||
        errors.departmentCode ||
        errors.birthPlace ||
        errors.birthDate ||
        errors.mail ||
        errors.phone
      }
    >
      <Container>
        <form className={styles.fullNameStep} onSubmit={handleSubmit}>
          <div className={styles.item}>
            <Input
              name='lastname'
              label='Фамилия'
              placeholder='Иванов'
              value={values.lastname}
              onChange={handleChange}
            />
            <Input
              name='firstname'
              label='Имя'
              placeholder='Иван'
              value={values.firstname}
              onChange={handleChange}
            />
            <Input
              name='patronymic'
              label='Отчество (если имеется)'
              placeholder='Иванович'
              value={values.patronymic}
              onChange={handleChange}
            />
            <div className={styles.row}>
              <div className={styles.space}>
                <div>
                  <label htmlFor={'serialNumber'} className={styles.label}>
                    Серия паспорта
                  </label>
                  <BdMaskedInput
                    name='serialNumber'
                    placeholder='__ __'
                    autoComplete='off'
                    value={values.serialNumber}
                    onChange={handleChange}
                    mask='9999'
                  />
                </div>
              </div>
              <div>
                <label htmlFor={'documentNumber'} className={styles.label}>
                  Номер паспорта
                </label>
                <BdMaskedInput
                  name='documentNumber'
                  mask='999999'
                  placeholder='__ __'
                  autoComplete='off'
                  value={values.documentNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <label htmlFor={'issuedDate'} className={styles.label}>
                Дата выдачи паспорта
              </label>
              <BdMaskedInput
                name='issuedDate'
                mask='99.99.9999'
                placeholder=''
                autoComplete='off'
                value={values.issuedDate}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.item}>
            <SuggestInput
              suggests={issueBySuggests}
              mask='999-999'
              placeholder='___-___'
              name='departmentCode'
              label='Код подразделения'
              autoComplete='off'
              value={values.departmentCode}
              onChange={handleChange}
            />
            <div>
              <label htmlFor={'birthDate'} className={styles.label}>
                Дата рождения
              </label>
              <BdMaskedInput
                name='birthDate'
                mask='99.99.9999'
                autoComplete='off'
                placeholder='__.__.____'
                value={values.birthDate}
                onChange={handleChange}
              />
            </div>
            <Input
              name='birthPlace'
              label='Место рождения'
              placeholder=''
              value={values.birthPlace}
              onChange={handleChange}
            />
            <Input
              name='phone'
              placeholder='000) 000-00-00'
              label='Ваш основной телефон'
              prefix='+ 7 ('
              value={values.phone.replace(/^\+7 \(/, '')}
              onChange={onPhoneChange}
              maxLength={14}
            />
            <Input
              name='mail'
              label='Ваша актуальная электронная почта'
              placeholder='ivan@gmail.com'
              value={values.mail}
              onChange={handleChange}
            />
          </div>
        </form>
      </Container>
    </Step>
  )
}
