import styles from './PhoneStep.module.scss'
import Container from '../../components/Container/Container'
import Input from '../../../../components/Input/Input'
import { useFormik } from 'formik'
import Timer from './components/Timer/Timer'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import Button from '../../../../components/Button/Button'
import Step from '../../components/Step/Step'
import { CommonStepProps } from '../types'
import Scroller from '../../../../components/Scroller/Scroller'
import { getSmsCode, handleSms } from '../../../../api/sms/sms'
import Document from './components/Document/Document'
import formatPhoneNumber from '../../../../helpers/formatPhone'
import Title from '../../../../components/Title/Title'
import { DOCUMENTS } from './constants'
import Preloader from '../../../../components/Preloader/Preloader'
import { EOperationStatus, ESmsCodeStatus } from '../../../../api/sms/dto/sms'

export default function PhoneStep({
  onSuccess,
  onBackward,
  phone,
}: CommonStepProps & { phone: string }) {
  const [time, setTime] = useState(60000)
  const [isGetCodeButtonDisabled, setGetCodeButtonDisabled] = useState(true)
  const [error, setError] = useState<string>('')
  const [isReady, setReady] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const { values, handleChange, handleSubmit } = useFormik<{
    code: string
  }>({
    initialValues: {
      code: '',
    },
    onSubmit() {
      setError(null)
      onSuccess()
    },
  })

  useEffect(() => {
    if (time === 0) {
      setGetCodeButtonDisabled(false)
    }
  }, [time])

  const onGetCodeClick = useCallback(async () => {
    setTime(60000)
    setGetCodeButtonDisabled(true)

    try {
      setLoading(true)
      const result = await getSmsCode({ phone: formatPhoneNumber(phone) })

      if (result.status !== EOperationStatus.SENT) {
        throw new Error(result.status)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [phone])

  function onTimerFinish() {
    setTime(0)
  }

  const onCodeChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      handleChange(e)
      try {
        if (e.currentTarget.value.trim().length === 6) {
          setLoading(true)
          const result = await handleSms({
            phone: formatPhoneNumber(phone),
            code: e.currentTarget.value.trim(),
          })
          if (result.success === ESmsCodeStatus.MATCH) {
            setReady(true)
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    },
    [phone],
  )

  return (
    <Step error={error} ready={!isReady} onSubmit={handleSubmit}>
      {isLoading ? <Preloader /> : null}
      <Scroller />
      <div className={styles.phoneStep}>
        <Container>
          <form>
            <div className={styles.codeLabel}>
              Введите код подтверждения, который был отправлен на номер {phone}:
            </div>
            <div className={styles.timerGroup}>
              <Input
                name='code'
                label='Код подтверждения'
                autoComplete='one-time-code'
                onChange={onCodeChange}
                disabled={isLoading}
                value={values.code}
                maxLength={6}
              />
              <div className={styles.flexContainer}>
                <Button
                  className={styles.button}
                  type='button'
                  onClick={onGetCodeClick}
                  uiType='secondary'
                  disabled={isGetCodeButtonDisabled}
                >
                  Отправить код повторно
                </Button>
                <Timer onFinish={onTimerFinish} time={time} />
              </div>
            </div>
            <button onClick={onBackward} className={`${styles.hint} ${styles.link}`}>
              Изменить номер
            </button>
            <div className={styles.docContainer}>
              <Title className={styles.docListTitle} level={6}>
                Вводя код, вы соглашаетесь с условиями следующих документов:
              </Title>
              <ul>
                {DOCUMENTS.map((item, index) => (
                  <li className={styles.docItem} key={index}>
                    <Document {...item} />
                  </li>
                ))}
              </ul>
            </div>
          </form>
        </Container>
      </div>
    </Step>
  )
}
