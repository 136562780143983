import React, { memo } from 'react'
import { ICompanyItem } from '../../../../../../api/ui/dto/company'
import { Link } from 'react-router-dom'
import styles from '../../../../Companies.module.scss'
import CompanyImage from '../CompanyImage/CompanyImage'
import checkDesktop from '../../../../../../helpers/checkDesktop'
import { ReactComponent as Star } from 'assets/icons/star-green.svg'

const RatingBlock = ({ averageRating, total }: { averageRating: number; total: number }) => {
  function getTotalSuffix(total: number) {
    const source = { zero: 'оценок', one: 'оценка', few: 'оценки', many: 'оценки' }
    const pr = new Intl.PluralRules('ru')

    if (!total) {
      return source.zero
    }

    return source[pr.select(total)]
  }

  return (
    <div className={styles.ratingBlock}>
      <div className={styles.rating}>
        <div>{averageRating}</div>
        <Star className={styles.star} />
      </div>
      <div className={styles.total}>{total + ' ' + getTotalSuffix(total)}</div>
    </div>
  )
}

const CompanyRefListItem = React.forwardRef<
  HTMLDivElement,
  { company: ICompanyItem; index: number }
>(({ company, index }, ref) => {
  const Cell = () => (
    <Link
      role='row'
      key={company.id}
      className={`${styles.row} ${styles.rowLink} ${styles.rowContent}`}
      to={`/mfo/${company.slug}/`}
    >
      <div role='gridcell' className={`${styles.cell} ${styles.cellNumber} ${styles.mr0}`}>
        {index + 1}
      </div>
      <div role='gridcell' className={`${styles.cell} ${styles.cellLogo}`}>
        <CompanyImage logoUrl={company.logoUrl} name={company.name} />
      </div>
      <div role='gridcell' className={`${styles.cell} ${styles.cellName}`}>
        {company.name}
      </div>
      <div role='gridcell' className={`${styles.cell} ${styles.cellRating}`}>
        <RatingBlock averageRating={company.rating.averageRating} total={company.rating.total} />
      </div>
      {checkDesktop() && (
        <>
          <div role='gridcell' className={`${styles.cell} ${styles.cellInfo} ${styles.greenCell}`}>
            <div>{company.rating.total}</div>
          </div>
          <div role='gridcell' className={`${styles.cell} ${styles.cellInfo} ${styles.greenCell}`}>
            <div>0</div>
          </div>
          <div role='gridcell' className={`${styles.cell} ${styles.cellInfo} ${styles.greenCell}`}>
            <div>0</div>
          </div>
        </>
      )}
    </Link>
  )

  if (ref) {
    return (
      <div ref={ref}>
        <Cell />
      </div>
    )
  }

  return <Cell />
})
CompanyRefListItem.displayName = 'CompanyRefListItem'

const CompanyListItem = memo(CompanyRefListItem)

export default CompanyListItem
