import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import { ReactComponent as Burger } from 'assets/icons/burger.svg'
import styles from './Header.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { useCallback, useEffect, useRef, useState } from 'react'
import NavMenu from './components/NavMenu/NavMenu'

interface IProps {
  mode?: string
}

export default function Header({ mode }: IProps) {
  const [isDropdownShown, setIsDropdownShown] = useState(false)
  const { search } = useLocation()
  const touchStartY = useRef(0)
  const touchEndY = useRef(0)
  const heightStart = useRef<number | undefined>(0)

  const dropdown = useRef<HTMLDivElement>(null)

  const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

  useEffect(() => {
    onDropdownSizeChange()
  }, [isDropdownShown])

  function onDropdownSizeChange() {
    if (dropdown.current) dropdown.current.style.height = 'auto'
  }

  const preventScroll = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  const preventKeywordScroll = useCallback((e: { keyCode: number; preventDefault: () => void }) => {
    switch (e.keyCode) {
      case 9:
      case 32:
      case 37:
      case 39:
      case 38:
      case 40:
        e.preventDefault()
        break
      default:
        break
    }
  }, [])

  function onProductsHover() {
    document.body.addEventListener('scroll', preventScroll, { passive: false })
    document.body.addEventListener(wheelEvent, preventScroll, { passive: false })
    document.body.addEventListener('DOMMouseScroll', preventScroll, { passive: false })
    document.body.addEventListener('keydown', preventKeywordScroll, { passive: false })
    setIsDropdownShown(true)
  }

  function onCloseActionFire() {
    document.body.removeEventListener('touchstart', onTouchStart, true)
    document.body.removeEventListener('touchmove', onTouchMove, true)
    document.body.removeEventListener('touchend', onTouchEnd, true)
    if (dropdown.current) {
      dropdown.current.style.height = '0'
      dropdown.current.style.overflow = 'hidden'
      dropdown.current.style.padding = '0'
    }
    setTimeout(() => {
      setIsDropdownShown(false)
      touchStartY.current = 0
      touchEndY.current = 0
      document.body.style.overflow = 'initial'
      document.body.removeEventListener('scroll', preventScroll, false)
      document.body.removeEventListener(wheelEvent, preventScroll, false)
      document.body.removeEventListener('DOMMouseScroll', preventScroll, false)
      document.body.removeEventListener('keydown', preventKeywordScroll, false)
    }, 350)
  }

  function onBurgerClick() {
    document.body.style.overflow = 'hidden'
    document.body.addEventListener('touchstart', onTouchStart, true)
    document.body.addEventListener('touchmove', onTouchMove, true)
    document.body.addEventListener('touchend', onTouchEnd, true)
    setIsDropdownShown(true)
  }

  const onTouchStart = useCallback((evt: TouchEvent) => {
    touchStartY.current = evt.targetTouches[0].clientY
    heightStart.current = dropdown.current?.scrollHeight
  }, [])

  const onTouchMove = useCallback((evt: TouchEvent) => {
    touchEndY.current = evt.targetTouches[0].clientY
    setTimeout(() => {
      const diff = touchStartY.current - touchEndY.current
      if (dropdown.current && diff && heightStart.current) {
        if (diff > 0) {
          dropdown.current.style.height = `${heightStart.current - diff}px`
        } else {
          dropdown.current.style.height = `${heightStart.current + diff * -1}px`
        }
      }
    })
  }, [])

  const onTouchEnd = useCallback(() => {
    setTimeout(() => {
      const diff = touchStartY.current - touchEndY.current
      if (dropdown.current && touchEndY.current !== 0 && (diff > 300 || touchEndY.current < 10)) {
        onCloseActionFire()
      }
    })
  }, [])

  if (window.innerWidth > 1024) {
    return (
      <div className={`${styles.headerLayout} ${mode && mode === 'dark' ? styles.dark : ''}`}>
        <header className={styles.header}>
          <Link to={{ pathname: '/', search }} aria-label='Ссылка на главную страницу'>
            <Logo className={`${styles.logo} ${mode && mode === 'dark' ? styles.darkLogo : ''}`} />
          </Link>
          <span
            className={`${styles.anchors} ${mode && mode === 'dark' ? styles.darkAnchors : ''}`}
          >
            <Link to={{ pathname: '/', search }} onMouseEnter={onProductsHover}>
              Продукты
            </Link>
            <Link to={{ hash: 'best-offer-anchor', search }}>Подбор</Link>
            <a href='/novosti'>Новости</a>
            <Link to={{ hash: 'help', search }}>Помощь</Link>
          </span>
          <div className={styles.stubContainer} />
          {isDropdownShown && (
            <div className={styles.dropdown}>
              <NavMenu onMouseLeave={onCloseActionFire} onCloseActionFire={onCloseActionFire} />
            </div>
          )}
        </header>
      </div>
    )
  }

  return (
    <div className={`${styles.headerLayout} ${mode && mode === 'dark' ? styles.dark : ''}`}>
      <header className={styles.header}>
        <button className={styles.burgerButton} onClick={onBurgerClick} aria-label='Меню'>
          <Burger className={`${styles.user} ${mode && mode === 'dark' ? styles.darkLogo : ''}`} />
        </button>
        <Link to='/' aria-label='Ссылка на главную страницу'>
          <Logo className={`${styles.logo} ${mode && mode === 'dark' ? styles.darkLogo : ''}`} />
        </Link>
        <div className={styles.stubContainer} />
        {isDropdownShown && (
          <div className={styles.dropdown} onClick={onCloseActionFire}>
            <NavMenu
              onCloseActionFire={onCloseActionFire}
              onSizeChange={onDropdownSizeChange}
              ref={dropdown}
            />
          </div>
        )}
      </header>
    </div>
  )
}
