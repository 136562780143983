import { getSeoSection } from '../../../../api/ui/client'
import { useEffect, useState } from 'react'

export default function useSeoInfoBlock({ code }: { code: string }) {
  const [isLoading, setIsLoading] = useState(false)
  const [seoBlock, setSeoBlock] = useState({
    code,
    title: null,
    text: null,
  })
  async function fetchData() {
    setIsLoading(true)
    try {
      const result = await getSeoSection({ code })
      setSeoBlock(result)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    ;(async function () {
      await fetchData()
    })()
  }, [])

  return {
    isLoading,
    seoBlock,
  }
}
