export const debounce = (func: (...arg: never) => void, wait: number) => {
  let timeout = null
  return (...args) => {
    const later = () => {
      timeout = null
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}
