import { ReactNode } from 'react'
import styles from './CardStack.module.scss'

export default function CardStack({
  className,
  children,
}: {
  className?: string
  children?: ReactNode
}) {
  return (
    <div className={`${styles.cardStack} ${className}`}>
      <div className={styles.cardContainer}>{children}</div>
    </div>
  )
}
