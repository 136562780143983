import { useEffect, useState } from 'react'
import { HeadHelmetProps } from '../../../components/HeadHelment/HeadHelmet'
import { LoansSubcategory } from '../../../api/ui/dto/loans'

export const useLoanMeta = (subcategory: LoansSubcategory) => {
  const [meta, setMeta] = useState<HeadHelmetProps>({
    title: '',
    description: '',
  })
  function getHead(subcategory: LoansSubcategory): HeadHelmetProps {
    switch (subcategory) {
      case LoansSubcategory.SNIZIM_PERCENT: {
        return {
          title: 'Снизить проценты по займам | Уменьшить проценты по микрозаймам на Снизим.ру',
          description:
            'Снизьте процент по займам микрофинансовых организаций на Снизим.ру. ' +
            'Сравните предложения МФО и выберите лучшее предложение по уменьшению процента по микрозаймам.',
        }
      }
      case LoansSubcategory.SNIZIM_PAYMENT: {
        return {
          title: 'Уменьшить сумму платежа по займам онлайн на Снизим.ру',
          description:
            'Уменьшите процент по займам микрофинансовых организаций на Снизим.ру. Сравните предложения МФО и выберите лучшее предложение по уменьшению суммы платежа по микрозаймам.',
        }
      }
      case LoansSubcategory.LONG_TERM: {
        return {
          title: 'Долгосрочные займы – получить микрозайм на долгий срок онлайн на Снизим.ру',
          description:
            'Подобрать и оформить долгосрочный займ под низкий процент от надежных микрокредитных организаций. На сайте Снизим.ру можно подобрать и оформить микрозайм на карту банка Открытие мгновенно без поручителей, залога и комиссии.',
        }
      }
      case LoansSubcategory.SHORT_TERM: {
        return {
          title: 'Краткосрочные займы – получить микрозайм онлайн на карту на короткий срок ',
          description:
            'Выберите и оставьте заявку онлайн на получение краткосрочного займа на карту под низкий процент. Сервис Снизим.ру поможет оформить микрозайм на короткий срок мгновенно на выгодных условиях.',
        }
      }
      case LoansSubcategory.BIG_AMOUNT: {
        return {
          title: 'Займы на большую сумму – взять большой займ онлайн на карту на Снизим.ру',
          description:
            'Выберите и оставьте заявку онлайн на получение займа на большую сумму на карту под низкий процент. Сервис Снизим.ру поможет оформить большой микрозайм мгновенно под низкий процент.',
        }
      }
      case LoansSubcategory.ZERO_PERCENTS: {
        return {
          title: 'Займы под 0%: взять первый микрозайм под ноль процентов онлайн на Снизим.ру',
          description:
            'Выберите и оставьте заявку онлайн на получение займа на карту под 0 процентов. Сервис Снизим.ру поможет оформить первый микрозайм под ноль процентов мгновенно.',
        }
      }
      case LoansSubcategory.PTS_LOAN: {
        return {
          title: 'Займы под ПТС: взять займ под залог ПТС автомобиля онлайн',
          description:
            'Выберите и оставьте заявку онлайн на получение займа под залог ПТС автомобиля. Сервис Снизим.ру поможет оформить микрозайм под залог ПТ автомобиля мгновенно под низкий процент.',
        }
      }
      case LoansSubcategory.MASTER_LOAN: {
        return {
          title: '',
          description:
            'Выберите и оставьте заявку онлайн на получение займа под залог ПТС автомобиля. Сервис Снизим.ру поможет оформить микрозайм под залог ПТ автомобиля мгновенно под низкий процент.',
        }
      }
      default: {
        return {
          title: '',
          description: '',
        }
      }
    }
  }

  useEffect(() => {
    setMeta(getHead(subcategory))
  }, [subcategory])

  return {
    meta,
  }
}
