import styles from '../CompanyInfo/CompanyInfo.module.scss'
import { useCompanyInfo } from './useCompanyInfo'
import CompanyInfoSkeleton from './CompanyInfo.skeleton'
import { ReactComponent as TermIcon } from 'assets/icons/term.svg'
import { ReactComponent as MaxAmountIcon } from 'assets/icons/maxAmount.svg'
import Field from '../CompanyInfoField/CompanyInfoField'
import LinkButton from '../../../../components/LinkButton/LinkButton'
import { HeadHelmet } from '../../../../components/HeadHelment/HeadHelmet'

export default function CompanyInfo() {
  const { companyInfo, isLoading } = useCompanyInfo()

  if (isLoading) {
    return <CompanyInfoSkeleton />
  }

  return (
    <>
      <HeadHelmet
        title={`Займ в МФО ${companyInfo?.name} на карту онлайн: условия, проценты, требования к заемщикам`}
        description={`Подробная информация о МФО ${companyInfo?.name}. Взять займ на карту в МФО ${companyInfo?.name}, оформив заявку онлайн на сайте. Адреса офисов, номера телефонов, режим работы, официальный сайт, личный кабинет.
`}
      />
      <div className={styles.companyInfo}>
        <section className={styles.content}>
          <header className={styles.header}>
            <img
              className={styles.logo}
              src={companyInfo?.logoUrl}
              alt={'company logo'}
              itemProp='logo'
            />
            <div className={styles.container}>
              <h1 className={styles.name} itemProp='name'>
                {companyInfo?.name}
              </h1>
              <span className={styles.advertiser}>{companyInfo?.advertiser}</span>
            </div>
          </header>
          <footer className={styles.footer}>
            <Field icon={<TermIcon />} title='Срок займа' message={companyInfo.term} />
            <div className={styles.fieldSpace}>
              <Field icon={<MaxAmountIcon />} title='Сумма займа' message='до 300 000 рублей' />
            </div>
            <div className={styles.fluidContainer}>
              <LinkButton
                className={styles.button}
                to={companyInfo?.targetUrl}
                target='_blank'
                uiType='secondary'
                title={companyInfo?.targetUrl}
                rel='nofollow'
              >
                Взять займ
              </LinkButton>
            </div>
          </footer>
        </section>
      </div>
    </>
  )
}
