import ProductItem, {
  AdvantageItemProps,
} from '../../../../../../components/ProductItem/ProductItem'
import styles from './AdvantageList.module.scss'

export default function AdvantageList({ items }: { items: AdvantageItemProps[] }) {
  return (
    <ul className={styles.advantageList}>
      {items.map((item, index) => (
        <ProductItem icon={item.icon} content={item.content} link={item.link} key={index} />
      ))}
    </ul>
  )
}
