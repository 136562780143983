import { ReactComponent as FileIcon } from 'assets/icons/file.svg'
import { Link } from 'react-router-dom'
import styles from '../CompanyFilesField/CompanyFilesField.module.scss'

interface IProps {
  title: string
  url: string
}

export default function CompanyFilesField({ title, url }: IProps) {
  return (
    <section className={styles.fileField}>
      <Link to={url} target='_blank' rel='nofollow' className={styles.link}>
        <FileIcon className={styles.icon} />
        <span className={styles.title}>{title}</span>
      </Link>
    </section>
  )
}
