import React from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async'

export type HeadHelmetProps = {
  description?: string
  title?: string
  keywords?: string
}

export const HeadHelmet = ({ description = '', title = '', keywords = '' }: HeadHelmetProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta content={description} name='description' />
        <meta content={keywords} name='keywords' />
        <meta content={title} name='title' />
        <meta content={window.location.href} name='url' />
        <meta content={description} property='og:description' />
        <meta content={title} property='og:title' />
        <meta content={window.location.href} property='og:url' />
      </Helmet>
    </HelmetProvider>
  )
}
