import { cloneElement, ReactElement, ReactNode } from 'react'
import styles from './AboutCard.module.scss'

interface IAboutCard {
  title: string | ReactNode
  icon: ReactElement
  color: string
  text: string
}

export default function AboutCard({ title, icon, color, text }: IAboutCard) {
  return (
    <section className={styles.aboutCard} style={{ backgroundColor: color }}>
      <header className={styles.header}>
        <div className={styles.title}>{title}</div>
        {cloneElement(icon, { className: styles.icon })}
      </header>
      <footer className={styles.text}>{text}</footer>
    </section>
  )
}
