import styles from './Timer.module.scss'
import { useEffect, useState } from 'react'
import joinClassNames from '../../../../../../helpers/joinClassNames'
export default function Timer({ onFinish, time }: { onFinish(): void; time?: number }) {
  const [remainedTime, setRemainedTime] = useState(time)
  useEffect(() => {
    if (time) {
      setRemainedTime(time)
    }
  }, [time])

  useEffect(() => {
    if (remainedTime && remainedTime >= 1000) {
      setTimeout(() => setRemainedTime(remainedTime - 1000), 1000)
    } else {
      onFinish()
    }
  }, [remainedTime])

  return (
    <div className={joinClassNames(styles.timer, remainedTime ? styles.active : '')}>
      {remainedTime ? Math.round(remainedTime / 1000) : '--'}
    </div>
  )
}
