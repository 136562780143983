import styles from './Document.module.scss'
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg'

export interface DocumentProps {
  title: string
  url: string
}

export default function Document({ title, url }: DocumentProps) {
  return (
    <a className={styles.document} href={url} target='_blank' rel='noreferrer'>
      <DocumentIcon />
      <div>{title}</div>
    </a>
  )
}
