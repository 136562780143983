export default function formatPhoneNumber(phoneNumberString: string): string {
  const cleaned = ('' + phoneNumberString)
    .replace(/\\D/g, '')
    .replaceAll(' ', '')
    .replaceAll('-', '')
    .replaceAll(')', '')
    .replaceAll('(', '')
    .replaceAll('+7', '')
  return '8' + cleaned
}
