import { request } from '../request'
import { UIBannerDto } from './dto/banners'
import { CreditSubcategory } from './dto/uiCreditOfferDto'
import { LoansSubcategory } from './dto/loans'
import { BankruptcySubcategory } from './dto/bankruptcy'

export enum BannersCategories {
  LOAN = 'LOAN',
  CREDITS = 'CREDIT',
  BANKRUPTCY = 'BANKRUPTCY',
  BROKERS = 'BROKER',
}

export type BannerParamsType = {
  category: BannersCategories
  subcategory?: CreditSubcategory | LoansSubcategory | BankruptcySubcategory
}

export function getBanners(params: BannerParamsType): Promise<UIBannerDto[]> {
  return request({
    url: '/ui/v1/banners',
    params,
  })
}
