import styles from './MainPage.module.scss'
import CardStack from './components/CardStack/CardStack'
import Card from './components/CardStack/components/Card/Card'
import useProductsList from '../../hooks/useProductsList'
import BestOffers from './components/BestOffers/BestOffers'
import SnizimMagazine from './components/SnizimMagazine/SnizimMagazine'
import OftenLookingFor from './components/OftenLookingFor/OftenLookingFor'
import { useScrollToAnchor } from '../../hooks/useScrollToAnchor'
import { HeadHelmet } from '../../components/HeadHelment/HeadHelmet'
import Title from '../../components/Title/Title'
export default function MainPage() {
  const { cards } = useProductsList()

  useScrollToAnchor()

  return (
    <>
      <HeadHelmet
        title='Снизим.ру – рефинансирование, кредитование, займы, банкротство'
        description='Снизим.ру – сервис, где можно сравнить, рассчитать и выбрать подходящие кредиты, ипотеку, рефинансирование, займы, условия по банковским картам.'
      />
      <main className={styles.mainPage}>
        <Title className={styles.title} level={1}>
          Снизим.ру – ваш путь к выгодным финансам
        </Title>
        <CardStack className={styles.cardStack}>
          {cards.map((item, index) => (
            <Card {...item} key={index} />
          ))}
        </CardStack>
        <BestOffers className={styles.bestOffers} />
        <SnizimMagazine className={styles.magazine} />
        <OftenLookingFor className={styles.oftenLookingFor} />
      </main>
    </>
  )
}
