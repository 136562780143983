import { useState } from 'react'
import { TabItem } from '../../../../components/Tabs/Tabs'

export enum ETabsIndex {
  ABOUT = 0,
  USER_ACCOUNT = 1,
  CALL_CENTER = 2,
  DETAILS = 3,
}
enum ETabsNames {
  ABOUT = 'О компании',
  USER_ACCOUNT = 'Личный кабинет',
  CALL_CENTER = 'Горячая линия',
  DETAILS = 'Контакты',
}

export function useCompanyTabs() {
  const [selectedTab, setSelectedTab] = useState(0)

  const tabItems: TabItem[] = [
    {
      text: ETabsNames.ABOUT,
      onClick: () => setSelectedTab(ETabsIndex.ABOUT),
    },
    {
      text: ETabsNames.USER_ACCOUNT,
      onClick: () => setSelectedTab(ETabsIndex.USER_ACCOUNT),
    },
    {
      text: ETabsNames.CALL_CENTER,
      onClick: () => setSelectedTab(ETabsIndex.CALL_CENTER),
    },
    {
      text: ETabsNames.DETAILS,
      onClick: () => setSelectedTab(ETabsIndex.DETAILS),
    },
  ]

  return {
    selectedTab,
    tabItems,
  }
}
