import styles from './BudgettBlock.module.scss'
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg'
import { UIBannerDto } from '../../../../../api/ui/dto/banners'
import joinClassNames from '../../../../../helpers/joinClassNames'

export default function Banner({
  content,
  logoUrl,
  targetUrl,
  title,
  className,
}: UIBannerDto & { className?: string }) {
  return (
    <a
      className={joinClassNames(styles.budgettBlock, className)}
      href={targetUrl}
      target='_blank'
      rel='nofollow noreferrer'
    >
      <div className={styles.firstContainer}>
        <img className={styles.logo} src={logoUrl} alt={`${content} logo`} />
        <div className={styles.textContainer}>
          <span className={styles.title}>
            <span>{title}</span>
          </span>
          <span className={styles.text}>
            <span>{content}</span>
          </span>
        </div>
      </div>

      <div className={styles.arrowContainer}>
        <Arrow />
      </div>
    </a>
  )
}
