import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { useCallback, useEffect } from 'react'
import getPostBack from '../api/postback/postback'

export type UtmObjectType = {
  cid: string
  utm_campaign: string
  utm_medium: string
  utm_source: string
  utm_term: string
  yc: string
  sub1?: string
  sub2?: string
  sub3?: string
  sub4?: string
  sub6?: string
  sub7?: string
  sub8?: string
  sub9?: string
  sub10?: string
}

export const useGetOfferUrlWithUtm = () => {
  const { search } = useLocation()
  const searchObj = search ? (qs.parse(search) as UtmObjectType) : null

  const getOfferUrlUtm = (url: string, subcategory?: string): string => {
    const data = {}
    // todo сделать маппер для уменьшения кода
    if (searchObj) {
      if ('utm_source' in searchObj) {
        data['sub1'] = document.referrer.includes('budgett') ? 'budgett' : searchObj.utm_source
      } else if ('sub1' in searchObj) {
        data['sub1'] = document.referrer.includes('budgett') ? 'budgett' : searchObj['sub1']
      }

      if ('utm_medium' in searchObj && 'utm_campaign' in searchObj && 'utm_term' in searchObj) {
        data['sub2'] = `${searchObj.utm_medium}-${searchObj.utm_campaign}-${searchObj.utm_term}`
      } else if ('sub2' in searchObj) {
        data['sub2'] = searchObj['sub2']
      }

      if ('cid' in searchObj) {
        data['sub3'] = searchObj.cid
      } else if ('sub3' in searchObj) {
        data['sub3'] = searchObj['sub3']
      }

      if ('yc' in searchObj) {
        data['sub4'] = searchObj.yc
      } else if ('sub4' in searchObj) {
        data['sub4'] = searchObj['sub4']
      }

      if ('sub6' in searchObj) {
        data['sub6'] = searchObj['sub6']
      }

      if ('sub7' in searchObj) {
        data['sub7'] = searchObj['sub7']
      }

      if ('sub8' in searchObj) {
        data['sub8'] = searchObj['sub8']
      }

      if ('sub9' in searchObj) {
        data['sub9'] = searchObj['sub9']
      }

      if ('sub10' in searchObj) {
        data['sub10'] = searchObj['sub10']
      }
    } else {
      data['sub1'] = 'norefferal'
    }

    if (subcategory) {
      data['sub5'] = subcategory
    }

    return url.includes('?') ? `${url}&${qs.stringify(data)}` : `${url}?&${qs.stringify(data)}`
  }

  useEffect(() => {
    if (searchObj) {
      if (!sessionStorage.getItem('utm_source') && 'utm_source' in searchObj) {
        sessionStorage.setItem('utm_source', searchObj.utm_source)
      }
      if (!sessionStorage.getItem('utm_campaign') && 'utm_campaign' in searchObj) {
        sessionStorage.setItem('utm_campaign', searchObj.utm_campaign)
      }
    }
  }, [])

  const openLink = (url: string) => {
    setTimeout(() => {
      window.open(url, '_blank')
    }, 0)
  }

  const getPostBackUrl = useCallback(async ({ event, url, offerId }) => {
    event.preventDefault()
    try {
      const result = await getPostBack({ offerId })
      if (result) {
        const redirectUrl = url.includes('?')
          ? `${url}&click_id=${result}`
          : `${url}?&click_id=${result}`
        openLink(redirectUrl)
      }
    } catch (e) {
      console.error(e)
      openLink(url)
    }
  }, [])

  return { getOfferUrlUtm, getPostBackUrl }
}
