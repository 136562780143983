export function getRubles(
  amount: number,
  isViewedUnit: boolean = true,
  isRedesign: boolean = false,
): string {
  const getUnit = () => {
    if (isViewedUnit) {
      if (isRedesign) {
        return '₽'
      }
      return 'руб.'
    }

    return ''
  }
  return new Intl.NumberFormat('ru-RU').format(amount) + ' ' + getUnit()
}
