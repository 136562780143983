import { ProviderItem, ProviderTag, ProviderTemplate } from '../../../components/Offer/Offer'
import { useState } from 'react'
import { CreditSubcategory, UiCreditOfferDto } from '../../../api/ui/dto/uiCreditOfferDto'
import getTermUnit from '../../../helpers/getTermUnit'
import { getRubles } from '../../../helpers/getRubles'
import getShortTermUnit from '../../../helpers/getShortTermUnit'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'
import checkDesktop from '../../../helpers/checkDesktop'

export default function useCredits() {
  const [credits, setCredits] = useState<
    (ProviderItem & { tag: ProviderTag; template: ProviderTemplate })[]
  >([])
  const { getOfferUrlUtm } = useGetOfferUrlWithUtm()

  function getFieldName(item: UiCreditOfferDto) {
    if (item.subcategory === CreditSubcategory.MORTGAGE) {
      return 'перв. взнос'
    }

    if (item.subcategory === CreditSubcategory.CREDIT_CARD) {
      return 'без %'
    }

    return 'срок'
  }

  function getField1(maintenanceFrom, maintenanceTo, maintenanceUnit) {
    let result = ''

    if (maintenanceFrom !== null && maintenanceFrom >= 0) {
      result += `от ${getRubles(maintenanceFrom, false)} `
    }

    if (maintenanceTo !== null && maintenanceTo >= 0) {
      result += `до ${getRubles(maintenanceTo, false)}`
    }

    if (maintenanceUnit) {
      result += `руб./${getShortTermUnit(maintenanceUnit)}`
    }

    return !result.length ? null : result
  }

  function getField2(periodFrom, periodTo, periodUnit) {
    let result = ''

    if (periodFrom !== null) {
      result += `от ${periodFrom}`
    }

    if (periodTo !== null) {
      result += `до ${periodTo}`
    }

    if (periodUnit !== null) {
      result += ' ' + getTermUnit(periodUnit)
    }

    return !result.length ? null : result
  }

  function getMortgageField(downPaymentFrom: number, downPaymentTo: number) {
    let result = ''

    if (downPaymentFrom !== null) {
      result += `от ${downPaymentFrom}`
    }

    if (downPaymentTo !== null) {
      result += `до ${downPaymentTo}`
    }

    return !result.length ? null : result + '%'
  }
  function getFieldsValue(item: UiCreditOfferDto) {
    if (item.subcategory === CreditSubcategory.CREDIT_CARD) {
      return {
        field1: getField1(item.maintenanceFrom, item.maintenanceTo, item.maintenanceUnit),
        field2: getField2(item.periodFrom, item.periodTo, item.periodUnit),
      }
    }

    if (item.subcategory === CreditSubcategory.MORTGAGE) {
      if (!checkDesktop()) {
        return {
          field1: getMortgageField(item?.downPaymentFrom, item?.downPaymentTo),
          field2: item.rateFrom !== null ? 'от' + ' ' + String(item.rateFrom) + '%' : null,
        }
      }

      return {
        field1: item.rateFrom !== null ? 'от' + ' ' + String(item.rateFrom) + '%' : null,
        field2: getMortgageField(item?.downPaymentFrom, item?.downPaymentTo),
      }
    }
    return {
      field1: item.rateFrom !== null ? 'от' + ' ' + String(item.rateFrom) + '%' : null,
      field2: `${item.termFrom}-${item.termTo} ${getTermUnit(item.termUnit, item.termTo)}`,
    }
  }

  async function fetchCredits({ offerList }) {
    setCredits(
      offerList.map((item) => {
        return {
          id: String(item.id),
          tag: {
            text: item.tagText,
          },
          title: item.name,
          field3: `до ${getRubles(item.sum)}`,
          url: getOfferUrlUtm(item.targetUrl, item.subcategory),
          icon: item.logoUrl,
          advertiser: item.advertiser,
          template: {
            buttonText: 'Перейти',
            field1Name:
              item.subcategory === CreditSubcategory.CREDIT_CARD ? 'обсуживание' : 'ставка',
            field2Name: getFieldName(item),
            field3Name: 'стоимость',
            size: 's',
            backgroundColorCard: '#F1EBE9',
          },
          ...getFieldsValue(item),
        }
      }),
    )
  }

  return {
    credits,
    fetchCredits,
  }
}
