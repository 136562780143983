import styles from './SearchFormTitle.module.scss'
import Title from '../../../../components/Title/Title'
import Subtitle from '../../../../components/Subtitle/Subtitle'
import joinClassNames from '../../../../helpers/joinClassNames'
import checkDesktop from '../../../../helpers/checkDesktop'

export interface SearchFormTitleProps {
  title: string
  subtitle: string
  text: string
  className?: string
}
export default function SearchFormTitle({
  title,
  subtitle,
  text,
  className,
}: SearchFormTitleProps) {
  return checkDesktop() ? (
    <div className={joinClassNames(styles.searchFormTitle, className)}>
      <Title className={styles.title} level={1}>
        {title}
      </Title>
      <Subtitle>{subtitle}</Subtitle>
      <div className={styles.text}>{text}</div>
    </div>
  ) : (
    <>
      <div className={styles.titleContainer}>
        <Title className={styles.title}>{title}</Title>
        &#160;
      </div>
      <Subtitle className={styles.subtitle}>{subtitle}</Subtitle>
      <div className={styles.subtext}>{text}</div>
    </>
  )
}
