import styles from './Brokers.module.scss'
import useBackground from '../../../hooks/useBackground'
import useBrokers from './useBrokers'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SearchFormTitle from '../components/SearchFormTitle/SearchFormTitle'
import useBanners from '../hooks/useBanners'
import Scroller from '../../../components/Scroller/Scroller'
import BrokersSkeleton from './Brokers.skeleton'
import { BannersCategories } from '../../../api/ui/banners'
import { useBrokersMeta } from './useBrokersMeta'
import { HeadHelmet } from '../../../components/HeadHelment/HeadHelmet'
import Offer from '../../../components/Offer/Offer'
import Banner from './components/Banner/Banner'
import SeoInfoBlock from '../../SeoPage/components/SeoInfoBlock/SeoInfoBlock'
import { useShowcase } from '../hooks/useShowcase'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'

export default function Brokers() {
  useBackground('#D6D9CF')
  const [pageNum, setPageNum] = useState(0)
  const { template, brokersProviderItems, loadBrokers } = useBrokers()
  const { banners } = useBanners({
    category: BannersCategories.BROKERS,
  })
  const { fetchShowcase, offerList, isLoading, hasNextPage } = useShowcase(
    BannersCategories.BROKERS,
  )
 const { getPostBackUrl } = useGetOfferUrlWithUtm()

  const { meta } = useBrokersMeta()
  const intObserver = useRef<IntersectionObserver | null>(null)
  const lastPostRef = useCallback(
    (offer: HTMLDivElement) => {
      if (isLoading) {
        return
      }

      if (intObserver.current) {
        intObserver.current.disconnect()
      }

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          setPageNum((prev) => prev + 1)
        }
      })

      if (offer) {
        intObserver.current.observe(offer)
      }
    },
    [isLoading, hasNextPage],
  )
  useEffect(() => {
    ;(async function () {
      await fetchShowcase({ pageNum, key: BannersCategories.BROKERS.toLowerCase() })
    })()
  }, [])

  useEffect(() => {
    void loadBrokers({ offerList })
  }, [offerList])

  const content = useMemo(() => {
    const result = []
    for (let i = 0; i < brokersProviderItems.length; i++) {
      const { tag, ...item } = brokersProviderItems[i]

      if (brokersProviderItems.length === i + 1) {
        result.push(
          <Offer
            ref={lastPostRef}
            item={item}
            tag={tag}
            template={template}
            key={`offer ${item.id}`}
            isRedesignMobile
            onClick={getPostBackUrl}
          />,
        )
      } else {
        result.push(
          <Offer
            item={item}
            tag={tag}
            template={template}
            key={`offer ${item.id}`}
            onClick={getPostBackUrl}
            isRedesignMobile
          />,
        )
      }
      if (banners.length && (i + 1) % 6 === 0) {
        const trueBannerIndex = (i + 1) / 6 - 1
        const banner = banners[trueBannerIndex % banners.length]
        result.push(<Banner className={styles.banner} {...banner} key={`banner ${banner.id}`} />)
      }
    }
    return result
  }, [banners, brokersProviderItems])

  return (
    <>
      <HeadHelmet {...meta} />
      <Scroller />
      <div className={styles.loans}>
        <SearchFormTitle
          className={styles.searchFormTitle}
          title={'Прочие займы'}
          subtitle='Ставка от 0% за 5 минут!'
          text={
            'Заполняя регистрацию у партнеров из списка по нашей ссылке, Вы получаете самое выгодное предложение.'
          }
        />
        <ul className={styles.providers}>
          {isLoading && pageNum === 0 ? <BrokersSkeleton /> : content}
        </ul>
        <SeoInfoBlock code={BannersCategories.BROKERS.replace('_', '-').toLowerCase()} />
      </div>
    </>
  )
}
