import LinkButton from 'components/LinkButton/LinkButton'
import { ReactNode } from 'react'
import Button from '../../../../../../../../components/Button/Button'

export default function FooterButton({
  to,
  onClick,
  content,
}: {
  content: ReactNode
  to?: string
  onClick?: () => void
}) {
  if (to) {
    return (
      <LinkButton to={to} size={'thick'}>
        {content}
      </LinkButton>
    )
  }

  return <Button onClick={onClick}>{content}</Button>
}
