import styles from './LinkButton.module.scss'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import joinClassNames from '../../helpers/joinClassNames'

export default function LinkButton({
  children,
  className,
  uiType = 'primary',
  size,
  to,
  title = '',
  ...restProps
}: LinkProps & {
  uiType?: 'primary' | 'secondary' | 'green' | 'yellow'
  size?: 'thin' | 'thick'
  title?: string
  to: string
}) {
  return (
    <Link
      className={joinClassNames(styles.linkButton, className, styles[uiType], size && styles[size])}
      title={title}
      to={to}
      {...restProps}
    >
      {children}
    </Link>
  )
}
