import styles from './Title.module.scss'
import { ReactNode } from 'react'

export default function Title({
  children,
  className,
  level,
}: {
  children: ReactNode
  className?: string
  level?: 1 | 2 | 3 | 4 | 5 | 6
}) {
  switch (level) {
    case 1:
      return <h1 className={`${styles.title} ${className || ''}`}>{children}</h1>
    case 2:
      return <h2 className={`${styles.title} ${className || ''}`}>{children}</h2>
    case 3:
      return <h3 className={`${styles.title} ${className || ''}`}>{children}</h3>
    case 4:
      return <h4 className={`${styles.title} ${className || ''}`}>{children}</h4>
    case 5:
      return <h5 className={`${styles.title} ${className || ''}`}>{children}</h5>
    case 6:
      return <h6 className={`${styles.title} ${className || ''}`}>{children}</h6>
    default:
      return <div className={`${styles.title} ${className || ''}`}>{children}</div>
  }
}
