import { ReactNode, useEffect, useRef } from 'react'
import styles from './DropdownItem.module.scss'

export default function DropdownItem({ children, open }: { children?: ReactNode; open?: boolean }) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return
    if (open) {
      ref.current.style.height = `${ref.current.scrollHeight}px`
    } else {
      ref.current.style.height = '0'
    }
  }, [ref.current, open])

  return (
    <div ref={ref} className={styles.dropdownItem}>
      {children}
    </div>
  )
}
