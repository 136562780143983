import { UiBankruptcyOfferDto } from '../../../api/ui/dto/bankruptcy'
import { useState } from 'react'
import { getRubles } from '../../../helpers/getRubles'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'
import { ProviderItem, ProviderTag, ProviderTemplate } from '../../../components/Offer/Offer'
export default function useBankruptcy() {
  const [bankruptcyProviderItems, setBankruptcyProviderItems] = useState<
    (ProviderItem & { tag: ProviderTag })[]
  >([])
  const { getOfferUrlUtm } = useGetOfferUrlWithUtm()

  async function loadBankruptcy({
    offerList,
    subcategory,
  }: {
    subcategory: string
    offerList: UiBankruptcyOfferDto[]
  }) {
    const providerItems = offerList.map((item) => {
      return {
        id: String(item.id),
        tag: {
          text: item.tagText,
          color: '#000000',
          backgroundColor: '#DFEB81',
        },
        title: item.name,
        field1: `от ${getRubles(item.priceFrom)}`,
        field2: `до ${getRubles(item.profitTo)}`,
        field3: String(item.termString),
        url: getOfferUrlUtm(item.targetUrl, subcategory),
        icon: item.logoUrl,
      }
    })

    setBankruptcyProviderItems(providerItems)
  }

  const template: ProviderTemplate = {
    buttonText: 'Перейти',
    field1Name: 'стоимость',
    field2Name: 'выгода',
    field3Name: 'оформление банкротства',
    backgroundColorCard: '#EBE8E6',
  }

  return {
    loadBankruptcy,
    bankruptcyProviderItems,
    template,
  }
}
