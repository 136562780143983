import React from 'react'
import styles from './Button.module.scss'
import joinClassNames from '../../helpers/joinClassNames'

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  uiType?: 'primary' | 'secondary' | 'green' | 'yellow'
  size?: 'thin' | 'thick'
}
export default function Button({
  children,
  className,
  uiType = 'primary',
  size,
  ...restProps
}: ButtonProps) {
  return (
    <button
      className={joinClassNames(styles.button, className, styles[uiType], size && styles[size])}
      {...restProps}
    >
      {children}
    </button>
  )
}
