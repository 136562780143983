import { Link } from 'react-router-dom'
import styles from './BackLink.module.scss'

interface IProps {
  text: string
  url: string
}

export default function BackLink({ text, url }: IProps) {
  return (
    <Link to={url} className={styles.backLink}>
      <span className={styles.arrow}>&#8592;</span> {text}
    </Link>
  )
}
