import styles from './SeoInfoBlock.module.scss'
import SeoToggle from './components/SeoToggle/SeoToggle'
import { useCallback, useEffect, useMemo, useState } from 'react'
import SeoInfoText from './components/SeoInfoText/SeoInfoText'
import useSeoInfoBlock from './useSeoInfoBlock'

export default function SeoInfoBlock({ code }: { code: string }) {
  const [isFullText, setFullText] = useState(false)
  const { seoBlock } = useSeoInfoBlock({ code })
  const onClick = useCallback(() => {
    setFullText((state) => !state)
  }, [])

  useEffect(() => {
    if (!isFullText) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [isFullText])

  const content = useMemo(() => {
    if (seoBlock.text && seoBlock.title) {
      return (
        <>
          <span className={styles.title}>{seoBlock.title}</span>
          <span className={styles.text}>{seoBlock.text}</span>
        </>
      )
    }

    return (
      <>
        <span className={styles.title}>Что такое займы под 0 процентов</span>
        <span className={styles.text}>
          Займ под 0 процентов — это финансовая услуга, которую предлагают некоторые микрофинансовые
          организации (МФО). Основная идея такого займа заключается в том, что клиент может взять в
          долг небольшую сумму денег на короткий срок без уплаты процентов при условии соблюдения
          сроков контракта. Это предложение обычно действует для новых клиентов МФО как способ
          привлечения их внимания и как первый шаг к дальнейшему сотрудничеству.
        </span>
        <div className={`${isFullText ? styles.show : styles.hide}`}>
          <SeoInfoText />
        </div>
      </>
    )
  }, [seoBlock, isFullText])

  return (
    <section className={styles.seoInfoBlock}>
      {content}
      <div className={styles.buttonContainer}>
        <SeoToggle onClick={onClick}>{isFullText ? 'Свернуть' : 'Узнать больше'}</SeoToggle>
      </div>
    </section>
  )
}
