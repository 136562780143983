import styles from '../../Companies.module.scss'
import checkDesktop from '../../../../helpers/checkDesktop'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useCompanies } from '../../useCompanies'
import CompanyListItem from './components/CompanyListItem/CompanyListItem'
import { CompaniesMoreItemSkeleton, CompaniesSkeleton } from '../../Companies.skeleton'

export const CompanyTable = () => {
  const [pageNum, setPageNum] = useState(0)
  const { companyList, isLoading, hasNextPage } = useCompanies(pageNum)
  const intObserver = useRef<IntersectionObserver | null>(null)
  const lastPostRef = useCallback(
    (company: HTMLDivElement) => {
      if (isLoading) {
        return
      }

      if (intObserver.current) {
        intObserver.current.disconnect()
      }

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          setPageNum((prev) => prev + 1)
        }
      })

      if (company) {
        intObserver.current.observe(company)
      }
    },
    [isLoading, hasNextPage],
  )

  const content = companyList.map((company, i) => {
    if (companyList.length === i + 1) {
      return (
        <CompanyListItem ref={lastPostRef} key={company.slug + i} company={company} index={i} />
      )
    }
    return <CompanyListItem key={company.slug + i} company={company} index={i} />
  })

  const renderHead = useMemo(() => {
    if (!checkDesktop()) return null

    return (
      <div role='row' className={`${styles.row} ${styles.rowHead}`}>
        <div role='gridcell' className={`${styles.cell} ${styles.cellHead} ${styles.cellNumber}`}>
          №
        </div>
        <div role='gridcell' className={`${styles.cell} ${styles.cellName} ${styles.space}`}>
          Наименование
        </div>
        <div role='gridcell' className={`${styles.cell} ${styles.cellRating}`}>
          Рейтинг
        </div>
        <div role='gridcell' className={`${styles.cell} ${styles.cellInfo}`}>
          Отзывы
        </div>
        <div role='gridcell' className={`${styles.cell} ${styles.cellInfo}`}>
          Ответы
        </div>
        <div role='gridcell' className={`${styles.cell} ${styles.cellInfo}`}>
          Решения
        </div>
      </div>
    )
  }, [])

  return (
    <>
      <div className={styles.companyTable} role='grid'>
        {renderHead}
        {content}
        {isLoading && pageNum === 0 && <CompaniesSkeleton />}
        {isLoading && pageNum !== 0 && <CompaniesMoreItemSkeleton />}
      </div>
    </>
  )
}
