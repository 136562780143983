import checkDesktop from '../../helpers/checkDesktop'
import OfferDesktop from './desktop/OfferDesktop'
import OfferMobile from './mobile/OfferMobile'
import React, { memo } from 'react'

export interface ProviderItem {
  id: string
  field1: string
  field1Replaced?: string
  field2: string
  field3: string
  icon: string
  title: string
  url: string
  advertiser?: string
  field3Color?: string
}

export interface ProviderTemplate {
  buttonText: string
  field1Name: string
  field2Name: string
  field3Name: string
  size?: 's'
  isFlexTitleContainer?: boolean
  backgroundColorCard?: string
  width?: number | string
}

export interface ProviderTag {
  text: string
  color?: string
  backgroundColor?: string
}
export interface ProviderProps {
  item: ProviderItem
  tag: ProviderTag
  template: ProviderTemplate
  isRedesignMobile?: boolean
  onClick?: ({
    event,
    url,
  }: {
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    url: string
    offerId: string
  }) => Promise<void>
}

function OfferMapper(props: ProviderProps) {
  if (checkDesktop()) {
    return <OfferDesktop {...props} />
  }

  return props.isRedesignMobile ? <OfferMobile {...props} /> : <OfferDesktop {...props} />
}

const OfferWithRef = React.forwardRef<HTMLDivElement, ProviderProps>((props, ref) => {
  if (ref) {
    return (
      <div ref={ref}>
        <OfferMapper {...props} />
      </div>
    )
  }
  return <OfferMapper {...props} />
})

OfferWithRef.displayName = 'OfferWithRef'

const Offer = memo(OfferWithRef)

export default Offer
