import pluralize from './pluralize'
import { TermUnit } from '../api/ui/dto/common'

export default function getTermUnit(termUnit: TermUnit, qty?: number) {
  switch (termUnit) {
    case 'DAY':
      return qty ? pluralize(qty, ['дня', 'дней', 'дней']) : 'дней'
    case 'YEAR':
      return qty ? pluralize(qty, ['года', 'лет', 'лет']) : 'лет'
    case 'MONTH':
      return qty ? pluralize(qty, ['месяца', 'месяцев', 'месяцев']) : 'месяцев'
    default:
      return termUnit
  }
}
