import { TermUnitEnum } from './common'

export enum BankruptcySubcategory {
  ONLINE = 'ONLINE',
  APPLICATION = 'APPLICATION',
}

export interface UiBankruptcyOfferDto {
  id: number
  name: string
  priceFrom: number
  profitTo: number
  profitUnit: TermUnitEnum
  termString: string
  logoUrl: string
  targetUrl: string
  tagText: string
}
