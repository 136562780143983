import { createSlice, configureStore } from '@reduxjs/toolkit'

const counterSlice = createSlice({
  name: 'CardStack',
  initialState: {
    activeCard: null,
  },
  reducers: {
    setActiveCard: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.activeCard = action.payload
    },
  },
})

export const { setActiveCard } = counterSlice.actions

export const store = configureStore({
  reducer: counterSlice.reducer,
})
