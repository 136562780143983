import { request } from '../request'
import { Loan, LoansSubcategory } from './dto/loans'

export interface GetLoansParams {
  subcategory: LoansSubcategory
}

export function getLoans({ subcategory }: GetLoansParams): Promise<Loan[]> {
  return request({
    url: '/ui/v1/offers/loan',
    params: {
      subcategory,
    },
  })
}

export function getAllLoans(): Promise<Loan[]> {
  return request({
    url: '/ui/v1/offers/loan',
  })
}
