import { useEffect, useState } from 'react'
import { HeadHelmetProps } from '../../../components/HeadHelment/HeadHelmet'
import { CreditSubcategory } from '../../../api/ui/dto/uiCreditOfferDto'

export const useCreditMeta = (subcategory: CreditSubcategory) => {
  const [meta, setMeta] = useState<HeadHelmetProps>({
    title: '',
    description: '',
  })
  function getHead(subcategory: CreditSubcategory): HeadHelmetProps {
    switch (subcategory) {
      case CreditSubcategory.CONSUMER_LOAN: {
        return {
          title:
            'Оформить потребительский кредит онлайн | Взять потребкредит под низкий процент без отказа',
          description:
            'Взять выгодный потребительский кредит, сравнив и выбрав лучшее предложение под низкий процент с высокой вероятностью одобрения. Посмотрите условия кредитования, рассчитайте онлайн и оформите заявку на потребкредит на Снизим.ру',
        }
      }
      case CreditSubcategory.CREDIT_CARD: {
        return {
          title: 'Оформить онлайн заявку кредитную карту | Заказать кредитку через Интернет',
          description:
            'Выбрать и оформить кредитные карту онлайн на лучших условиях. Сравнить условия и получить кредитку с максимальным льготным периодом, кэшбеком и без отказа, оставив онлайн заявку на Снизим.ру.',
        }
      }
      case CreditSubcategory.MORTGAGE: {
        return {
          title: 'Взять ипотеку онлайн | Оформить заявку на ипотечный кредит онлайн',
          description:
            'Выгодные предложения на ипотеку от ведущих банков на Снизим.ру. Сравните предложения банков и оформите заявку на ипотеку онлайн под низкий процент.',
        }
      }
      case CreditSubcategory.CREDIT_MASTER: {
        return {
          title: '',
          description: '',
        }
      }
      default: {
        const exhaustiveCheck: never = subcategory
        throw new Error(exhaustiveCheck)
      }
    }
  }

  useEffect(() => {
    setMeta(getHead(subcategory))
  }, [subcategory])

  return {
    meta,
  }
}
