import { request } from '../request'
import { IBroker } from './brokers'
import { Loan } from './dto/loans'
import { UiCreditOfferDto } from './dto/uiCreditOfferDto'
import { UiBankruptcyOfferDto } from './dto/bankruptcy'

export interface ShowcaseParams {
  page: number
  size: number
  key: string
}

export interface ShowcaseResponse {
  content: {
    key: string
    category: string
    name: string
    description: string
    offers: IBroker[] | Loan[] | UiCreditOfferDto[] | UiBankruptcyOfferDto[]
  }
  totalPages: 0
  totalElements: 0
  last: true
}
export function getShowcaseKeys({ category }): Promise<string[]> {
  return request({
    url: '/snizim-offer/v2/showcases/keys',
    params: {
      category,
    },
  })
}

export function getShowcase({ page, size, key }): Promise<ShowcaseResponse> {
  return request({
    url: `/snizim-offer/v2/showcases/${key}`,
    params: {
      page,
      size,
    },
  })
}
