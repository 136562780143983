import styles from './NavMenu.module.scss'
import { Link } from 'react-router-dom'
import useProductsList from '../../../../hooks/useProductsList'
import ProductItem, { AdvantageItemProps } from '../../../ProductItem/ProductItem'
import React, { ForwardedRef, forwardRef, useState } from 'react'
import checkDesktop from '../../../../helpers/checkDesktop'
import DropdownItem from './components/DropdownItem/DropdownItem'
import PlusMinusToggle from '../../../PlusMinusToggle/PlusMinusToggle'
import LinkButton from '../../../LinkButton/LinkButton'

export interface NavMenuProps {
  onMouseLeave?: () => void
  onCloseActionFire?: () => void
  onSizeChange?: () => void
}

interface AdvantageActiveFooterButtonMenuItemsProps {
  content: string
  to: string
}

const NavMenu = forwardRef(function (
  { onMouseLeave, onCloseActionFire, onSizeChange }: NavMenuProps,
  ref: ForwardedRef<HTMLElement | null>,
) {
  const [activeMenuItems, setActiveMenuItems] = useState<AdvantageItemProps[] | null>(null)
  const [activeFooterButtonMenuItems, setActiveFooterButtonMenuItems] =
    useState<AdvantageActiveFooterButtonMenuItemsProps | null>(
      {} as AdvantageActiveFooterButtonMenuItemsProps,
    )
  const [activeItem, setActiveItem] = useState('')
  const { cards } = useProductsList()

  const mainMenuItems = cards.map((item) => {
    return (
      <li key={item.title.text}>
        <Link
          className={styles.mainMenuLink}
          to={item?.link ?? '/'}
          onMouseEnter={() => onMouseEnter(item.title.text)}
          onClick={onCloseActionFire}
        >
          {item.title.text}
        </Link>
      </li>
    )
  })

  function onMouseEnter(titleText: string) {
    const card = cards.find((item) => item.title.text === titleText)
    if (card) {
      setActiveMenuItems(card.items)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setActiveFooterButtonMenuItems(card.footerButton)
    }
  }

  return checkDesktop() ? (
    <nav className={styles.navMenuContainer} onMouseLeave={onMouseLeave}>
      <div className={styles.navMenu}>
        <ul className={styles.mainMenu}>{mainMenuItems}</ul>
        <ul className={styles.submenu}>
          {activeMenuItems &&
            activeMenuItems.map((item) => (
              <ProductItem
                {...item}
                icon={{
                  color: '#666666',
                  outline: '#F0F0F0',
                  IconJsx: item.icon.IconJsx,
                }}
                content={{ text: item.content.text, color: '#000' }}
                size='s'
                key={item.content.text}
                onLinkClick={onCloseActionFire}
              />
            ))}
          {activeFooterButtonMenuItems?.content && (
            <li className={`${styles.productItem} ${styles.small}`}>
              <LinkButton className={styles.link} to={activeFooterButtonMenuItems.to}>
                <span>{activeFooterButtonMenuItems.content}</span>
              </LinkButton>
            </li>
          )}
        </ul>
      </div>
    </nav>
  ) : (
    <nav
      className={styles.navMenuContainer}
      onClick={(e) => {
        e.stopPropagation()
      }}
      ref={ref}
    >
      <div className={styles.navMenu}>
        <ul className={styles.mainMenu}>
          {cards.map((item) => (
            <li
              className={activeItem === item.title.text ? styles.active : ''}
              key={item.title.text}
            >
              <button
                className={styles.mobileMenuItem}
                onClick={() => {
                  if (
                    item.title.text === 'Рефинансирование' ||
                    item.title.text === 'Кредитная история'
                  ) {
                    window.open('https://budgett.ru/sign_up/', '_blanc')
                  } else {
                    setActiveItem(activeItem !== item.title.text ? item.title.text : '')
                    if (onSizeChange) onSizeChange()
                  }
                }}
              >
                <span className={styles.title}>{item.title.text}</span>
                <PlusMinusToggle open={activeItem === item.title.text} />
              </button>
              <DropdownItem open={activeItem === item.title.text}>
                <ul className={styles.productItemList}>
                  {item.items.map((productItem) => (
                    <ProductItem
                      {...productItem}
                      icon={{
                        color: '#666666',
                        outline: '#F0F0F0',
                        IconJsx: productItem.icon.IconJsx,
                      }}
                      content={{ text: productItem.content.text, color: '#000' }}
                      size='s'
                      key={productItem.content.text}
                      onLinkClick={onCloseActionFire}
                    />
                  ))}
                </ul>
              </DropdownItem>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.dragger} />
    </nav>
  )
})

NavMenu.displayName = 'NavMenu'

export default NavMenu
