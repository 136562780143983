import React, { memo } from 'react'
import styles from '../../../../Companies.module.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { CompanyItemImageSkeleton } from '../../../../Companies.skeleton'

const CompanyImage = memo(({ logoUrl, name }: { logoUrl: string; name: string }) => {
  if (!logoUrl) {
    return <CompanyItemImageSkeleton />
  }

  return (
    <LazyLoadImage
      className={styles.logo}
      height={36}
      src={logoUrl}
      alt={name}
      width={36}
      effect='blur'
    />
  )
})

CompanyImage.displayName = 'CompanyImage'

export default CompanyImage
