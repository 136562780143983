import { HeadHelmet } from '../../../components/HeadHelment/HeadHelmet'
import styles from '../Promo.module.scss'
import BackLink from '../../../components/BackLink/BackLink'
import Scroller from '../../../components/Scroller/Scroller'

export default function PrivacyPolicy() {
  return (
    <>
      <HeadHelmet title='Политика конфиденциальности' />
      <Scroller isSmooth />
      <main className={styles.promoContent}>
        <BackLink text='На главную страницу' url='/' />
        <section className={styles.info}>
          <h1 className={styles.title}>Политика конфиденциальности</h1>
          <p className={styles.text}>
            Регистрируясь на сайте <strong>snizim.ru</strong>, оформляя заказ на услуги Снизим.ру по
            подбору страховых, банковских, и продуктов микрофинансовых организаций, а также
            образовательных продуктов, или предоставляя нам персональные данные, Вы, тем самым,
            действуя по своей воле и в своем интересе, выражаете свое согласие на обработку Ваших
            персональных данных на условиях, указанных ниже.
          </p>
          <section>
            <h4 className={styles.subtitle}>Обработку ваших данных осуществляют:</h4>
            <section className={styles.block}>
              <ul className={styles.list}>
                <li className={styles.text}>
                  Общество с ограниченной ответственностью «Снизим.ру», зарегистрированное по адресу
                  125363, г. Москва, вн.тер.г. Муниципальный округ Южное Тушино, ул. Сходненская,
                  дом 25, ОГРН 1247700212528, ИНН 7733440950, которое далее по тексту будет для
                  краткости именоваться “Общество” или “Оператор”;
                </li>
                <li className={styles.text}>
                  лица, непосредственно оказывающие Вам услуги, в том числе услуги страхового и
                  банковского обслуживания на основании надлежащей лицензии, услуги в сфере
                  образования, микрофинансовые организации, которые далее по тексту будут для
                  краткости именоваться “Партнеры”;
                </li>
                <li className={styles.text}>
                  операторы связи, организаторы распространения информации в сети интернет, иные
                  лица, обеспечивающие обмен информацией между Вами и Обществом и/или Вами и
                  Партнером, которые далее по тексту будут для краткости именоваться “Операторы
                  связи”.
                </li>
              </ul>
            </section>
          </section>
          <section>
            <h4 className={styles.subtitle}>Цели обработки персональных данных</h4>
            <ol>
              <li className={styles.text}>
                1.1. Ваши персональные данные обрабатываются исключительно в целях подбора для Вас
                страховых, банковских, образовательных продуктов и продуктов микрофинансовых
                организаций (далее – “Услуги”).{' '}
              </li>
              <li className={styles.text}>
                1.2. Достижение вышеуказанных целей предполагает:
                <section className={styles.block}>
                  <ul className={styles.list}>
                    <li className={styles.text}>
                      передачу данных Партнерам в целях оформления и рассмотрения Ваших обращений на
                      получение Услуг;
                    </li>
                    <li className={styles.text}>
                      проверку предоставленных Вами данных для оценки рисков Партнеров в целях
                      принятия решения о предоставлении Вам Услуг;
                    </li>
                    <li className={styles.text}>
                      заключение и последующее исполнение гражданско-правовых договоров, заключенных
                      Вами с Партнерами; использование персональных данных в личном кабинете на
                      сайте Оператора https://www.snizim.ru, в мобильном приложении в целях Вашей
                      аутентификации, оказания технической поддержки при использовании ресурсов
                      snizim.ru и сохранении истории ваших покупок;
                    </li>
                    <li className={styles.text}>
                      информирование о товарах и услугах, оказываемых Оператором и его Партнерами, в
                      том числе о возможности применения программ лояльности, скидок и иных льготных
                      условий, в том числе через Операторов связи;
                    </li>
                    <li className={styles.text}>
                      получение и последующая обработка Оператором и/или Партнерами кредитного
                      отчета, сформированного на основании кредитной истории с целью проверки
                      благонадежности, а также иной информации в рамках Федерального закона от
                      30.12.2004 № 218.-ФЗ «О кредитных историях».
                    </li>
                  </ul>
                </section>
              </li>
            </ol>
          </section>
          <section>
            <h4 className={styles.subtitle}>Перечень обрабатываемых персональных данных</h4>
            <ol>
              <li className={styles.text}>
                2.1. Для целей настоящего Согласия под персональными данными понимается любая
                информация, прямо или косвенно относящаяся к Вам, указанная Вами лично, в том числе
                на сайте https://www.snizim.ru в сети Интернет, в мобильном приложении, в личном
                кабинете, полученная в ходе рассмотрения заявки Оператором и/или Партнером или
                полученная в общедоступных источниках, в частности:
                <section>
                  <p className={styles.text}>Предоставление кредитных продуктов:</p>
                  <section className={styles.block}>
                    <ul className={styles.list}>
                      <li className={styles.text}>
                        идентифицирующие данные: фамилия, имя, отчество, адрес, номер и серия
                        основного документа, удостоверяющего личность, сведения о дате выдачи
                        указанного документа и выдавшем его органе; адрес фактического проживания и
                        регистрации, адрес и дата места рождения, скан- копия указанного документа,
                        ИНН, СНИЛС, должность, сведения о работодателе;
                      </li>
                      <li className={styles.text}>
                        данные личного кабинета: email/login, номер мобильного телефона, загруженные
                        пользователем изображения (“аватар”);
                      </li>
                      <li className={styles.text}>
                        данные о семейном положении: свидетельство о браке, данные о детях;
                      </li>
                      <li className={styles.text}>
                        данные о собственности: автомобили и недвижимость, указанные для
                        подтверждения платежеспособности, залога;
                      </li>
                      <li className={styles.text}>
                        данные, содержащиеся в скан-копиях документов: данные заграничного паспорта,
                        водительского удостоверения, справки с места работы, данные регистрации;
                      </li>
                      <li className={styles.text}>
                        данные о банковских продуктах пользователя: вклады, кредиты, данные
                        банковской карты и т.п. сведения из бюро кредитных историй: сведения,
                        получаемые по Вашему поручению из бюро кредитных историй, содержащие
                        информацию, предусмотренную законодательством о кредитных историях, об
                        основной, информационной, титульной частях кредитной истории и об оценке
                        Вашей платежеспособности;
                      </li>
                    </ul>
                  </section>
                  <section>
                    <p className={styles.text}>Предоставление страховых продуктов:</p>
                    <section className={styles.block}>
                      <ul className={styles.list}>
                        <li className={styles.text}>
                          идентифицирующие данные: фамилия, имя, отчество, адрес, номер и серия
                          основного документа, удостоверяющего личность, сведения о дате выдачи
                          указанного документа и выдавшем его органе; адрес фактического проживания,
                          скан- копия указанного документа, ИНН, должность, сведения о работодателе;
                        </li>
                        <li className={styles.text}>
                          данные о страховых продуктах пользователя (КАСКО, ОСАГО);
                        </li>
                        <li className={styles.text}>
                          данные о транспортном средстве: марка, модель автомобиля; VIN номер
                          автомобиля; гос. номер автомобиля; дата выпуска (продажи) автомобиля;
                          данные СТС, данные ПТС и данные договора купли-продажи автомобиля; данные
                          об установке противоугонной системы;
                        </li>
                        <li className={styles.text}>
                          данные, содержащиеся в скан-копиях документов: данные заграничного
                          паспорта, водительского удостоверения, справки с места работы, данные
                          регистрации;
                        </li>
                      </ul>
                    </section>
                  </section>
                  <section>
                    <p className={styles.text}>
                      Предоставление продуктов микрофинансовых организаций:
                    </p>
                    <section className={styles.block}>
                      <ul className={styles.list}>
                        <li className={styles.text}>
                          идентифицирующие данные: фамилия, имя, отчество, адрес, номер и серия
                          основного документа, удостоверяющего личность, сведения о дате выдачи
                          указанного документа и выдавшем его органе; адрес фактического проживания,
                          скан- копия указанного документа, ИНН, должность, сведения о работодателе;
                        </li>
                        <li className={styles.text}>
                          данные личного кабинета: email/login, номер мобильного телефона,
                          загруженные пользователем изображения (“аватар”);
                        </li>
                        <li className={styles.text}>
                          данные о семейном положении: свидетельство о браке, данные о детях;
                        </li>
                        <li className={styles.text}>
                          данные, содержащиеся в скан-копиях документов: данные заграничного
                          паспорта, водительского удостоверения, справки с места работы, данные
                          регистрации;
                        </li>
                        <li className={styles.text}>
                          данные о банковских продуктах пользователя: вклады, кредиты, данные
                          банковской карты и т.п
                        </li>
                        <li className={styles.text}>
                          сведения из бюро кредитных историй: сведения, получаемые по Вашему
                          поручению из бюро кредитных историй, содержащие информацию,
                          предусмотренную законодательством о кредитных историях, об основной,
                          информационной, титульной частях кредитной истории и об оценке Вашей
                          платежеспособности.
                        </li>
                      </ul>
                    </section>
                  </section>
                </section>
                <section>
                  <p className={styles.text}>Предоставление образовательных продуктов:</p>
                  <section className={styles.block}>
                    <ul className={styles.list}>
                      <li className={styles.text}>
                        идентифицирующие данные: фамилия, имя, отчество;
                      </li>
                      <li className={styles.text}>
                        контактные данные: e-mail, номер мобильного телефона.
                      </li>
                    </ul>
                  </section>
                </section>
              </li>
            </ol>
          </section>
          <section>
            <h4 className={styles.subtitle}>
              Перечень действий с персональными данными, на совершение которых дается согласие
            </h4>
            <ol>
              <li className={styles.text}>
                3.1. Настоящее Согласие предоставляется на осуществление любых законных действий в
                отношении персональных данных, указанных в ч.3.ст.3 Федерального Закона “О защите
                персональных данных” и необходимых для достижения указанных выше целей, в том числе:
                сбор, систематизация, накопление, хранение, уточнение (обновление, изменение),
                использование, предоставление (в том числе передача и доступ третьим лицам), в том
                числе повторное при запросе пользователем аналогичной услуги, обезличивание,
                блокирование, удаление, уничтожение персональных данных, обработка персональных
                данных для целей статистического учета и научного анализа, трансграничной передачи,
                в страны, обеспечивающие адекватную защиту, а также осуществление любых иных
                действий с персональными данными в рамках действующего законодательства Российской
                Федерации.
              </li>
            </ol>
          </section>
          <section>
            <h4 className={styles.subtitle}>
              Перечень действий с персональными данными, на совершение которых дается согласие
            </h4>
            <ol>
              <li className={styles.text}>
                4.1. Настоящее Согласие, в том числе дается на передачу персональных данных третьим
                лицам в объеме, необходимом для рассмотрения вашей заявки по выбранному продукту в
                целях оформления, рассмотрения Ваших обращений, заключения и последующего исполнения
                договоров с Партнерами или получения информации о продуктах последних.{' '}
              </li>
              <li className={styles.text}>
                4.2. Ознакомиться с перечнем Партнеров вы можете по ссылке{' '}
                <a href='/' className={styles.link}>
                  (вставить ссылку зеленым цветом)
                </a>
              </li>
              <li className={styles.text}>
                4.3. Дополнительно к условиям о согласии на передачу персональных данных третьим
                лицам Вы также предоставляете конкретное, информированное и осознанное согласие на
                обработку персональных данных следующими Партерами Оператора, указанными далее в
                настоящем пункте, в порядке и на условиях, содержащихся по следующей ссылке{' '}
                <a href='/' className={styles.link}>
                  (вставить ссылку зеленым цветом)
                </a>
              </li>
            </ol>
          </section>
          <section>
            <h4 className={styles.subtitle}>Получение рекламной информации</h4>
            <ol>
              <li className={styles.text}>
                5.1. В целях оказания услуг Вам будет направляться рекламная и иная информация о
                продуктах Партнеров, Оператора и Операторов связи.
              </li>
              <li className={styles.text}>
                5.2. Вы можете в любой момент отказаться от получения рекламы путем направления
                электронного письма по адресу{' '}
                <a
                  href='mailto:support@snizim.ru'
                  className={styles.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  support@snizim.ru
                </a>
              </li>
            </ol>
          </section>
          <section>
            <h4 className={styles.subtitle}>Ваши основные права</h4>
            <ol>
              <li className={styles.text}>
                6.1. Информируем, что в соответствии с действующим законодательством Вы имеете
                следующие права:
                <section className={styles.block}>
                  <ul className={styles.list}>
                    <li className={styles.text}>
                      на отзыв согласия в установленном законодательством порядке,
                    </li>
                    <li className={styles.text}>
                      право на получение от Оператора сведений, касающихся обработки Ваших
                      персональных данных,
                    </li>
                    <li className={styles.text}>
                      право требовать уточнения Ваших персональных данных, их блокирования или
                      уничтожения в случае, если персональные данные являются неполными,
                      устаревшими, неточными, незаконно полученными или не являются необходимыми для
                      заявленной цели обработки, а также
                    </li>
                    <li className={styles.text}>
                      право принимать предусмотренные законом меры по защите своих прав, в том числе
                      обжаловать действия или бездействие оператора в уполномоченный орган по защите
                      прав субъектов персональных данных или в судебном порядке и защиту своих прав
                      и законных интересов, в том числе на возмещение убытков и (или) компенсацию
                      морального вреда в судебном порядке.
                    </li>
                    <li className={styles.text}>
                      В случае отзыва Вами согласия на обработку персональных данных, Оператор
                      вправе продолжить обработку персональных данных без Вашего согласия только при
                      наличии оснований, установленных законодательством о персональных данных.
                    </li>
                  </ul>
                </section>
              </li>
            </ol>
          </section>
          <section>
            <h4 className={styles.subtitle}>Отзыв согласия</h4>
            <ol>
              <li className={styles.text}>
                7.1. Отзыв настоящего согласия осуществляется путем предоставления Оператору такого
                заявления через личный кабинет на сайте snizim.ru. В случае отсутствия иных законных
                оснований на обработку персональных данных, Оператор прекращает обработку
                персональных данных и уничтожает или обезличивает персональные данные в течение
                одного рабочего дня или с момента окончания срока обработки персональных данных.
              </li>
            </ol>
          </section>
          <section>
            <h4 className={styles.subtitle}>Сроки обработки персональных данных</h4>
            <ol>
              <li className={styles.text}>
                8.1. Срок обработки персональных данных устанавливается с момента предоставления
                согласия и действует в течение срока рассмотрения Ваших заявок, действий договоров,
                заключенных по результатам рассмотрения заявок с Партнерами. По истечении указанного
                срока действие согласия считается продленным на десять лет в целях осуществления
                маркетинговых кампаний, ведения статистики и проведения аналитических исследований.
                Логи действий пользователя на сайте (подтверждение факта подписания согласия)
                хранятся без ограничения срока.
              </li>
            </ol>
          </section>
          <section>
            <h4 className={styles.subtitle}>
              Обратная связь по вопросам обработки персональных данных
            </h4>
            <ol>
              <li className={styles.text}>
                9.1. Если у Вас есть дополнительные вопросы или предложения относительно данного
                согласия – свяжитесь с нами в любое время через форму обратной связи или, написав по
                следующему адресу:{' '}
                <a
                  href='mailto:support@snizim.ru'
                  className={styles.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  support@snizim.ru
                </a>
              </li>
              <li className={styles.text}>
                9.2. Контакты для обращения по вопросам обработки персональных данных: 125363, г.
                Москва, вн.тер.г. Муниципальный округ Южное Тушино, ул. Сходненская, дом 25.
                Телефон:{' '}
                <a href='tel:support@snizim.ru' className={styles.link}>
                  8 (800) 555-99-80.
                </a>
              </li>
            </ol>
          </section>
        </section>
      </main>
    </>
  )
}
