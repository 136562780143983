import styles from './Bankruptcy.module.scss'
import useBankruptcy from './useBankruptcy'
import useBackground from '../../../hooks/useBackground'
import SearchFormTitle from '../components/SearchFormTitle/SearchFormTitle'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BankruptcySubcategory } from '../../../api/ui/dto/bankruptcy'
import Scroller from '../../../components/Scroller/Scroller'
import BankruptcySkeleton from './Bankruptcy.skeleton'
import useBanners from '../hooks/useBanners'
import { BannersCategories } from '../../../api/ui/banners'
import checkDesktop from '../../../helpers/checkDesktop'
import Banner from '../Brokers/components/Banner/Banner'
import { HeadHelmet } from '../../../components/HeadHelment/HeadHelmet'
import SeoInfoBlock from '../../SeoPage/components/SeoInfoBlock/SeoInfoBlock'
import { useShowcase } from '../hooks/useShowcase'
import Offer from '../../../components/Offer/Offer'
import { useGetOfferUrlWithUtm } from '../../../hooks/useGetOfferUrlWithUtm'

export default function Bankruptcy() {
  const [pageNum, setPageNum] = useState(0)
  const { loadBankruptcy, bankruptcyProviderItems, template } = useBankruptcy()
  const { banners } = useBanners({
    category: BannersCategories.BANKRUPTCY,
    subcategory: BankruptcySubcategory.ONLINE,
  })
  const { getPostBackUrl } = useGetOfferUrlWithUtm()
  const { fetchShowcase, offerList, seoBlock, isLoading, hasNextPage } = useShowcase(
    BannersCategories.BANKRUPTCY,
  )
  const intObserver = useRef<IntersectionObserver | null>(null)
  const lastPostRef = useCallback(
    (offer: HTMLDivElement) => {
      if (isLoading) {
        return
      }

      if (intObserver.current) {
        intObserver.current.disconnect()
      }

      intObserver.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && hasNextPage) {
          setPageNum((prev) => prev + 1)
        }
      })

      if (offer) {
        intObserver.current.observe(offer)
      }
    },
    [isLoading, hasNextPage],
  )
  useBackground('#D8D5D3')
  useEffect(() => {
    ;(async function () {
      await fetchShowcase({ pageNum, key: BankruptcySubcategory.ONLINE.toLowerCase() })
    })()
  }, [])

  const content = useMemo(() => {
    const result = []
    for (let i = 0; i < bankruptcyProviderItems.length; i++) {
      if (!checkDesktop() && banners.length && i % 2 === 0) {
        const trueBannerIndex = i / 2
        const banner = banners[trueBannerIndex % banners.length]
        result.push(<Banner className={styles.banner} {...banner} key={`banner ${banner.id}`} />)
      }
      const item = bankruptcyProviderItems[i]

      if (bankruptcyProviderItems.length === i + 1) {
        result.push(
          <Offer
            ref={lastPostRef}
            tag={{ text: 'списание долгов' }}
            template={template}
            item={item}
            key={item.id}
            onClick={getPostBackUrl}
          />,
        )
      } else {
        result.push(
          <Offer tag={{ text: 'списание долгов' }} template={template} item={item} onClick={getPostBackUrl} key={item.id} />,
        )
      }

      if (checkDesktop() && banners.length && (i + 1) % 3 === 0) {
        const trueBannerIndex = (i + 1) / 3 - 1
        const banner = banners[trueBannerIndex % banners.length]
        result.push(<Banner className={styles.banner} {...banner} key={`banner ${banner.id}`} />)
      }
    }
    return result
  }, [banners, bankruptcyProviderItems])

  useEffect(() => {
    void loadBankruptcy({ offerList, subcategory: BankruptcySubcategory.ONLINE })
  }, [offerList])
  return (
    <>
      <HeadHelmet
        title='Банкротство физических лиц | Услуги по списанию долгов под ключ, цены'
        description='Рейтинг компаний по банкротству физических лиц.
        Услуги по списанию долгов онлайн под ключ по низким ценам.
        Выберите компанию и оставьте онлайн-заявку.'
      />
      <Scroller />
      <div className={styles.bankruptcy}>
        <SearchFormTitle
          className={styles.title}
          title={seoBlock || 'Банкротство физических лиц'}
          subtitle={'Спишите долги по онлайн-заявке.'}
          text={
            'Расчет условий и все инструменты для оформления банкротства в одном разделе. Делаем сложное - простым.'
          }
        />
        <ul>{isLoading && pageNum === 0 ? <BankruptcySkeleton /> : content}</ul>
        <SeoInfoBlock code={BankruptcySubcategory.ONLINE.replace('_', '-').toLowerCase()} />
      </div>
    </>
  )
}
