import React, { FunctionComponent, SVGProps, useEffect, useRef } from 'react'
import styles from './ProductItem.module.scss'
import { Link, useLocation } from 'react-router-dom'

export interface AdvantageItemProps {
  icon: {
    IconJsx: FunctionComponent<SVGProps<SVGSVGElement>>
    color: string
    outline?: string
  }
  content: {
    text: string
    color: string
    hoverColor?: string
  }
  size?: 's'
  link?: string
  isExternalLink?: boolean
}
export default function ProductItem({
  icon,
  content,
  size,
  link,
  onLinkClick,
  isExternalLink,
}: AdvantageItemProps & { onLinkClick?: () => void }) {
  const ref = useRef<HTMLAnchorElement>(null)
  const iconContainerColor = icon.outline || 'transparent'
  const { search } = useLocation()

  useEffect(() => {
    if (ref.current && content.hoverColor) {
      ref.current.style.setProperty('--hover-color', content.hoverColor)
      ref.current.style.setProperty('--color', content.color)
    }
  }, [])

  return link ? (
    <li className={`${styles.productItem} ${size === 's' ? styles.small : ''}`}>
      <Link
        ref={ref}
        className={styles.link}
        to={link + search}
        onClick={onLinkClick}
        target={isExternalLink ? '_blank' : ''}
        rel={isExternalLink ? 'nofollow' : ''}
      >
        <div className={styles.iconContainer} style={{ backgroundColor: iconContainerColor }}>
          <icon.IconJsx className={styles.icon} />
        </div>{' '}
        <span>{content.text}</span>
      </Link>
    </li>
  ) : (
    <li
      className={`${styles.productItem} ${size === 's' ? styles.small : ''}`}
      onClick={onLinkClick}
    >
      <div className={styles.iconContainer} style={{ backgroundColor: iconContainerColor }}>
        <icon.IconJsx className={styles.icon} style={{ color: icon.color }} />
      </div>{' '}
      <span style={{ color: content.color }}>{content.text}</span>
    </li>
  )
}
