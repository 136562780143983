import styles from '../../SeoInfoBlock.module.scss'

export default function SeoInfoText() {
  return (
    <section className={styles.fullTextContainer}>
      <span className={styles.text}>Важные моменты, на которые стоит обратить внимание:</span>
      <div className={styles.block}>
        <ul className={styles.list}>
          <li className={styles.text}>
            Своевременное погашение: Беспроцентный займ остается таковым только при условии, что
            долг будет погашен вовремя. В случае просрочки начисляются проценты и неустойка.
          </li>
          <li className={styles.text}>
            Дополнительные условия: Некоторые МФО могут требовать подтверждение дохода или наличие
            хорошей кредитной истории.
          </li>
          <li className={styles.text}>
            Ограничения: займы под 0 процентов на карту без отказа часто имеют ограничения по сумме
            и сроку, а также могут быть доступны только определенным категориям заемщиков.
          </li>

          <li className={styles.text}>
            Прежде чем воспользоваться таким предложением, советуется тщательно ознакомиться со
            всеми условиями и требованиями микрофинансовой организации (МФО), а также удостовериться
            в своей возможности своевременно погасить заем, чтобы избежать лишних расходов и
            штрафов. Если есть сомнения или вопросы, лучше проконсультироваться с финансовым
            консультантом.
          </li>
        </ul>
      </div>
      <div className={styles.flexInfoBlock}>
        <span className={styles.title}>
          На какую сумму можно получить первый займ под 0% на карту
        </span>
        <span className={styles.text}>
          Сумма, на которую можно взять первый займ под 0 процентов на карту, может значительно
          варьироваться в зависимости от микрокредитной организации (МФО), к которой обратились.
          Некоторые МФО предлагают небольшие суммы, начиная от 5 000 рублей, в то время как другие
          могут предложить до 15 000 или даже 20 000 рублей. Это может быть частью промо-акции или
          специального предложения для привлечения новых клиентов.
        </span>
        <span className={styles.text}>
          Важно помнить, что такие займы обычно имеют определенные ограничения по срокам. Например,
          беспроцентный период может длиться от 10 до 30 дней, после чего заемщик должен вернуть
          сумму долга без начисления процентов. Если заем не будет погашен в указанный срок, то
          обычно применяется стандартная процентная ставка, которая может быть достаточно высокой.
        </span>
        <span className={styles.text}>
          Кроме того, сумма беспроцентного займа может зависеть от кредитной истории заемщика, его
          доходов и других факторов, оцененных МФО при рассмотрении заявки. В некоторых случаях,
          если заемщик имеет хорошую кредитную историю и стабильный доход, МФО могут предложить
          более высокую сумму под 0 процентов.
        </span>
        <span className={styles.text}>
          В любом случае, перед оформлением займа под 0 процентов на карту, рекомендуется тщательно
          изучить условия и политику МФО, чтобы понимать, какую сумму вы можете получить, на какой
          срок и какие возможны дополнительные расходы или ограничения.
        </span>
      </div>
      <div className={styles.flexInfoBlock}>
        <span className={styles.title}>
          Можно ли взять займ под 0 процентов с плохой кредитной историей
        </span>
        <span className={styles.text}>
          Существует мнение, что с плохой кредитной историей получить займ под 0 процентов на карту
          может быть сложнее, поскольку МФО обычно рассматривают кредитную историю заемщика как один
          из ключевых факторов при принятии решения о выдаче займа. Однако, некоторые МФО
          специализируются на обслуживании клиентов с неполной или негативной кредитной историей и
          могут предложить беспроцентные займы, но с более строгими требованиями или меньшими
          суммами.
        </span>
        <span className={styles.text}>
          В таких случаях, МФО могут запросить дополнительную информацию о заемщике, таких как
          подтверждение дохода, справки о работе или другие документы, чтобы оценить его
          платежеспособность. Они также могут установить более короткий беспроцентный период или
          более высокие комиссии за обслуживание.
        </span>
        <span className={styles.text}>
          Важно помнить, что каждый случай индивидуален, и МФО могут применять разные подходы к
          заемщикам с плохой кредитной историей. Поэтому, если у вас есть такая история,
          рекомендуется связаться с несколькими МФО, чтобы узнать, какие условия они могут
          предложить, и сравнить их между собой.
        </span>
      </div>
      <div className={styles.flexInfoBlock}>
        <span className={styles.title}>Почему могут отказать в выдаче займа без процентов</span>
        <span className={styles.text}>
          Отказ в выдаче займа без процентов может быть обусловлен несколькими причинами:
          Недостоверная информация от заемщика; Наличие долгов по текущим кредитам; Чересчур большое
          количество ранее взятых и не погашенных кредитов; Наличие недавних просрочек по кредитным
          платежам.
        </span>
        <div className={styles.block}>
          <ul className={styles.list}>
            <li className={styles.text}>Недостоверная информация от заемщика;</li>
            <li className={styles.text}>Наличие долгов по текущим кредитам;</li>
            <li className={styles.text}>
              Чересчур большое количество ранее взятых и не погашенных кредитов;
            </li>
            <li className={styles.text}>Наличие недавних просрочек по кредитным платежам.</li>
          </ul>
        </div>
      </div>
      <div className={styles.flexInfoBlock}>
        <span className={styles.title}>Требования к заемщику</span>
        <span className={styles.text}>
          Требования к заемщику для оформления микрозайма под 0% на карту быстро обычно включают:
        </span>
        <div className={styles.block}>
          <ul className={styles.list}>
            <li className={styles.text}> Возраст от 18 до 75 лет;</li>
            <li className={styles.text}> Наличие действующего паспорта гражданина РФ;</li>
            <li className={styles.text}>
              Постоянная регистрация на территории Российской Федерации;
            </li>
            <li className={styles.text}>
              Наличие мобильного телефона и доступа к интернету для подачи заявления.
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.flexInfoBlock}>
        <span className={styles.title}>Есть ли подвох в микрозаймах под 0 процентов</span>
        <span className={styles.text}>
          Если говорить о подвохе, то при соблюдении всех условий договора, заем под 0% может быть
          действительно без подводных камней. Однако, заемщику стоит быть внимательным к следующим
          аспектам:
        </span>
        <div className={styles.block}>
          <ul className={styles.list}>
            <li className={styles.text}>
              Короткий срок займа. Обычно микрозаймы под 0 процентов выдаются на срок от 7 до 30
              дней. Этого времени может быть недостаточно, чтобы вернуть деньги без просрочки.
            </li>
            <li className={styles.text}>
              Штрафы за просрочку. Если вы не вернете деньги в срок, вам придется заплатить штраф.
              Размер штрафа может быть довольно высоким.
            </li>
            <li className={styles.text}>
              Дополнительные комиссии. Некоторые МФО могут взимать дополнительные комиссии,
              например, за оформление займа или за перевод денег на карту.
            </li>
            <li className={styles.text}>
              Скрытые условия. Внимательно читайте договор займа перед его подписанием. В нем могут
              быть скрытые условия, о которых вам не скажут при оформлении займа.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.flexInfoBlock}>
        <span className={styles.text}>
          В целом, микрозаймы под 0 процентов могут быть хорошим вариантом, если вам нужны деньги
          срочно и вы уверены, что сможете вернуть их в срок. Однако важно внимательно изучить все
          условия займа, чтобы избежать неприятных сюрпризов.
        </span>
      </div>
    </section>
  )
}
