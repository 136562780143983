import { useEffect, useState } from 'react'
import { getLoans } from '../../../../api/ui/loans'
import { Loan, LoansSubcategory } from '../../../../api/ui/dto/loans'
import getTermUnit from '../../../../helpers/getTermUnit'
import { ProviderProps } from '../../../../components/Offer/Offer'
import { getRubles } from '../../../../helpers/getRubles'
import { useGetOfferUrlWithUtm } from '../../../../hooks/useGetOfferUrlWithUtm'

export default function useCompaniesBestOffers() {
  const [offsets, setOffsets] = useState([])
  const { getOfferUrlUtm } = useGetOfferUrlWithUtm()

  function getLoansItem(item: Loan): ProviderProps {
    const getField1 = (item: Loan) => {
      if (item.subcategory !== LoansSubcategory.SNIZIM_PERCENT) {
        return item.rateFrom ? String(item.rateFrom) + '%' : '0%'
      }
      return item.reducedRateFrom ? String(item.reducedRateFrom) + '%' : '0%'
    }

    const getField1Replaced = (item: Loan) => {
      if (item.subcategory === LoansSubcategory.SNIZIM_PERCENT && item.rateFrom !== null) {
        return String(item.rateFrom) + '%'
      }

      return null
    }

    return {
      item: {
        id: String(item.id),
        title: item.name,
        field1Replaced: getField1Replaced(item),
        field1: getField1(item),
        field2: `${item.termFrom}-${item.termTo} ${getTermUnit(item.termUnit, item.termTo)}`,
        field3: getRubles(Number(item.maxAmount)),
        url: getOfferUrlUtm(item.targetUrl),
        icon: item.logoUrl,
        advertiser: item.advertiser,
      },
      tag: {
        text: item.tagText,
        color: '#000000',
        backgroundColor: '#DFEB81',
      },
      template: {
        buttonText: 'Перейти',
        field1Name: 'ставка',
        field2Name: 'срок',
        field3Name: 'сумма',
        size: 's',
        backgroundColorCard: '#F0F0F0',
        width: 150,
        isFlexTitleContainer: true,
      },
    }
  }

  async function getOffersContent() {
    const result = await getLoans({ subcategory: LoansSubcategory.LONG_TERM })

    return result.map((item) => getLoansItem(item))
  }

  useEffect(() => {
    async function getOffers() {
      const offers = await getOffersContent()

      if (!offers) {
        setOffsets([])
      } else {
        setOffsets(offers.slice(0, 6))
      }
    }

    getOffers()
  }, [])

  return {
    offsets,
  }
}
