import React from 'react'
import InputMask, { Props } from 'react-input-mask'
import styles from './BdMaskedInput.module.scss'
export default function BdMaskedInput({
  className,
  status,
  ...props
}: Props & { status?: 'error' | 'warn' | '' | undefined }) {
  let statusClassName: string

  switch (status) {
    case 'error': {
      statusClassName = styles.error
      break
    }
    case 'warn': {
      statusClassName = styles.warn
      break
    }
    default: {
      statusClassName = ''
      break
    }
  }

  return (
    <InputMask className={`${styles.bdMaskedInput} ${statusClassName} ${className}`} {...props} />
  )
}
