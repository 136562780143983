import Tabs from '../../../../components/Tabs/Tabs'
import { ETabsIndex, useCompanyTabs } from './useCompanyTabs'
import { useMemo } from 'react'
import { CompanyAbout } from './components/CompanyAbout/CompanyAbout'
import styles from '../CompanyTabs/CompanyTabs.module.scss'
import { CompanyUserAccount } from './components/CompanyUserAccount/CompanyUserAccount'
import { CompanyCallCenter } from './components/CompanyCallCenter/CompanyCallCenter'
import { CompanyDetails } from './components/CompanyDetails/CompanyDetails'

export default function CompanyTabs() {
  const { selectedTab, tabItems } = useCompanyTabs()

  const content = useMemo(() => {
    switch (selectedTab) {
      case ETabsIndex.ABOUT: {
        return <CompanyAbout />
      }
      case ETabsIndex.USER_ACCOUNT: {
        return <CompanyUserAccount />
      }
      case ETabsIndex.CALL_CENTER: {
        return <CompanyCallCenter />
      }
      case ETabsIndex.DETAILS: {
        return <CompanyDetails />
      }
      default: {
        const exhaustiveCheck: never = selectedTab as never
        throw new Error(exhaustiveCheck)
      }
    }
  }, [selectedTab])

  return (
    <div className={styles.companyTabs}>
      <Tabs items={tabItems} selectedTab={selectedTab} />
      <section className={styles.content}>{content}</section>
    </div>
  )
}
