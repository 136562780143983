import { CompanyTextField } from '../CompanyTextField/CompanyTextField'
import { useCompanyAbout } from './useCompanyAbout'
import CompanyAboutSkeleton from './CompanyAbout.skeleton'
import CompanyFilesField from '../CompanyFilesField/CompanyFilesField'
import styles from '../CompanyAbout/CompanyAbout.module.scss'

export function CompanyAbout() {
  const { isLoading, companyAbout } = useCompanyAbout()

  if (isLoading) {
    return <CompanyAboutSkeleton />
  }
  return (
    <div className={styles.about}>
      <h5 className={styles.title}>О микрофинансовой организации {companyAbout.advertiser}</h5>
      <CompanyTextField title={'Описание'} text={companyAbout?.about} />
      <CompanyTextField title={'Полное наименование'} text={companyAbout?.advertiser} />
      <CompanyTextField title={'Год основания'} text={companyAbout?.foundingDate} />
      <CompanyTextField title={'Региональная сеть'} text={companyAbout?.network} />
      <div className={styles.filesContainer}>
        <h5 className={styles.title}>Документы компании</h5>
        <div className={styles.files}>
          {companyAbout?.files.map(({ title, url }, index) => (
            <CompanyFilesField title={title} url={url} key={`file_${index}`} />
          ))}
        </div>
      </div>
    </div>
  )
}
