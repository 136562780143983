import styles from './Tag.module.scss'
export default function Tag({
  color,
  backgroundColor,
  children,
  className,
}: {
  color?: string
  backgroundColor?: string
  children: string
  className?: string
}) {
  return (
    <div
      className={`${className} ${styles.tag}`}
      style={{ color: color, backgroundColor: backgroundColor }}
    >
      {children}
    </div>
  )
}
