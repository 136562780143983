export enum ESmsCodeStatus {
  MATCH = 'MATCH',
  NOT_MATCH = 'NOT_MATCH',
  MANY_REQUESTS = 'MANY_REQUESTS',
}

export enum EOperationStatus {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
  FAILED = 'FAILED',
}

export interface ISmsPayload {
  phone: string
  code: string
}

export interface ISmsResponse {
  phone: string
  success: ESmsCodeStatus
}

export interface ISmsCodeGetResponse {
  status: EOperationStatus
}
