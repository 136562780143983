import styles from './Container.module.scss'
import { ReactNode } from 'react'
import joinClassNames from '../../../../helpers/joinClassNames'
export default function Container({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  return <div className={joinClassNames(styles.container, className)}>{children}</div>
}
