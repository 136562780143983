import { request } from '../request'
import { IRegistrationPayload, IRegistrationResponse } from './dto/registration'
import axios from 'axios'

export function handleRegistration(data: IRegistrationPayload): Promise<IRegistrationResponse> {
  return request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    url: '/reg/v1',
    data,
  })
}

export interface PassportDepartmentSuggestion {
  value: string
  unrestricted_value: string
  data: {
    code: string
    name: string
    regionCode: string
    type: string
  }
}

export const DADATA_API_KEY = 'd70e6aa67583f6e9d982eaa8b37330c46366a845'

const dadataService = axios.create({
  baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/',
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${DADATA_API_KEY}`,
  },
})

export interface PostFindPassportDepartmentResponse {
  data: {
    suggestions: PassportDepartmentSuggestion[]
  }
}

interface queryParamsPassport {
  query: string
}

export function postFindPassportDepartment(
  params: queryParamsPassport,
): Promise<PostFindPassportDepartmentResponse> {
  return dadataService.request({
    method: 'post',
    url: 'fms_unit',
    data: params,
  })
}
