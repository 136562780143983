import styles from './Bankruptcy.module.scss'
import checkDesktop from '../../../helpers/checkDesktop'

export default function BankruptcySkeleton() {
  if (!checkDesktop()) {
    return (
      <>
        <div className={`${styles.banner} ${styles.stubBunner}`} />
        <div className={styles.stub} />
        <div className={styles.stub} />
        <div className={styles.stub} />
        <div className={styles.stub} />
        <div className={styles.stub} />
      </>
    )
  }

  return (
    <>
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={`${styles.banner} ${styles.stubBunner}`} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
      <div className={styles.stub} />
    </>
  )
}
