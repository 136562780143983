import { HeadHelmet } from '../../../components/HeadHelment/HeadHelmet'
import styles from '../Promo.module.scss'
import BackLink from '../../../components/BackLink/BackLink'
import Scroller from '../../../components/Scroller/Scroller'

export default function UserTerms() {
  return (
    <>
      <HeadHelmet title='Пользовательское соглашение' />
      <Scroller isSmooth />
      <main className={styles.promoContent}>
        <BackLink text='На главную страницу' url='/' />
        <section className={styles.info}>
          <h1 className={styles.title}>Пользовательское соглашение</h1>
          <section>
            <h4 className={styles.subtitle}>Общие положения</h4>
            <ol>
              <li className={styles.text}>
                1.1. ООО «Снизим.ру» (далее — «Снизим») предлагает пользователю сети Интернет (далее
                — «Пользователь») использовать свои сервисы на условиях, изложенных в настоящем
                Пользовательском соглашении (далее — «Соглашение», «ПС»). Соглашение вступает в силу
                с момента выражения Пользователем согласия с его условиями в порядке,
                предусмотренном п. 1.4 Соглашения.
              </li>
              <li className={styles.text}>
                1.2. Сравни предлагает Пользователям доступ к широкому спектру сервисов, включая
                средства подбора страховых и банковских продуктов (далее – «Сервисы»).
              </li>
              <li className={styles.text}>
                1.3. Использование Сервисов регулируется настоящим Соглашением, а также Политикой
                конфиденциальности. Соглашение может быть изменено Сравни без какого-либо
                специального уведомления, новая редакция Соглашения вступает в силу с момента ее
                размещения в сети Интернет по адресу{' '}
                <a href='/' className={styles.link}>
                  (вставить ссылку зеленым цветом)
                </a>{' '}
                если иное не предусмотрено новой редакцией Соглашения.
              </li>
              <li className={styles.text}>
                1.4. Начиная использовать Сервисы Снизим, отдельные функции, либо пройдя процедуру
                регистрации, Пользователь считается принявшим условия Соглашения в полном объеме,
                без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из
                положений Соглашения, Пользователь не вправе использовать Сервисы. В случае, если
                Сравни были внесены какие-либо изменения в Соглашение в порядке, предусмотренном
                пунктом 1.3 Соглашения, с которыми Пользователь не согласен, он обязан прекратить
                использование Сервисов Снизим.
              </li>
            </ol>
          </section>
          <section>
            <h4 className={styles.subtitle}>
              Регистрация пользователя. Учетная запись пользователя
            </h4>
            <ol>
              <li className={styles.text}>
                2.1. Для того чтобы воспользоваться некоторыми Сервисами Снизим или некоторыми
                отдельными его функциями, Пользователю необходимо пройти процедуру регистрации, в
                результате которой для Пользователя будет создана уникальная учетная запись.
              </li>
              <li className={styles.text}>
                2.2. Для регистрации Пользователь обязуется предоставить достоверную и полную
                информацию о себе по вопросам, предлагаемым в форме регистрации, и поддерживать эту
                информацию в актуальном состоянии. Для использования отдельных Сервисов может
                потребоваться предоставление информации о юридическом лице, в интересах которого
                Пользователь использует Сервис, в таком случае Пользователь обязуется представить
                достоверную и полную информацию о таком лице и гарантирует, что он получил данную
                информацию на законных основаниях и имеет все необходимые права и полномочия для ее
                использования. Если Пользователь предоставляет неверную информацию или у Снизим есть
                основания полагать, что предоставленная Пользователем информация неполная или
                недостоверна, Снизим имеет право по своему усмотрению заблокировать либо удалить
                учетную запись Пользователя и отказать Пользователю в использовании своих Сервисов
                (либо их отдельных функций).
              </li>
            </ol>
          </section>
          <section>
            <h4 className={styles.subtitle}>Общие положения об использовании и хранении</h4>
            <ol>
              <li className={styles.text}>
                3.1. Снизим вправе устанавливать ограничения в использовании Сервисов для всех
                Пользователей, либо для отдельных категорий Пользователей в том числе:
                наличие/отсутствие отдельных функций.
              </li>
              <li className={styles.text}>
                3.2. Снизим вправе посылать своим пользователям информационные сообщения.
                Пользователь также в соответствии с ч. 1 ст. 18 Федерального закона «О рекламе» дает
                свое согласие на получение сообщений рекламного характера. Пользователь вправе
                отказаться от получения сообщений рекламного характера путем направления в адрес
                Снизим обращения в личном кабинете.
              </li>
              <li className={styles.text}>
                3.3. В целях повышения качества Сервисов Снизим вправе осуществлять сбор мнений и
                отзывов Пользователей по различным вопросам путем направления информационного
                сообщения при очередном посещении Пользователем сервиса либо осуществления связи по
                контактным данным, указанным Пользователем в учетной записи (посредством телефонных
                звонков или электронных писем). Собранные мнения и отзывы могут быть использованы
                для формирования статистических данных, которые могут быть использованы в Сервисах
                Снизим.
              </li>
              <li className={styles.text}>
                3.4. Снизим оставляет за собой право в любой момент потребовать от Пользователя
                подтверждения данных, указанных при регистрации в учетной записи Пользователя и
                содержащих информацию о Пользователе, а также иную информацию, связанную с
                использованием сервисов Снизим, доступную Пользователю после авторизации с
                использованием учетной записи Пользователя (его аффилированных лиц) на Сервисах
                Снизим.
              </li>
              <li className={styles.text}>
                3.5 В случае если данные Пользователя, указанные в предоставленных им документах, не
                соответствуют данным, указанным при регистрации, а также в случае, когда данные,
                указанные при регистрации, не позволяют идентифицировать пользователя, Снизим вправе
                отказать Пользователю в доступе к учетной записи и использовании Сервисов.
              </li>
              <li className={styles.text}>
                3.6. Пользователь обязуется обеспечить сохранность и конфиденциальность своих
                учетных данных, обеспечить невозможность доступа третьих лиц к устройствам, на
                которых учетные данные сохраняются и используются для авторизации по умолчанию.
              </li>
              <li className={styles.text}>
                3.7. Пользователь обязуется немедленно уведомить Снизим о любом случае
                несанкционированного (неразрешенного Пользователем) доступа в авторизованную зону
                Пользователя путем обращения в Службу поддержки клиентов.
              </li>
              <li className={styles.text}>
                3.8. Пользователь несет ответственность за все действия (а также их последствия) при
                использовании Сервиса, включая случаи передачи Пользователем данных для доступа к
                Сервису третьим лицам. При этом все действия при использовании Сервиса, совершенные
                с использованием учетных данных Пользователя, считаются произведенными самим
                Пользователем.
              </li>
            </ol>
          </section>

          <section>
            <h4 className={styles.subtitle}>Условия использования Сервисов Снизим</h4>
            <ol>
              <li className={styles.text}>
                4.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои
                действия, связанные с использованием Сервиса, в том числе если такие действия
                приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение
                законодательства при использовании Сервиса.
              </li>
              <li className={styles.text}>
                4.2. При использовании Сервисов Пользователь не вправе:
              </li>
              <li className={styles.text}>
                4.2.1. загружать, посылать, передавать или любым другим способом размещать и/или
                распространять контент, который является незаконным, вредоносным, клеветническим,
                оскорбляет нравственность, демонстрирует (или является пропагандой) насилия и
                жестокости, нарушает права интеллектуальной собственности, пропагандирует ненависть
                и/или дискриминацию людей по расовому, этническому, половому, религиозному,
                социальному признакам, содержит оскорбления в адрес каких-либо лиц или организаций,
                содержит элементы (или является пропагандой) порнографии, детской эротики,
                представляет собой рекламу (или является пропагандой) услуг сексуального характера
                (в том числе под видом иных услуг), разъясняет порядок изготовления, применения или
                иного использования наркотических веществ или их аналогов, взрывчатых веществ или
                иного оружия;
              </li>
              <li className={styles.text}>
                4.2.2. выдавать себя за другого человека или представителя организации и/или
                сообщества без достаточных на то прав, в том числе за сотрудников Снизим, за
                модераторов форумов, за владельца сайта, а также применять любые другие формы и
                способы незаконного представительства других лиц в сети, а также вводить
                пользователей или Снизим в заблуждение относительно свойств и характеристик
                каких-либо субъектов или объектов;
              </li>
              <li className={styles.text}>
                4.2.3. загружать, посылать, передавать или любым другим способом размещать и/или
                распространять рекламу, спам.
              </li>
              <li className={styles.text}>
                4.3. Пользователь признает и соглашается с тем, что Снизим не обязан просматривать
                контент любого вида, размещаемый и/или распространяемый Пользователем посредством
                Сервисов, а также то, что Снизим имеет право (но не обязанность) по своему
                усмотрению отказать Пользователю в размещении и/или распространении им контента или
                удалить любой контент, который доступен посредством Сервисов. Пользователь осознает
                и согласен с тем, что он должен самостоятельно оценивать все риски, связанные с
                использованием контента, включая оценку надежности, полноты или полезности этого
                контента.
              </li>
              <li className={styles.text}>
                4.4. Пользователь осознает и соглашается с тем, что технология работы Сервисов может
                потребовать копирование (воспроизведение) контента Пользователя Сервиса, а также
                переработки его для соответствия техническим требованиям Сервиса.
              </li>
            </ol>
          </section>

          <section>
            <h4 className={styles.subtitle}>
              Исключительные права на содержание Сервисов и контент
            </h4>
            <ol>
              <li className={styles.text}>
                5.1. Все объекты, доступные при помощи Сервисов Снизим, в том числе элементы
                дизайна, текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы
                данных, музыка, звуки и другие объекты (далее – содержание сервисов), а также любой
                контент, размещенный на Сервисах, являются объектами исключительных прав Снизим,
                Пользователей и других правообладателей.
              </li>
              <li className={styles.text}>
                5.2. Использование контента, а также каких-либо иных элементов Сервисов возможно
                только в рамках функционала, предлагаемого тем или иным Сервисом. Никакие элементы
                содержания Сервисов, а также любой контент, размещенный на Сервисах, не могут быть
                использованы иным образом без предварительного разрешения правообладателя. Под
                использованием подразумеваются, в том числе: воспроизведение, копирование,
                переработка, распространение на любой основе, отображение во фрейме и т.д.
                Исключение составляют случаи, прямо предусмотренные законодательством РФ или
                условиями использования того или иного Сервиса.
                <div className={styles.text}>
                  Использование Пользователем элементов содержания Сервисов, а также любого контента
                  для личного некоммерческого использования, допускается при условии сохранения всех
                  знаков охраны авторского права, смежных прав, товарных знаков, других уведомлений
                  об авторстве, сохранения имени (или псевдонима) автора/наименования
                  правообладателя в неизменном виде, сохранении соответствующего объекта в
                  неизменном виде.
                </div>
              </li>
              <li className={styles.text}>
                5.3 Размещая отзыв или комментарий на ресурсах Сервиса, Пользователь автоматически
                безвозмездно, безусловно и безотзывно предоставляет Снизим неисключительное право на
                использование таких отзывов и комментариев на территории всего мира без ограничения
                срока по усмотрению Снизим, в том числе, но не ограничиваясь, на воспроизведение,
                копирование, подбор, систематизацию, преобразование, изменение, редактирование,
                перевод, опубликование, распространение, сообщение в эфир, сообщение по кабелю,
                доведение до всеобщего сведения такой информации (частично или полностью) любыми
                законными способами.
              </li>
              <li className={styles.text}>
                5.4. Пользователь подтверждает, что обладает соответствующими правами и полномочиями
                для предоставления Снизим указанного выше неисключительного права на использование
                размещенной информации. При таких обстоятельствах Снизим имеет право использовать
                соответствующий комментарий или отзыв как с указанием имени автора такой информации,
                так и без указания имени автора (в качестве имени автора при этом будет указываться
                имя (псевдоним) Пользователя, которое он указал при регистрации на Сайте или в
                настройках профиля либо логин Пользователя). Администрация Сайта вправе передавать
                права, указанные в настоящем пункте, своим партнерам. Кроме этого, Администрация
                сайта праве передавать своим партнерам содержание отзыва и контактные данные автора
                отзыва для целей получения обратной связь по продуктам третьих лиц.
                <div className={styles.text}>
                  Удаление или блокировка учётной записи Пользователем самостоятельно или
                  Администратором не означает прекращения действия исключительной лицензии,
                  предоставляемой Пользователем Администратору.
                </div>
              </li>
            </ol>
          </section>

          <section>
            <h4 className={styles.subtitle}>Сайты и контент третьих лиц</h4>
            <ol>
              <li className={styles.text}>
                6.1. Сервисы могут содержать ссылки на другие сайты в сети интернет (сайты третьих
                лиц). Указанные третьи лица и их контент не проверяются Снизим на соответствие тем
                или иным требованиям (достоверности, полноты, законности и т.п.). Снизим не несет
                ответственность за любую информацию, материалы, размещенные на сайтах третьих лиц, к
                которым Пользователь получает доступ с использованием Сервисов, в том числе за любые
                мнения или утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за
                доступность таких сайтов или контента и последствия их использования Пользователем.
              </li>
              <li className={styles.text}>
                6.2. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию
                коммерческого или некоммерческого характера, размещенная на Сайте, не является
                одобрением или рекомендацией данных продуктов (услуг, деятельности) со стороны
                Снизим.
              </li>
            </ol>
          </section>

          <section>
            <h4 className={styles.subtitle}>Отсутствие гарантий и ограничение ответственности</h4>
            <ol>
              <li className={styles.text}>
                7.1. Пользователь использует Сервисы на свой собственный риск. Сервисы
                предоставляются «как есть». Снизим не принимает на себя никакой ответственности, в
                том числе за соответствие сервисов целям Пользователя.
              </li>
              <li className={styles.text}>
                7.2. Снизим не гарантирует, что: Сервисы соответствуют/будут соответствовать
                требованиям Пользователя; сервисы будут предоставляться непрерывно, быстро, надежно
                и без ошибок; результаты, которые могут быть получены с использованием сервисов,
                будут точными и надежными и могут использоваться для каких-либо целей или в
                каком-либо качестве (например, для установления и/или подтверждения каких-либо
                фактов); качество какого-либо продукта, услуги, информации и пр., полученных с
                использованием сервисов, будет соответствовать ожиданиям Пользователя.
              </li>
              <li className={styles.text}>
                7.3. Снизим не несет ответственности за любые виды убытков, произошедшие вследствие
                использования Пользователем Сервисов или отдельных его частей/функций.
              </li>
              <li className={styles.text}>
                7.4. При любых обстоятельствах ответственность Снизим в соответствии со статьей 15
                Гражданского кодекса России ограничена 10 000 (десятью тысячами) рублей РФ и
                возлагается на него при наличии в его действиях вины.
              </li>
            </ol>
          </section>

          <section>
            <h4 className={styles.subtitle}>
              Соглашение об использовании простой электронной подписи (ПЭП)
            </h4>
            <ol>
              <li className={styles.text}>
                8.1. Принимая условия настоящего Пользовательского соглашения, Пользователь
                заключает соглашение об использовании сервисов простой электронной подписи в
                информационных системах Снизим. Соглашение считается заключенным в порядке,
                предусмотренном разделом первым Пользовательского соглашения.
              </li>
              <li className={styles.text}>
                8.2. Простая электронная подпись используется Пользователем в том числе при
                предоставлении согласия на обработку персональных данных, при направлении заявления
                на подбор страховых, финансовых услуг или услуг микрофинансовых организацией,
                совершении иных юридически значимых действий с использованием функционала платформы
                Снизим.
              </li>
              <li className={styles.text}>
                8.3. Для целей формирования ПЭП используется номер мобильного телефона Пользователя
                и кода подтверждения, генерируемого Снизим.
              </li>
              <li className={styles.text}>
                8.4. Пользователь запрашивает код подтверждения путем нажатия соответствующей
                электронной кнопки, размещенной в интерфейсе сайта – платформы Снизим. Код
                подтверждения в качестве ключа ПЭП направляется Снизим на номер мобильного телефона
                Пользователя.
              </li>
              <li className={styles.text}>
                8.5. После получения кода подтверждения на номер мобильного телефона Пользователь
                должен ввести этот код подтверждения в специальное поле, размещенное в интерфейсе
                сайта Снизим.
              </li>
              <li className={styles.text}>
                8.6. Ввод полученного на номер мобильного телефона кода подтверждения признается
                сторонами подписанием ЭД пользователем посредством ПЭП.
              </li>
              <li className={styles.text}>
                8.7. Корректная ПЭП является простой электронной подписью Пользователя в
                соответствии с Федеральным законом от 06.04.2011 №63-ФЗ «Об электронной подписи» и
                является аналогом собственноручной подписи Пользователя.
              </li>
            </ol>
          </section>

          <section>
            <h4 className={styles.subtitle}>Иные положения</h4>
            <ol>
              <li className={styles.text}>
                9.1. Настоящее Соглашение представляет собой договор между Пользователем и Снизим
                относительно порядка использования Сервисов и заменяет собой все предыдущие
                соглашения между Пользователем и Снизим.
              </li>
              <li className={styles.text}>
                9.2. Настоящее Соглашение регулируется и толкуется в соответствии с
                законодательством Российской Федерации. Вопросы, не урегулированные настоящим
                Соглашением, подлежат разрешению в соответствии с законодательством Российской
                Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим
                Соглашением, разрешаются в порядке, установленном действующим законодательством
                Российской Федерации, по нормам российского права.
              </li>
              <li className={styles.text}>
                9.3. Услуги в рамках настоящего Соглашения оказываются на безвозмездной основе,
                нормы о защите прав потребителей, предусмотренные законодательством Российской
                Федерации, не могут быть применимыми к отношениям между Пользователем и Снизим.
              </li>
              <li className={styles.text}>
                9.4. Ничто в Соглашении не может пониматься как установление между Пользователем и
                Снизим агентских отношений, отношений товарищества, отношений по совместной
                деятельности, отношений личного найма, либо каких-то иных отношений, прямо не
                предусмотренных Соглашением.
              </li>
              <li className={styles.text}>
                9.5. Если по тем или иным причинам одно или несколько положений настоящего
                Соглашения будут признаны недействительными или не имеющими юридической силы, это не
                оказывает влияния на действительность или применимость остальных положений
                Соглашения.
              </li>
              <li className={styles.text}>
                9.6. Бездействие со стороны Снизим в случае нарушения Пользователем либо иными
                пользователями положений Соглашений не лишает Снизим права предпринять
                соответствующие действия в защиту своих интересов позднее, а также не означает
                отказа Снизим от своих прав в случае совершения в последующем подобных либо сходных
                нарушений.
              </li>
              <li className={styles.text}>
                9.7. В случае нарушения Пользователем условий настоящего Соглашения учетная запись
                Пользователя может быть удалена или заблокирована, в том числе без возможности
                восстановления, по решению Снизим.
              </li>
            </ol>
          </section>
        </section>
      </main>
    </>
  )
}
