import styles from './Footer.module.scss'
import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import { ReactComponent as Whatsapp } from 'assets/icons/whatsapp.svg'
import { ReactComponent as Telegram } from 'assets/icons/telegram.svg'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer id='help' className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.socials}>
          <Logo />
          <span>
            <a href='/' title='Перейти в телеграм чат'>
              <Telegram />
            </a>
            <a href='/' title='Перейти в вотсап чат'>
              <Whatsapp />
            </a>
          </span>
        </div>
        <nav>
          <Link to='/about/company/' title='О компании'>
            О компании
          </Link>
          <a href='/' title='Контакты'>
            Товарный знак
          </a>
          <Link to='/promo/privacy-policy' title='Политика конфиденциальности'>
            Политика конфиденциальности
          </Link>
          <Link to='/promo/polzovatelskoe-soglashenie/' title='Пользовательское соглашение'>
            Пользовательское соглашение
          </Link>
        </nav>
        <div className={styles.copyrights}>
          © {new Date().getFullYear()}, ООО «СНИЗИМ.РУ». При использовании материалов гиперссылка
          на «СНИЗИМ» обязательна. Юридическая информация (ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ
          «СНИЗИМ.РУ», ОГРН 1247700212528, ИНН 7733440950, 125363, Г.МОСКВА, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ
          ОКРУГ ЮЖНОЕ ТУШИНО, УЛ СХОДНЕНСКАЯ, Д. 25).
          <br />
          <br />
          Мы используем файлы cookie для того, чтобы предоставить пользователям больше возможностей
          при посещении сайта «СНИЗИМ». По вопросам сотрудничества пишите{' '}
          <a href='mailto:info@snizim.ru' className={styles.mail}>
            info@snizim.ru
          </a>
        </div>
      </div>
    </footer>
  )
}
