import React from 'react'
import ReactDOM from 'react-dom/client'
import { router } from './router/router'
import { RouterProvider } from 'react-router'
import './assets/styles/constants.scss'
import './assets/styles/common.scss'
import './assets/fonts/Gilroy/Gilroy.css'
import { Provider } from 'react-redux'
import { store } from './store/store'
import joinClassNames from './helpers/joinClassNames'
import checkDesktop from './helpers/checkDesktop'
import AnalyticsProvider from './modules/Analytics/AnalyticsProvider'

window.joinClassNames = joinClassNames
window.checkDesktop = checkDesktop

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <AnalyticsProvider>
      <RouterProvider router={router}></RouterProvider>
    </AnalyticsProvider>
  </Provider>,
)
