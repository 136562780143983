import styles from './AboutCompanyTextField.module.scss'

interface IProps {
  title: string
  text: string
}

export default function AboutCompanyTextField({ title, text }: IProps) {
  return (
    <section className={styles.aboutTextField}>
      <span className={styles.title}>{title}</span>
      <span className={styles.text}>{text}</span>
    </section>
  )
}
