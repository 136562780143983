import styles from './Card.module.scss'
import { AdvantageItemProps } from '../../../../../../components/ProductItem/ProductItem'
import AdvantageList from '../AdvantageList/AdvantageList'
import FooterButton from './components/FooterButton/FooterButton'
import { ReactNode, useRef, useEffect, MutableRefObject } from 'react'
import PlusMinusToggle from '../../../../../../components/PlusMinusToggle/PlusMinusToggle'
import checkDesktop from '../../../../../../helpers/checkDesktop'

export interface CardProps {
  title: { text: string; color?: string }
  link?: string
  color: string
  subtitle: { text: string; color?: string }
  items: AdvantageItemProps[]
  footerButton?: {
    content: ReactNode
    to?: string
    onClick?: () => void
  }
  isOpen?: boolean
  onToggle: () => void
  activeCard?: MutableRefObject<string | null | undefined>
}
export default function Card({
  title,
  subtitle,
  items,
  footerButton,
  color,
  isOpen,
  onToggle,
}: CardProps) {
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!contentRef.current || checkDesktop()) return
    if (isOpen) {
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`
    } else {
      contentRef.current.style.height = '0'
    }
  }, [contentRef.current, isOpen])

  return (
    <div className={styles.details} style={{ backgroundColor: color }}>
      <div className={styles.summary} style={{ color: title.color }} onClick={onToggle}>
        {title.text}
        <PlusMinusToggle open={isOpen} />
      </div>
      <div className={styles.content} ref={contentRef}>
        <p className={styles.subtitle} style={{ color: subtitle.color }}>
          {subtitle.text}
        </p>
        <AdvantageList items={items} />
        {footerButton && (
          <div className={styles.footerButton}>
            <FooterButton {...footerButton} />
          </div>
        )}
      </div>
    </div>
  )
}
