import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface IProps {
  isSmooth?: boolean
}

export default function Scroller({ isSmooth = false }: IProps) {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: isSmooth ? 'smooth' : undefined,
    })
  }, [pathname])

  return null
}
