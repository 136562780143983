import { useCompanyCallCenter } from './useCompanyCallCenter'
import CompanyCallCenterSkeleton from './CompanyCallCenter.skeleton'
import styles from '../CompanyUserAccount/CompanyUserAccount.module.scss'

export function CompanyCallCenter() {
  const { isLoading, companyCallCenter } = useCompanyCallCenter()

  if (isLoading) {
    return <CompanyCallCenterSkeleton />
  }
  return <div className={styles.callCenter}>{companyCallCenter.content}</div>
}
