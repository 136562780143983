import styles from './Stepper.module.scss'
import { useMemo, useState } from 'react'
import RoundButton from '../RoundButton/RoundButton'
import { ReactComponent as QuestionMark } from 'assets/icons/questionMark.svg'
import { ReactComponent as SmallArrow } from 'assets/icons/smallArrow.svg'
import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import checkDesktop from '../../../../helpers/checkDesktop'
import { Link } from 'react-router-dom'
import Button from '../../../../components/Button/Button'
import Modal from 'react-modal'
import { MobileWrapper } from '../MobileWrapper/MobileWrapper'
import { ReactComponent as Telegram } from 'assets/icons/telegram.svg'
import { ReactComponent as Mail } from 'assets/icons/mail.svg'
import Operator from 'assets/icons/operator.jpg'

const STEPS_QUANTITY = 3

export interface StepperProps {
  currentStep?: number
  error?: string
  ready?: boolean
  onSubmit?: () => void
}

const customStyles = {
  overlay: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: 10,
    backgroundColor: 'rgba(28, 42, 64, 0.4)',
    display: 'flex',
    alignItems: 'end',
  },
  content: {
    position: 'relative',
    inset: 'inherit',
    border: 'none',
    background: 'none',
    overflow: 'inherit',
    borderRadius: 0,
    outline: 'none',
    padding: 0,
    height: 'fit-content',
    width: '100%',
  },
} as Modal.Styles

export default function Stepper({ currentStep = 0, error, ready, onSubmit }: StepperProps) {
  const [showTopBlock, setShowTopBlock] = useState(false)

  const items = useMemo(() => {
    if (error) return error

    const list = []
    for (let i = 0; i <= currentStep; i++) {
      list.push(<div className={`${styles.item} ${styles.filled}`} key={`progress-step-${i}`} />)
    }
    for (let i = currentStep + 1; i < STEPS_QUANTITY; i++) {
      list.push(<div className={styles.item} key={`progress-step-${i}`} />)
    }

    return list
  }, [currentStep, error])

  const handleCloseTopBlock = () => {
    setShowTopBlock(false)
    document.body.style.overflow = ''
  }

  const showTopBlockHandler = () => {
    setShowTopBlock(true)
    document.body.style.overflow = 'hidden'
  }

  return (
    <div className={styles.stepper}>
      <Modal
        isOpen={showTopBlock}
        onRequestClose={handleCloseTopBlock}
        contentLabel='Example Modal'
        style={customStyles}
        ariaHideApp={false}
      >
        <MobileWrapper hide={handleCloseTopBlock}>
          {showTopBlock && (
            <div className={styles.topBlockWrapper}>
              <div className={styles.topInfoWrapper}>
                <p className={styles.topBlockHeader}>
                  Круглосуточный чат <br />с поддержкой
                </p>
                <div onClick={handleCloseTopBlock} className={styles.closeInfoButton} />
              </div>
              <div className={styles.bottomInfoWrapper}>
                <div className={styles.contactItem}>
                  <img src={Operator} alt='Icon' />
                  <p>
                    Оператор
                    <br />
                    онлайн
                  </p>
                </div>
                <div className={styles.contactItem}>
                  <Telegram />
                  <p>
                    Мессенджер
                    <br />
                    Telegram
                  </p>
                </div>
                <div className={styles.contactItem}>
                  <Mail />
                  <p>
                    Электронная
                    <br />
                    почта
                  </p>
                </div>
              </div>
            </div>
          )}
        </MobileWrapper>
      </Modal>
      {checkDesktop() ? (
        <Link to='/'>
          <Logo className={styles.logo} />
        </Link>
      ) : (
        <RoundButton description='Помощь' onClick={showTopBlockHandler}>
          <QuestionMark />
        </RoundButton>
      )}
      <div className={styles.dotsContainer}>{items}</div>
      {checkDesktop() ? (
        <Button className={styles.nextButton} uiType='green' disabled={ready} onClick={onSubmit}>
          Далее
        </Button>
      ) : (
        <RoundButton description='Далее' type='success' disabled={ready} onClick={onSubmit}>
          <SmallArrow />
        </RoundButton>
      )}
    </div>
  )
}
