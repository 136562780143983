import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { UtmObjectType } from '../../../../hooks/useGetOfferUrlWithUtm'
import { LoansSubcategory } from '../../../../api/ui/dto/loans'
import { useMemo } from 'react'

interface IProps {
  subcategory: LoansSubcategory
}

export default function useGetTextBanner({ subcategory }: IProps) {
  const { search } = useLocation()
  const searchObj = search ? (qs.parse(search) as UtmObjectType) : null

  const text = useMemo(() => {
    if (
      searchObj &&
      searchObj?.utm_source &&
      searchObj?.utm_source.includes('budgett') &&
      (subcategory === LoansSubcategory.SNIZIM_PERCENT ||
        subcategory === LoansSubcategory.SHORT_TERM)
    ) {
      return 'В данный момент Бюджет.ру не может выдать вам займ, но Снизим.ру уже подобрал список компаний, которые готовы одобрить лимит по сниженной ставке.'
    }

    if (subcategory === LoansSubcategory.SNIZIM_PERCENT) {
      return 'Заполняя регистрацию в МФО по нашей ссылке, Вы получаете самое выгодное предложение.'
    }

    return 'Покажем точную ставку, сумму и платеж по кредиту от нескольких банков с решением в течение 5 минут.'
  }, [searchObj, subcategory])

  return {
    text,
  }
}
