import React, { FunctionComponent, SVGProps } from 'react'
import styles from './Input.module.scss'
import joinClassNames from '../../helpers/joinClassNames'

export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string
  value: string
  label?: string
  suffix?: FunctionComponent<SVGProps<SVGSVGElement>>
  prefix?: string
  onSuffixClick?: () => void
  suffixClassName?: string
}

export default function Input({
  label,
  suffix: Suffix,
  prefix: Prefix,
  onSuffixClick,
  className,
  suffixClassName,
  ...inputProps
}: InputProps) {
  return (
    <div className={`${styles.input} ${className}`}>
      {label && (
        <label htmlFor={inputProps.name} className={styles.label}>
          {label}
        </label>
      )}
      <div className={styles.nativeInputContainer}>
        {Prefix && <span className={styles.prefix}>{Prefix}</span>}
        <input {...inputProps} className={styles.nativeInput} />
        {Suffix && (
          <Suffix
            className={joinClassNames(
              styles.suffix,
              suffixClassName,
              onSuffixClick ? styles.clickable : '',
            )}
            onClick={onSuffixClick}
          />
        )}
      </div>
    </div>
  )
}
