import { request } from '../request'

export function getSeoSection({ code }: { code: string }): Promise<{
  code: string
  title: string
  text: string
}> {
  return request({
    url: `/client/v1/section/${code}`,
  })
}
