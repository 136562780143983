import Title from 'components/Title/Title'
import Tabs, { TabItem } from 'components/Tabs/Tabs'
import { ReactNode, useEffect, useState } from 'react'
import styles from './SnizimMagazine.module.scss'
import LinkButton from '../../../../components/LinkButton/LinkButton'
import { getNews } from '../../../../api/news/news'
import { News } from '../../../../api/news/dto/news'
import Article from './components/Article/Article'

export default function SnizimMagazine({ className }: { className: string }) {
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState<null | string>(null)
  const [news, setNews] = useState<News[]>([])

  useEffect(() => {
    ;(async function () {
      try {
        const result = await getNews()
        setNews(result)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  const items: TabItem[] = [
    {
      text: 'Главное',
      onClick() {
        setSelectedTab(0)
        setSelectedCategory(null)
      },
    },
    {
      text: 'Финансы',
      onClick() {
        setSelectedTab(1)
        setSelectedCategory('Финансы')
      },
    },
    {
      text: 'Кредиты',
      onClick() {
        setSelectedTab(2)
        setSelectedCategory('Кредиты')
      },
    },
    {
      text: 'Страхование',
      onClick() {
        setSelectedTab(3)
        setSelectedCategory('Страхование')
      },
    },
    {
      text: 'Инвестиции',
      onClick() {
        setSelectedTab(4)
        setSelectedCategory('Инвестиции')
      },
    },
    {
      text: 'Разное',
      onClick() {
        setSelectedTab(5)
        setSelectedCategory('Разное')
      },
    },
  ]

  return (
    <div id='magazine' className={`${className} ${styles.snizimMagazine}`}>
      <Title className={styles.title} level={3}>
        Новости
      </Title>
      <div className={styles.controls}>
        <Tabs items={items} selectedTab={selectedTab} />
        <LinkButton
          className={styles.moreButton}
          to='/novosti'
          target='_blank'
          rel='nofollow noreferrer'
          size={'thin'}
          uiType={'secondary'}
        >
          Смотреть все новости
        </LinkButton>
      </div>
      <div className={styles.articles}>
        {news.reduce((previousValue: ReactNode[], currentValue: News, currentIndex) => {
          if (selectedCategory === null || currentValue.term === selectedCategory) {
            if (previousValue.length >= 6) {
              return previousValue
            }

            previousValue.push(
              <Article
                to={currentValue.link}
                photoUrl={currentValue.image}
                date={currentValue.date}
                category={currentValue.term}
                title={currentValue.title}
                key={currentIndex}
              />,
            )
          }
          return previousValue
        }, [])}
      </div>
      <LinkButton
        className={styles.link}
        to='/novosti'
        uiType='secondary'
        target='_blank'
        rel='nofollow noreferrer'
      >
        Смотреть все новости
      </LinkButton>
    </div>
  )
}
