const data = {
  consumer_loan: {
    title: 'Потребительские кредиты',
    subtitle: 'Ставка от 2%, сумма до 10 000 000 рублей.',
    text: 'Покажем точную ставку, сумму и платеж по кредиту от нескольких банков с решением в течение 5 минут.',
    searchPlaceholder: 'Введите сумму кредита',
    amount: 12,
  },
  credit_card: {
    title: 'Кредитные карты',
    subtitle: 'Ставка от 2%, сумма до 10 000 000 рублей.',
    text: 'Покажем точную ставку, сумму и платеж по кредиту от нескольких банков с решением в течение 5 минут.',
    searchPlaceholder: 'Введите сумму кредита',
    amount: 15,
  },
  mortgage: {
    title: 'Ипотечное кредитование',
    subtitle: 'Ставка от 2%, сумма до 10 000 000 рублей.',
    text: 'Покажем точную ставку, сумму и платеж по кредиту от нескольких банков с решением в течение 5 минут.',
    searchPlaceholder: 'Введите сумму кредита',
    amount: 18,
  },
  credit_master: {
    title: 'Кредитный мастер',
    subtitle: 'Ставка от 2%, сумма до 10 000 000 рублей.',
    text: 'Покажем точную ставку, сумму и платеж по кредиту от нескольких банков с решением в течение 5 минут.',
    searchPlaceholder: 'Введите сумму кредита',
    amount: 20,
  },
  custom: {
    title: '',
    subtitle: 'Ставка от 2%, сумма до 10 000 000 рублей.',
    text: 'Покажем точную ставку, сумму и платеж по кредиту от нескольких банков с решением в течение 5 минут.',
    searchPlaceholder: 'Введите сумму кредита',
    amount: 20,
  },
}
export interface categoryDto {
  title: string
  subtitle: string
  text: string
  searchPlaceholder: string
  amount: number
}

export default function loadCategoryData(category: string | undefined) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return data[category]
}
