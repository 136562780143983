import { useParams } from 'react-router'
import { getCompanyCallCenter } from '../../../../../../api/ui/company'
import { useEffect, useState } from 'react'
import { ICompanyCallCenter, ICompanyCallCenterDTO } from '../../../../../../api/ui/dto/company'
import { parsedHtmlToReactComponent } from '../../../../helper'

function transformedDTO(data: ICompanyCallCenterDTO): ICompanyCallCenter {
  if (!data) {
    throw new Error('Company User Account is null')
  }

  return {
    id: data.id ?? 0,
    content: data.content ? parsedHtmlToReactComponent(data.content) : '',
  }
}

export function useCompanyCallCenter() {
  const { companySlug } = useParams()
  const [isLoading, setLoading] = useState(false)
  const [companyCallCenter, setCompanyCallCenter] = useState<ICompanyCallCenter>(null)

  async function fetchCompanyAccount() {
    setLoading(true)

    try {
      const result = await getCompanyCallCenter(companySlug)
      setCompanyCallCenter(transformedDTO(result))
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void fetchCompanyAccount()

    return () => {
      setCompanyCallCenter(null)
    }
  }, [])

  return {
    isLoading: isLoading || !companyCallCenter?.content,
    companyCallCenter,
  }
}
